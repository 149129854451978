body {
  font-family: "Quicksand-Regular" !important;
  font-size: 15px;
  box-sizing: border-box;
  background: #fff;
  color: #4b4b4b;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "JosefinSans-Regular";
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "JosefinSans-Regular";
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "JosefinSans-Regular";
}

:-moz-placeholder {
  /* Firefox 18- */
  font-family: "JosefinSans-Regular";
}

@font-face {
  font-family: "JosefinSans-Bold";
  src: url("../fonts/JosefinSans-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "JosefinSans-BoldItalic";
  src: url("../fonts/JosefinSans-BoldItalic.ttf") format("opentype");
}

@font-face {
  font-family: "JosefinSans-Italic";
  src: url("../fonts/JosefinSans-Italic.ttf") format("opentype");
}

@font-face {
  font-family: "JosefinSans-Light";
  src: url("../fonts/JosefinSans-Light.ttf") format("opentype");
}

@font-face {
  font-family: "JosefinSans-LightItalic";
  src: url("../fonts/JosefinSans-LightItalic.ttf") format("opentype");
}

@font-face {
  font-family: "JosefinSans-Regular";
  src: url("../fonts/JosefinSans-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "JosefinSans-SemiBold";
  src: url("../fonts/JosefinSans-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: "JosefinSans-Thin";
  src: url("../fonts/JosefinSans-Thin.ttf") format("opentype");
}

@font-face {
  font-family: "JosefinSans-ThinItalic";
  src: url("../fonts/JosefinSans-ThinItalic.ttf") format("opentype");
}

@font-face {
  font-family: "Quicksand-Regular";
  src: url("../fonts/Quicksand-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Quicksand-Medium";
  src: url("../fonts/Quicksand-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "Quicksand-Bold";
  src: url("../fonts/Quicksand-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "Tommysoft-Bold";
  src: url("../fonts/MADE Tommy Soft Bold PERSONAL USE.otf") format("opentype");
}

@font-face {
  font-family: "Tommysoft-Medium";
  src: url("../fonts/MADE Tommy Soft Medium PERSONAL USE.otf")
    format("opentype");
}

@font-face {
  font-family: "Tommysoft-Regular";
  src: url("../fonts/MADE Tommy Soft Regular PERSONAL USE.otf")
    format("opentype");
}

/* .checkbox-design input[type="checkbox"] ~ span {
  position: absolute;
  left: -20px;
  top: 3px;
  width: 16px;
  height: 16px;
  border: 1px solid #737373;
  background: #fff;
  border-radius: 2px;
} */
img#blah {
  width: 25%;
  height: 25%;
  margin: 15px 0;
}

header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: 10px 90px;
  background-color: var(--white-color);
  /* background: #ffffff#0f0230; */
}

.header.header-bg {
  background: var(--primary-color);
  border-bottom: 1px solid #ddd;
}

.header.header-bg .menu ul li a,
.header.header-bg .profile-name,
.header.header-bg .btn-bell {
  color: #ffffff !important;
}

.logo {
  width: 100%;
  max-width: 100px;
  height: auto;
}

.logo a {
  display: block;
  color: #fff;
  font-size: 26px;
}

.logo a:hover {
  text-decoration: none;
}

.logo img {
  max-width: 160px;
}

.logo .mobile-logo {
  display: none;
}

.cke_chrome {
  border-radius: 10px;
  overflow: hidden;
}

.btn-help {
  background: transparent;
  color: #33335f;
  font-size: 14px;
}

.btn-help:hover {
  color: #ff2801;
}

.uniquewhite {
  color: white !important;
}

.uniqueblack {
  color: black !important;
}

.btn-notification {
  background: transparent;
  color: #33335f;
  font-size: 28px;
  padding: 0px 6px;
  position: relative;
}

.btn-notification i {
  transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
}

.btn-notification span.badge {
  background: #ff2801;
  color: #fff;
  font-size: 11px;
  border-radius: 15px;
  padding: 2px 5px;
  position: absolute;
  top: 4px;
  right: 7px;
}

.btn-notification:hover {
  color: #aaa;
}

.btn-logSign {
  color: #10172f;
  font-size: 13px;
  border: 1px solid #10172f;
  border-radius: 2px;
  margin-left: 5px;
  padding: 8px 20px;
}

.btn-logSign:hover,
.btn-logSign.active {
  background: #10172f;
  color: #fff;
}

.menu {
  width: auto;
  position: relative;
}

.menu ul {
  padding: 0px 10px;
  margin: 0;
}

.loggin-box {
  display: inherit;
  align-items: end;
}
.customTable {
  overflow: hidden;
}

@media (min-width: 1091px) {
  .menu {
    margin-left: auto;
  }

  .menu ul {
    display: flex;
    align-items: center;
  }

  .loggin-box {
    display: inherit;
    display: flex;
    align-items: center;
  }
}

.proimg {
  min-width: 56px;
  min-height: 58px;
  object-fit: "cover";
  margin-top: 10px;
  border-radius: 10px;
}

#launcher {
  width: auto !important;
}

.menu ul li {
  display: inline-block;
  /* padding: 10px 15px; */
}

.menu ul li a {
  color: var(--text-grey);
  font-size: 14px !important;
  font-family: "Quicksand-Regular";
  /* text-transform: uppercase; */
  font-weight: 600;
}

.menu ul li a i {
  margin-left: 5px;
  position: relative;
  top: 6px;
}

/* .menu ul li a:hover{color:#3edfdf;text-decoration:none;} */
.menu ul li a.dropdown-toggle:after {
  display: none;
}

.btn.btnHeader {
  color: #fff !important;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: none !important;
  font-family: "Quicksand-Regular";
  padding: 10px 16px;
  margin: 0 4px;
  background-color: #30b5d1;
}

.btn.btnHeader.btnActive,
.btn.btnHeader:hover,
.btn.btnHeader:focus {
  color: #fff;
  background-color: #3e5c85;
}

.mob-menu {
  display: none;
  position: absolute;
  right: 10px;
  top: 44px;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.mob-menu:hover {
  color: #000;
  cursor: pointer;
}

.mob-menu span.menu-bar,
.mob-menu span.menu-bar:before,
.mob-menu span.menu-bar:after {
  background: var(--black-color);
}

.header.header-bg .mob-menu span.menu-bar,
.header.header-bg .mob-menu span.menu-bar:before,
.header.header-bg .mob-menu span.menu-bar:after {
  background: #fff;
}

.mob-menu span.menu-bar {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -1px;
  width: 25px;
  height: 2px;
}

.mob-menu span.menu-bar:before {
  content: "";
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -8px;
  width: 30px;
  height: 2px;
}

.mob-menu span.menu-bar:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: 6px;
  width: 30px;
  height: 2px;
}

.after-login {
}

/* .after-login ul li a{background:} */
.loggin-box {
  display: inherit;
}

/* .login-box {max-width:277px;text-align:right;} */
.login-box > .btn + .btn {
  margin-left: 7px;
}

.login-box a.dropdown-toggle {
  color: #fff;
  font-size: 13px;
  text-decoration: none;
}

.login-box a.dropdown-toggle img {
  border-radius: 6px;
}

.login-box a.dropdown-toggle:after {
  display: none;
}

.login-box a.dropdown-toggle + .dropdown-menu {
}

.login-box a.dropdown-toggle + .dropdown-menu li {
}

.login-box a.dropdown-toggle + .dropdown-menu li a {
  color: #fff;
  display: block;
  font-size: 13px;
  padding: 4px 15px;
}

.login-box a.btn-white {
  font-size: 13px;
}

.btn-bell {
  color: #3e5c85 !important;
  font-size: 26px;
  position: relative;
  padding: 6px 0 0;
  margin-left: 10px;
  /* transform:rotate(-20deg);-webkit-transform:rotate(-20deg);-ms-transform:rotate(-20deg); */
}

/* .btn-bell:hover{color:#fff;} */
.notiVal {
  position: absolute;
  width: 23px;
  height: 23px;
  top: -5px;
  right: -14px;
  border-radius: 50%;
  text-align: center;
  background: #f34040;
  color: var(--white-color);
  font-size: 10px;
  line-height: 23px;
  padding: 0px 2px;
}

.btn-user {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  padding: 0px;
}

.btn-user img {
  width: 100%;
  height: auto;
}

.dashboardIcon {
  color: #b1eb3f !important;
  font-size: 21px;
  margin-left: 35px;
}

.header_select_box {
  width: 520px;
}

.dropDownStyle .dropdown-toggle:after {
  border-top-color: #fff;
  border-width: 6px;
  display: none;
}

.dropDownStyle .dropdown-toggle i {
  margin-left: 5px;
  display: inline-block;
}

.dropdown-menu.main-dropdown {
  background: #0b172e;
  border: none;
  border-radius: 0px;
  top: 100% !important;
  padding: 0px;
}

.dropdown-menu.main-dropdown:before {
  content: "";
  border-bottom: 10px solid #0b172e;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: -9px;
  left: 12px;
}

.dropdown-menu.main-dropdown li {
  padding: 0px !important;
  display: block;
}

.dropdown-menu.main-dropdown li + li {
  /*border-top:1px dashed #fff;*/
  /*background-image: linear-gradient(to left, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0) 20%);
  background-position: top;
  background-size: 12px 1px;
  background-repeat: repeat-x; */
  border-top: 1px solid #60646c;
}

.dropdown-menu.main-dropdown li a {
  font-family: "JosefinSans-Regular";
  color: #fff;
  font-size: 13px;
  font-weight: 300;
  text-transform: none;
  text-shadow: none;
  padding: 10px;
}

.dropdown-menu.main-dropdown li a:hover {
  background: transparent;
  color: #fff;
}

.close-menu {
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  cursor: pointer;
}

.close-menu:before {
  background: #ff2801;
  content: "";
  position: absolute;
  width: 18px;
  height: 2px;
  left: 4px;
  top: 14px;
  margin-top: -1px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.close-menu:after {
  background: #ff2801;
  content: "";
  position: absolute;
  width: 18px;
  height: 2px;
  left: 4px;
  top: 14px;
  margin-top: -1px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

/* .mainContent{min-height:calc(100vh - 92px);} */

.banner-home {
  position: relative;
  width: 100%;
  padding-bottom: 0;
  padding: 0 15px;
  box-shadow: 1px 1px 8px 6px #d3f9ff;
  /* background:url(../images/top-bg.png);background-position:center;background-repeat:no-repeat; */
}

.homesection1 {
  position: absolute;
  /* left:9%;  */
}

.bannerSliderSection,
.banner-home {
  /* overflow: hidden; */
  /* background: linear-gradient(90deg,#d2d2d2 0,#54609c); */
  background: linear-gradient(90deg, #d2d2d2 0, #464f57);
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#161654', endColorstr='#0f0230', GradientType=1 ); */
  /* background: linear-gradient(to right,#430905,#0f020a); */
}

.topBanner-content {
  min-height: 700px;
  position: relative;
  z-index: 9;
}

/* .topBanner-container{width: 100%; max-width: calc(100% - 200px); margin: 0 auto;} */
.particles-animation {
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
  top: 0;
  z-index: -1;
  border-radius: 100%;
}

.topBanner-content h1 {
  text-align: justify;
  margin: 0;
  color: #141414;
  font-size: 36px;
  font-family: "JosefinSans-Regular";
  font-weight: 600;
  line-height: 1.4;
}

.topBanner-content h2 {
  color: #fff;
  font-size: 22px;
  margin: 0 0 10px;
  font-weight: 600;
}

.topBanner-content p {
  color: #3a3a3a;
  margin: 0 0 15px;
  font-size: 15px;
  font-family: "Quicksand-Regular";
}

.topBanner-content .btn + .btn {
  margin-left: 10px;
}

/* .banner-home:after {content: ""; background: #fff; height: 130px; position: absolute; bottom:-1px; left: 0; right: 0; } */
.banner_head {
  margin-bottom: 10px;
  padding-top: 80px;
}

.banner_head h2 {
  margin: 0 0 15px;
  font-size: 28px;
  color: #fff;
  line-height: 1;
}

.banner_head p {
  margin: 0px;
  color: #fff;
  font-size: 14px;
}

.banner-inner {
  min-height: 300px;
}

.coin-detailsec {
  position: relative;
  top: -50px;
  z-index: 9;
  padding: 0 !important;
}

.coin-detail-main {
  margin: 0;
  padding: 0;
  background: #fff;
  box-shadow: 0 0px 16px #fff;
  border-radius: 6px;
}

.coin-inner {
  display: flex;
  flex-wrap: wrap;
  max-width: 1050px;
  margin: 0 auto;
  min-height: 90px;
  align-items: center;
  padding: 0 50px;
}

.coin-detail-main .form-control {
  width: 25%;
  position: relative;
}

/* .coin-detail-main li:after{content:"";position:absolute;right:0;top:0;width:1px;height:100%;background:#d9d9d9;} */
.downloadBox {
  position: relative;
  align-items: flex-end;
  min-height: 390px;
  justify-content: flex-end;
}

.downloadLeft {
  width: calc(100% - 154px);
  position: absolute;
  left: -66px;
  top: -141px;
}

.downloadRight {
  width: 350px;
}

.appBox {
  justify-content: space-between;
}

.appBox a {
  width: 48%;
}

.requestLinkbox {
  margin-bottom: 15px;
}

.btn.btnYellow {
  background: #ffc107;
  font-size: 14px;
  color: #4f4f4f;
  border-radius: 4px;
  font-weight: 600;
}

.btn.btnYellow:hover,
.btn.btnYellow:focus,
.btn.btnBlack {
  background: #30b5d1;
  font-size: 14px;
  border-radius: 6px;
  font-weight: 600;
}

.btn.btnBlack:hover,
.btn.btnBlack:focus,
.btn.btnGreen,
.btn.btnWhite:hover,
.btn.btnGreen,
.btn.btnWhite:focus {
  background: #3e5c85;
  color: #fff;
}

.btn.btnWhite {
  background: #fff;
  font-size: 14px;
  color: #3e5c85;
  border-radius: 6px;
  font-weight: 600;
}

.btn.btnBlue {
  background: #16388f;
  font-size: 14px;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
}

.btn.btnGreen {
  background: #30b5d1;
  font-size: 15px;
  color: #fff !important;
  border-radius: 6px;
  font-weight: 500;
  /* width: 65%; */
}

.btn.btnSearch {
  border: none;
  font-size: 15px;
  color: #fff;
  box-shadow: 1px 1px 8px 6px #d3f9ff;
  background: #0b0125;
  /* background: linear-gradient(
    45deg,
    rgba(218, 7, 162, 1) 0%,
    rgba(93, 68, 165, 1) 47%,
    rgba(53, 176, 230, 1) 100%
  ); */
  /* background: linear-gradient(45deg, rgb(195 40 29) 0%, rgb(67 9 5) 47%, rgb(16 2 10) 100% ); */
  border-radius: 50px;
}

.btn.btnSearch:hover,
.btn.btnSearch:focus {
  opacity: 0.9;
  box-shadow: 1px 5px 8px 6px #d3f9ff;
  transform: translateY(-5px);
}

.underline {
  text-decoration: underline;
}

.sectionHead {
  margin-bottom: 30px;
}

.sectionHead h2 {
  margin: 0px;
  font-size: 26px;
  font-weight: 500;
}

.sectionHead p {
  margin: 10px 0 0;
  font-size: 15px;
  line-height: 1.4;
}

.proxiNova {
  font-family: "JosefinSans-Regular";
}

/* .sectionHead  p{ word-break: normal!important;} */
.overlaySection {
  background: #f6f6f6;
}

.overlayBox {
  overflow: hidden;
  border-radius: 6px;
  position: relative;
  margin-top: -135px;
  z-index: 9;
  margin-bottom: 50px;
}

.overlayBoxGrid {
  width: 33.33%;
  padding: 30px;
  min-height: 250px;
}

.overLayGrid1 {
  background: #dd2f3a;
}

.overLayGrid2 {
  background: #368ae3;
}

.overLayGrid3 {
  background: #29ab87;
}

.overlayImgBox {
  align-items: flex-end;
}

.overlayImg {
  max-width: 75px;
  margin-right: 15px;
}

.overlayBoxGrid strong {
  margin-bottom: -5px;
  font-size: 20px;
}

.overlayBoxGrid p {
  font-size: 13px;
  font-family: "JosefinSans-Regular";
}

/* ========innovative_companies Section============== */
.underline_link {
  text-decoration: underline;
}

.companies_slider_section {
  background: #fff;
  padding: 3em 0;
}

.companies_slider {
  padding: 0 15px;
}

.companies_slider .slick-prev:before {
  transform: rotate(134deg);
  left: 0;
}

.companies_slider .slick-next:before {
  transform: rotate(-44deg);
  right: 0px;
}

.companies_slider .slick-prev:before,
.companies_slider .slick-next:before {
  content: "";
  position: absolute;
  top: 0;
  border-right: 2px solid #929292;
  border-bottom: 2px solid #929292;
  height: 100%;
  width: 100%;
  opacity: 1;
  line-height: 12px;
}

.companies_slider .slick-next,
.companies_slider .slick-prev {
  width: 15px;
  height: 15px;
  top: 50%;
}

.companies_slider .slick-prev {
}

.companies_slider .slick-next {
}

.companies_slider .slick-track {
  padding: 15px;
}

.slideimgbox {
  padding: 0px;
  border-radius: 6px;
  /* box-shadow:0 0 20px rgba(0,0,0,.2); */
  margin-right: 24px;
  text-align: center;
  width: 236px !important;
  min-height: 198px;
  /* background:#fff0; */
  padding: 10px;
}

.slidetext {
  text-align: center;
  margin-top: 10px;
  color: #fff;
}

.slidetext h3 {
  font-size: 18px;
  margin: 0px;
}

.slidetext h6 {
  font-size: 13px;
  margin: 0 0 10px;
}

.slidetext p {
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.slideimg {
  width: 100%;
  height: 100%;
}

.slideimg img {
  border-radius: 6px;
  width: 100%;
  height: 120%;
}

.slideimg {
  max-height: 230px;
}

/* .slideimgbox:hover,.slideimgbox:focus{background:#4ec2fa;} */
.slideimgbox:hover .company-detail h5,
.slideimgbox:focus .company-detail h5,
.slideimgbox:hover .designation,
.slideimgbox:focus .designation {
  color: #fff;
}

.anchor-styling {
  color: #30b5d1 !important;
  /* background-color: #230d6d; */
  padding: 2px 7px;
  border-radius: 4px;
  font-family: "Quicksand-Medium";
  font-weight: 600;
}

.maxwidthField {
  max-width: 150px !important;
  word-break: break-word !important;
}

.anchor-styling:hover {
  cursor: pointer;
  text-decoration: underline !important;
}

.DtaeField-width {
  min-width: 170px !important;
}

.company-detail {
  padding: 0;
  text-align: center;
  margin-top: 10px;
}

.company-detail h5 {
  font-size: 15px;
  margin: 0 0 3px;
  font-weight: 600;
  line-height: 1;
  color: #4c4c4c;
  font-family: "JosefinSans-Bold";
}

.designation {
  display: inline-block;
  color: #4c4c4c;
  font-size: 12px;
}

.total-applicants {
  margin-bottom: 6px;
  display: inline-block;
  width: 100%;
}

.company-address {
  position: relative;
  padding-left: 25px;
  margin: 0;
}

.company-address img {
  position: absolute;
  left: 0;
  width: 16px;
  top: 1px;
}

.who-we-are-box {
  position: relative;
}

.rectBoxHead h3 {
  color: #4c4c4c;
  margin: 0;
  font-size: 28px;
  font-family: "JosefinSans-Regular";
  font-weight: 600;
}

.blueColor {
  color: #333346 !important;
}

.border-box {
  position: absolute;
  border: 2px solid #4b4b4b;
  width: 202px;
  height: calc(100% + 80px);
  left: -30px;
  top: -43px;
}

.border-box-content {
  position: relative;
  z-index: 9;
  background: #fff;
  padding: 20px 0;
}

.innovative_companies_section {
  padding: 3em 0;
  background: #fff;
}

.bannerSlider .slick-track {
  padding: 15px;
}

.bannerSlider {
  padding: 0 30px;
}

.bannerSlider .slick-prev:before {
  transform: rotate(135deg);
  left: 0;
}

.bannerSlider .slick-next:before {
  transform: rotate(314deg);
  right: 0px;
}

.bannerSlider .slick-prev:before,
.bannerSlider .slick-next:before {
  content: "003E";
  position: absolute;
  top: 0;
  border-right: 2px solid #929292;
  border-bottom: 2px solid #929292;
  height: 100%;
  width: 100%;
  opacity: 1;
  line-height: 12px;
}

.bannerSlider .slick-next,
.bannerSlider .slick-prev {
  z-index: 9;
  width: 15px;
  height: 15px;
  top: calc(50% - 7.5px);
}

.bannerSlider .slick-prev {
  left: 10px;
}

.bannerSlider .slick-next {
  right: 10px;
}

.prodImg {
  height: auto;
  width: 100%;
}

.prodImg .slick-next {
  right: -10px;
}

.prodImg .slick-prev {
  left: -10px;
  z-index: 1;
}

.prodImg .boxsize {
  width: 90% !important;
  height: 275px;
  padding: 0px 20px;
  margin-left: 35px;
}

.prodImg .slideimg {
  max-height: 350px;
}

.prodImg .slidetext p {
  font-size: 13px;
  white-space: normal;
  overflow: visible;
  text-align: left;
}

.tableFilter {
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
  /* margin-bottom: 30px; */
}

.tableFilter form {
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
}

.tableFilter .form-group {
  display: inline-block;
  margin: 0 10px 0 0;
  width: calc(33.33% - 47px);
}

.filterInlineField {
  width: calc(33.33% - 40px);
  padding-right: 10px;
  width: "50%";
  /* margin-bottom: 20px; */
}

.form-control.select-style1 {
  background: url("../images/arrow-icon.png") no-repeat scroll calc(100% - 9px)
    50%/12px auto;
  padding-right: 25px;
  background-color: #fff;
  font-family: "Quicksand-Regular";
  cursor: pointer;
  font-weight: 600;
}

.form-control.select-style3 {
  background: url("../images/arrow-icon.png") no-repeat scroll calc(100% - 9px)
    50%/12px auto;
  padding-right: 25px;
}

.form-control.select-style2 {
  background: url("../images/caret_arrow.png") no-repeat scroll calc(100% - 5px)
    50%/15px auto;
  padding-right: 18px;
}

.filterInlineField .form-control {
  min-height: 65px;
  border-bottom-right-radius: 5px;
  font-size: 16px;
  border-top-right-radius: 5px;
  width: "100%";
}

/* .tableFilter .btn{min-height:54px;} */
.tableFilter .input-group-append {
  margin-left: 0;
  position: relative;
}

.tableFilter .input-group-append:before {
  content: "";
  width: 1px;
  height: 70%;
  background: #ddd;
  position: absolute;
  left: 0;
  top: 9px;
}

.tableBox + .tableBox {
  margin-top: 30px;
}

.tableBoxHead {
  padding: 0 15px;
}

.tableBoxHead h4 {
  margin: 20px 0 0;
  color: #3e5c85;
  margin: 0;
  /* /  font-family:'JosefinSans-Regular'; */
}

.table.globalTable2 {
  background: #fff;
  margin: 0px;
  min-width: 730px;
  border-spacing: 40px 10px;
}

.table.globalTable2 thead th {
  background: #3e5c85;
  color: #fff !important;
}

/* .table.globalTable2 tbody{background: #161654;} */
.table.globalTable2 tr td,
.table.globalTable2 tr th {
  padding: 10px 8px;
  color: var(--text-grey);
  text-align: center;
  font-size: 16px;
  height: 80px;
  font-weight: 600;
}

.table.globalTable2 tr td {
  padding: 50px 9px !important;
}

@media screen and (max-width: 567px) {
  .table.globalTable2 tr td {
    padding: 25px 15px !important;
  }
}

.table.globalTable2 tr {
  border-bottom: 10px solid #81838490;
  background-color: var(--white-color);
}

.table.globalTable2 tr:hover {
  background-color: var(--table-hover);
}

.table.globalTable2 tr:hover .table.globalTable2 tr td {
  color: var(--primary-color) !important;
}

/* .table.globalTable2 tr:hover{border-color:#0aa619!important;} */
.btn.btnGray,
.btn.redBtn {
  background: #30b5d1;
  color: #fff !important;
  font-size: 14px;
  font-weight: 500;
}

.btn.btnGray:hover,
.btn.btnGray:focus {
  background: #3e5c85;
  color: #fff;
}

.btn.redBtn:hover,
.btn.redBtn:focus {
  background: #30b5d1;
  color: #fff;
}

.btn.btnGray.btnSell {
  background: #3e5c85;
}

.btn.btnGray.btnSell:hover,
.btn.btnGray.btnSell:focus {
  background: #30b5d1;
}

.pagination_block .pagination {
  margin: 15px 0 0;
  justify-content: center;
}

.pagination_block .page-link {
  color: #337ab7;
  font-family: "JosefinSans-Regular";
  font-size: 14px;
  border-color: #ddd;
}

.pagination_block .page-item.active .page-link {
  background: #edeef2;
  border-color: #ddd;
  color: #337ab7;
}

.showMore.btn {
  font-size: 16px;
  font-weight: 500;
  border: none;
  background: var(--btn-color);
  color: #fff;
  border-radius: 5px;
  line-height: 2;
}

.showMore.btn:hover,
.showMore.btn:focus {
  background: #3e5c85;
  color: #fff;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.payOptionBox {
  text-align: center;
}

.payicon {
  height: 65px;
  max-width: 80px;
  display: inline-block;
}

.payicon img {
  max-height: 100%;
}

.payOptionBox h4 {
  margin: 20px 0 0;
  color: #4b4b4b;
  font-size: 20px;
  font-weight: 500;
  font-family: "JosefinSans-Regular";
}

.payOptionBox label {
  margin: 0;
}

.payOptionBox p {
  margin: 20px 0 0;
}

.walletAnimateSection {
  background: url("../images/walletAnimateSection.png") no-repeat scroll 0 0 /
    cover;
  padding: 6em 0;
}

.animationWalletBox {
  border: 1px dashed #ada9a9;
  width: 450px;
  height: 450px;
}

.animationWalletBox .sectionHead {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 270px;
  display: none;
  text-align: center;
  margin: 0;
  width: 100%;
}

.animationWalletBox .sectionHead.active {
  display: block;
}

.animationWalletBox .sectionHead h2 {
  font-size: 22px;
}

.circleIcon {
  background: #fff;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  line-height: 74px;
  text-align: center;
  display: inline-block;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  transition: 1s;
}

.circleIcon img {
  max-width: 40px;
}

.circleImg2,
.circleIcon.active .circleImg1 {
  display: none;
}

.circleIcon.active .circleImg2 {
  display: inline-block;
}

.circleIcon.active {
  background: #110637;
}

.circleIcon.circleIcon1 {
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -41px;
}

.circleIcon.circleIcon2 {
  right: 0;
  top: 50px;
}

.circleIcon.circleIcon3 {
  right: -23px;
  bottom: 98px;
}

.circleIcon.circleIcon4 {
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -41px;
}

.circleIcon.circleIcon5 {
  left: -23px;
  bottom: 98px;
}

.circleIcon.circleIcon6 {
  left: 0;
  top: 50px;
}

.circleBeforeAnimate .circleIcon {
  left: 50% !important;
  top: 50% !important;
  opacity: 0;
  right: auto;
  bottom: auto;
  transition: 1s;
}

.newsCardBox {
  background: var(--white-color);
  box-shadow: 0 0 20px #ddd;
  height: 360px;
  /* box-shadow: 1px 1px 8px 6px #d3f9ff; */
  border-radius: 10px;
}

.newsCardBox figure {
  margin: 0;
}

.newsCardBox figure img {
  width: 100%;
  height: 180px;
  border-radius: 10px;
}

.newsContent {
  padding: 10px;
}

.newsContent label {
  color: #3f3f3f;
}

.newsContent h4 {
  color: var(--text-grey);
  font-family: "Quicksand-bold";
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 5px;
  line-height: 1.4;
}

.newsContent h4:hover,
.newsContent h4:focus {
  color: #3e5c85;
  cursor: pointer;
}

.newsContent p {
  margin-bottom: 10px;
  color: var(--text-grey);
  font-size: 14px;
  font-family: "Quicksand-Regular";
}

.readMore {
  color: #178569;
  font-weight: 600;
  font-family: "Quicksand-Bold";
  text-decoration: underline;
  display: flex;
  align-items: center;
  width: 110px;
  font-size: 14px;
}

.readMore img {
  width: 16px;
  left: 7px;
  transition: 1s;
  height: 16px;
  margin-top: -4px;
  position: relative;
}

.readMore:hover img,
.readMore:focus img {
  transition: 1s;
  left: 10px;
}

.createAccountBox {
  text-align: center;
  padding: 3em;
  background: var(--white-color);
  box-shadow: 0 0 20px #fff;
  border-radius: 16px;
  z-index: 1;
  margin-bottom: -60px;
  position: inherit;
  bottom: -10px;
}

.createAccountBox p {
  font-family: "Tommysoft-Bold";
  font-size: 18px;
  color: #3e5c85;
  margin-right: 30px;
  display: inline-block;
}

.createAccountSection {
  background: #17a2b8;
  padding: 20px 0;
  margin-top: 36px !important;
}

/* =================Footer=========== */
footer {
  background: url("../newImages/footer_bg.png");
  background-position: 50% 3%;
  background-repeat: no-repeat;
  padding: 50px 0px 0px;
  background-size: 100% 100%;
  position: relative;
  background-color: var(--white-color);
}

footer h2 {
  color: #fff;
  font-size: 24px;
}

.addressBox p,
.addressBox li a {
  color: #3a3a3a;
  font-family: "Quicksand-Regular";
  margin: 0;
  font-size: 14px;
  opacity: 0.9;
}

.addressBox li a:hover {
  color: #30b5d1;
}

.footerMenu li + li {
  margin-top: 5px;
}

.addressBox {
  margin-top: 10px;
}

.addressBox label {
  color: #3e5c85;
  font-family: "Quicksand-Bold";
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.copyRight {
  color: #fff;
  /* margin-top:52px; */
  font-family: "Quicksand-Regular";
  margin-bottom: 0;
}

.socialIconBox {
  max-width: 1000px;
  width: 100%;
  background: #fff;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 0 15px;
  border: 1px solid #ddd;
  justify-content: center;
  box-shadow: 0 0 20px #8c8c8c;
  position: absolute;
  left: 0;
  right: 0;
  top: -40px;
}

.socialLink {
  display: flex;
  align-items: center;
}

.socialLink li {
  padding: 15px;
  border-left: 1px solid #dcdcdc;
  width: 110px;
  text-align: center;
}

.socialIconBox label {
  color: #8c8c8c;
  margin: 0;
  font-size: 14px;
  font-family: "JosefinSans-Regular";
  min-width: 110px;
}

.footLogo {
  margin-left: 8px;
  width: 70%;
}

.jusCentr {
  justify-content: center;
}

.sprite {
  width: 25px;
  height: 25px;
  background-image: url("../images/sprite.png");
  background-repeat: no-repeat;
}

.sprite + .sprite {
  margin-left: 7px;
}

.sprite.Icon1 {
  background-position: 0px 50%;
}

.sprite.Icon2 {
  background-position: -25px 50%;
}

.sprite.Icon3 {
  background-position: -58px 50%;
}

.sprite.Icon4 {
  background-position: -90px 6px;
}

.sprite.Icon5 {
  background-position: -118px 6px;
}

.sprite.Icon6 {
  background-position: -150px 6px;
}

.sprite2 {
  width: 22px;
  height: 15px;
  margin-left: 7px;
  margin-top: 7px;
  position: absolute;
}

.teleIcon {
  width: 25px;
  height: 15px;
  bottom: 4px;
  position: relative;
  left: 7px;
}

.subsCribeBox input {
  color: #8c8c8c;
}

.staticContentSection {
  margin-top: 92px;
}

.cardBox {
  /* padding:30px; */
  border-radius: 12px;
  box-shadow: 0 4px 20px #cac7c7;
}

.cardBox .sectionHead h2 {
  text-transform: uppercase;
  font-size: 24px;
}

/* ===========Buy And Cell Details============== */
.coinDetailbox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: var(--text-grey);
}

.coinDetailbox + .coinDetailbox {
  margin-top: 10px;
}

.coinDetailLeft {
  font-weight: 600;
  width: 50%;
  padding-right: 5px;
  padding-left: 40px;
  font-family: "Quicksand-Bold";
}

.coinDetailRight {
  margin-left: auto;
  width: 50%;
  font-family: "Quicksand-Regular";
}

/* ============Wallet============= */
.coinAddress {
  position: relative;
  /* padding: 9px 55px; */
  margin: 8px auto;
  width: 75%;
}

.coinAddress input {
  padding-right: 35px;
}

.coinAddress i {
  position: absolute;
  right: 12px;
  top: 12px;
  color: #3e5c85;
}

.coinAddress span {
  display: block;
  position: absolute;
  top: 10px;
  left: 50%;
}

.tableFilter2 {
}

.datepickerField {
  margin-right: 10px;
}

.datepickerField label {
  margin: 0 7px 0 0;
  font-weight: 600;
}

.readTutoral p {
  font-size: 15px;
  margin-bottom: 0;
}

.readTutoral p + p {
  margin-top: 10px;
}

/* ==============Profile Image Box========= */
.profileImg {
  text-align: center;
  max-width: 100px;
  height: 100px;
}

.profileImg img {
  max-height: 100%;
}

.profileImgBox h5 {
  margin: 0;
  font-size: 18px;
}

.profileImgBox p {
  margin: 0;
}

.profileViewField {
  display: flex;
  width: 100%;
}

.profileViewField + .profileViewField {
  margin-top: 10px;
}

.viewFieldLeft {
  font-weight: 600;
  width: 50%;
  padding-right: 7px;
  margin: 0;
}

.viewFieldRight {
  width: 50%;
  color: var(--text-grey);
}

.feedbackBox {
  display: flex;
  width: 100%;
  position: relative;
  /* border-bottom-style:  solid ;
  border-bottom-color: #81838490; */
  border-bottom: 2px solid #81838490;
}

.feedbackBox + .feedbackBox {
  margin-top: 15px;
}

.feedUserLeft {
  min-width: 55px;
  padding-right: 10px;
  margin-bottom: 15px;
}

.feedUserImg img {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  /* margin-bottom: '10px'; */
}

.feedUserRight {
  width: 100%;
  justify-content: center;
  align-self: center;
}

.feedUserRight h5 {
  font-size: 14px;
  /* margin-top: '5px'; */
  display: flex;
  font-family: "Quicksand-Bold";
  justify-content: space-between;
  width: "100%";
}

.feedUserName {
  color: "var(--black-color)";
}

.feedTime {
  color: var(--text-grey);
  font-size: 13px;
  font-weight: 500;
  /* margin-left: auto; */
  margin-top: 5px;
}

.feedUserRight p {
  margin-bottom: 0;
}

.feedUserRight p i {
  margin-right: 3px;
}

.feedUserRight p + p {
  margin-top: 7px;
}

/* .checkDark{margin-bottom:15px;} */
.checkDark .checkbox-design {
  font-weight: 600 !important;
  font-size: 14px;
}

.checkDark p {
  font-size: 14px;
  margin: 0;
  font-weight: 500;
}

.commonTabStyle {
  max-width: 600px;
  margin: 0 auto;
}

.fixedBox {
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;
  padding: 25px 15px 0;
  background: var(--white-color);
  z-index: 9;
  top: 110px;
  height: 200px;
}

/* .scrollTabBox{margin-top: 250px !important;} */
.uploadWithButton .kycfile-uplaod {
  width: 100%;
  max-width: calc(100% - 100px);
}

.editSetting {
  position: absolute;
  right: 0;
  top: -1px;
  font-size: 20px;
}

/* =====2-11=2018==== */
#progress_bar,
#progress_bar2 {
  display: none;
}

/* custom styles by leena */
.btn-secondary {
  color: #fff;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}

.head-drop-down {
  background-color: #ffffff;
}

.dropdown-divider {
  margin: 0px;
  height: 0;
  /* margin: .5rem 0; */
  overflow: hidden;
  border-top: 1px solid #ddd;
}

.dropdown-item {
  padding: 1px 0px 1px;
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: 6px;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 25px;
  text-align: center;
}

main.mainContent {
  min-height: calc(100vh - 414px);
  background: var(--white-color);
}

.kycfile-uplaod input {
  z-index: 99;
}

.login-box .btn.btn-secondary {
  padding: 0;
  border: none;
  box-shadow: none !important;
  background-color: transparent !important;
}

.main-rowclass {
  margin: 0px 0px;
  padding: 0px 0px;
  display: -webkit-inline-box;
}

.sub-rowclass1 {
  margin: 0px 0px;
  padding-left: 0px;
  float: left;
}

.sub-rowclass2 {
  margin: 0px 0px;
  padding-right: 0px;
  float: right;
}

.ReactModal__Overlay {
  background-color: rgba(29, 25, 25, 0.75) !important;
}

.header.editheader.header-bg {
  top: -100px;
}

/* .fixedBox.fixdtop{top:0px;} */
/* .fixedBox .nav-item.active a{background: #b0e93f;} */
.ReactTags__tagInputField {
  border-color: #898989 !important;
  font-size: 14px;
  min-height: 45px;
  resize: none;
  border-radius: 4px;
  width: 100%;
  border-width: 1px;
  padding: 10px;
  border-radius: 5px !important;
}

.react-tags-wrapper .tag-wrapper.cursor-move {
  margin-right: 9px;
  cursor: pointer !important;
}

.modal-dialog.verification_modal {
  margin: 1.75rem auto;
}

.verification_modal .modal-footer {
  border: 0px;
}

.verification_modal .modal-footer > div {
  text-align: center;
}

/* ********************** */
.tags-pay .badge {
  border-radius: 5px;
  font-size: 11px;
  /* color: #568da2; */
  margin-top: 6px;
  margin-right: 3px;
  /* background-color: #f8f2f2; */
  /* border: 1px solid #afd7e6; */
  text-transform: lowercase;
  padding: 2px 5px 3px;
}

table.tableonhover tr:hover {
  /* background: linear-gradient(
  90deg
  ,#d2d2d2 0,#54609c); */
  background-color: #e5f1ee;
}

.input-per span {
  position: absolute;
  top: 0;
  right: 0;
  background: #3e5c85;
  color: #fff;
  min-height: 35px;
  min-width: 48px;
  text-align: center;
  line-height: 33px;
  border: 1px solid #3e5c85;
  font-size: 18px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.input-per2 span {
  position: absolute;
  top: 10px;
  right: 0;
  color: #3e5c85;
  min-height: 50px;
  min-width: 48px;
  text-align: center;
  line-height: 33px;
  font-size: 18px;
}

.input-per,
.input-per2,
.load-img {
  position: relative;
}

.load-img span {
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
}

/* *********Dixit******* */
.profile-name {
  margin: 0 6px;
  text-align: left;
  color: var(--text-grey);
  font-weight: 600;
}

.btn-bell {
  margin-right: 45px;
  font-size: 26px !important;
}

.dateandtime {
  display: inherit;
}

.close-menu {
  color: #fff !important;
}

/* .dropdown.common_ack{
  position: absolute;
  right: 18%;
} */
/* ********* */
.onHoverDropdown li.active,
.onHoverDropdown li:hover {
  background-color: #b1eb3f;
  border-radius: 6px;
}

.menu ul li a:hover {
  text-decoration: none;
}

.head-underline {
  position: relative;
}

.head-underline:before {
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #898989;
  height: 3px;
  width: 38%;
  content: "";
}

.createAdv .cardBox {
  border-radius: 12px;
  padding: 0px;
  box-shadow: 0 0 13px 0 var(--black-color);
}

.createAdv .sectionHead {
  padding: 20px 20px;
  background: #3e5c85;
  border-radius: 12px 12px 0 0;
  color: #fff;
}

.createAdv .sectionHead h2 {
  color: #fff;
}

.formInnerNew {
  padding: 0 50px 20px;
  justify-content: space-between;
}

.login-box .dropdown-menu.head-drop-down {
  padding: 0;
}

.login-box .dropdown-menu.head-drop-down a.dropdown-item {
  padding: 10px 10px 10px 20px;
  color: var(--text-grey);
  font-family: "Quicksand-Medium";
  font-size: 13px;
}

.login-box .dropdown-menu.head-drop-down a.dropdown-item span {
  padding-right: 15px;
  min-width: 40px;
  display: inline-block;
}

.login-box .btn.btn-secondary a img {
  display: inline-block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.userDetails label {
  color: #3e5c85;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.userDetails span {
  color: #676767;
}

.user-pic {
  margin-right: 10px;
  min-width: 60px;
}

.login-box > .dropdown:hover .head-drop-down.dropdown-menu {
  display: block;
  background-color: var(--white-color);
  border: 1px solid var(--black-color);
}

.dropdown-menu.head-drop-down:before {
  content: "";
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: -9px;
  right: 12px;
}

.user-pic img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.detail-list li {
  font-size: 14px;
  margin-left: 10px;
  list-style-type: disc;
  font-family: "Quicksand-Regular";
  color: var(--text-grey);
}

.chatCommonBlock {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background: #f1f1f1;
  max-width: 300px;
}

.chatCommonBlock.otherside {
  background: #d2f391;
  float: right;
}

.chatCommonBlock p {
  margin: 0px;
}

.uploadWithButton .textareaChat {
  width: 100%;
  max-width: calc(100% - 145px);
}

.ChatInner {
  padding: 30px 20px 10px;
  background: #f3eded;
  border-radius: 5px;
  margin: 0 0 30px;
  height: 550px;
  overflow-y: auto;
}

.nav-item.max-WT-20Per {
  max-width: 16.6% !important;
}

.chatCommonBlock img#blah {
  max-width: 25%;
  height: auto;
  width: auto;
}

.custom-switch.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.custom-switch.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 60px !important;
  height: 34px !important;
}

.custom-switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom-switch.toggle-switch input:checked + .slider {
  background-color: #3e5c85;
}

.custom-switch.toggle-switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.custom-switch .slider.round {
  border-radius: 34px;
}

.custom-switch .slider.round:before {
  border-radius: 50%;
}

.input-group.w45Per .input-group-text,
.input-group.w100Per .input-group-text {
  background-color: #3e5c85 !important;
  border: 1px solid #898989;
  color: #fff !important;
}

/* Chat window CSS */
.chat-box {
  width: 320px;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  position: fixed;
  right: 15px;
  bottom: 0px;
  background: #fff;
  height: 40px;
  transition: 10s;
  z-index: 1;
}

.chat-box-head {
  background: #fb9620;
  border: 1px solid #fff;
  border-bottom: none;
  color: #fff;
  padding: 10px 15px;
  font-size: 14px;
  position: relative;
  font-weight: 500;
  border-radius: 6px 6px 0 0;
}

.chat-box-body {
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.chat-box-head span.fa {
  position: absolute;
  right: 10px;
  top: 13px;
}

.chat-collapse.chat-box {
  height: auto;
  transition: 10s;
}

.chat {
  padding: 15px 28px;
  /* max-height: 380px;
   min-height: 250px; */
  overflow-y: auto;
}

.chat-user-name {
  font-size: 13px;
  color: #333333;
  font-weight: 700;
  margin-bottom: 7px;
  line-height: 14px;
}

.dateandtime {
  font-size: 12px;
  color: #c1c7d0;
  font-weight: 500;
}

.chat-inner-box p {
  width: 100%;
  line-height: 20px;
  font-size: 16px;
  margin: 0 0 5px;
  text-align: left;
  padding: 10px;
  text-align: justify;
  word-break: break-all;
  color: #222;
  font-weight: 500;
  font-family: "Quicksand-Regular";
  border-radius: 4px;
  font-weight: 600;
}

.chat li {
  margin-bottom: 10px;
}

.chat li:last-child {
  margin-bottom: 0px;
}

.chat li.left {
  text-align: left;
}

.chat li.right {
  text-align: right;
}

.chat li.right p {
  border: 1px solid #d1eef3;
  background: #d1eef3;
}

.chat li.left p {
  background: #ebecf0;
}

.chat-inner-box {
  max-width: 90%;
}

.chat li.left .chat-inner-box {
  float: left;
}

.chat li.right .chat-inner-box {
  float: right;
}

.chat-comment-box {
  position: relative;
  border-top: 1px solid #ddd;
}

.chat-comment-box .form-control {
  border-radius: 0;
  padding-right: 45px;
  border: none;
  box-shadow: none;
  min-height: 40px;
}

.btn.send-chat-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #222;
  background: none;
  box-shadow: none;
  padding: 3px 6px;
  font-size: 17px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  margin: 0 auto;
  width: 100%;
  z-index: 9999;
  background-color: #00000091;
  top: 0;
}

.common_ack .dropdown-menu {
  background: #fff;
  box-shadow: 0 4px 20px #ddd;
  border: none;
  border-radius: 6px;
  width: 252px;
  padding: 15px 0px 15px 12px;
  left: auto;
  right: -35px;
  transform: none;
  top: 43px;
}

.common_ack .dropdown-menu:before,
.common_ack .dropdown-menu:after {
  content: "";
  position: absolute;
}

.common_ack .dropdown-menu:before {
  border-bottom: 10px solid #fff;
  top: -10px;
  right: 35px;
  z-index: 9;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

.common_ack .dropdown-menu:after {
  border-bottom: 12px solid #eee;
  top: -12px;
  right: 33px;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
}

.dropdown:hover .dropdown-menu,
.dropdown:focus .dropdown-menu {
  display: block !important;
  background-color: var(--white-color);
}

.dropdown-title {
  color: #3e5c85;
  font-size: 16px;
  margin: 0 0 15px;
}

.dropdown_list {
  margin: 0;
}

.dropdown_list li + li {
  margin-top: 15px;
}

.dropdown_list h6 {
  margin: 0 0 7px;
  font-size: 12px;
  color: #3e5c85;
  line-height: 1;
}

.dropdown_list p {
  margin: 0;
  color: #707070;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 600;
}

.full_view_btn {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  width: 20px;
}

.dropdown_list li {
  padding-left: 45px;
  min-height: 30px;
  position: relative;
}

.notifyImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
}

.notifyImg img {
  border-radius: 100%;
  height: 100%;
  width: 100%;
}

.common_ack .dropdown-menu ul.dropdown_list li {
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  padding-left: 0;
  display: block;
}

.dropdown-body {
  overflow: auto;
  max-height: 335px;
  padding: 0 20px 0 0;
}

li.dropdown.common_ack {
  cursor: pointer;
}

.btn.btnGreen.cur:not(:disabled):not(.disabled),
.btn.btnRed.cur:not(:disabled):not(.disabled) {
  cursor: auto;
}

.btn.btnSearch.cur:not(:disabled):not(.disabled) {
  cursor: auto;
}

.smallslide-box {
  padding: 10px;
  height: 100px;
  width: 100px;
}

@media (min-width: 768px) {
  /* show 3 items */
  bannerSliderSection .carousel-inner .active,
  bannerSliderSection .carousel-inner .active + .carousel-item,
  bannerSliderSection .carousel-inner .active + .carousel-item + .carousel-item,
  bannerSliderSection
    .carousel-inner
    .active
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    display: block;
  }

  bannerSliderSection
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  bannerSliderSection
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
    + .carousel-item,
  bannerSliderSection
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
    + .carousel-item
    + .carousel-item,
  bannerSliderSection
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    transition: none;
  }

  bannerSliderSection .carousel-inner .carousel-item-next,
  bannerSliderSection .carousel-inner .carousel-item-prev {
    position: relative;
    transform: translate3d(0, 0, 0);
  }

  bannerSliderSection
    .carousel-inner
    .active.carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: absolute;
    top: 0;
    right: -25%;
    z-index: -1;
    display: block;
    visibility: visible;
  }

  /* left or forward direction */
  bannerSliderSection
    .active.carousel-item-left
    + .carousel-item-next.carousel-item-left,
  bannerSliderSection .carousel-item-next.carousel-item-left + .carousel-item,
  bannerSliderSection
    .carousel-item-next.carousel-item-left
    + .carousel-item
    + .carousel-item,
  bannerSliderSection
    .carousel-item-next.carousel-item-left
    + .carousel-item
    + .carousel-item
    + .carousel-item,
  bannerSliderSection
    .carousel-item-next.carousel-item-left
    + .carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  /* farthest right hidden item must be abso position for animations */
  bannerSliderSection .carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible;
  }

  /* right or prev direction */
  bannerSliderSection
    .active.carousel-item-right
    + .carousel-item-prev.carousel-item-right,
  bannerSliderSection .carousel-item-prev.carousel-item-right + .carousel-item,
  bannerSliderSection
    .carousel-item-prev.carousel-item-right
    + .carousel-item
    + .carousel-item,
  bannerSliderSection
    .carousel-item-prev.carousel-item-right
    + .carousel-item
    + .carousel-item
    + .carousel-item,
  bannerSliderSection
    .carousel-item-prev.carousel-item-right
    + .carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}

/* http://www.menucool.com/jquery-slider */
#thumbnail-slider {
  margin: 0 auto;
  /* center-aligned */
  width: 100%;
  /* width:400px; */
  max-width: 600px;
  padding: 20px;
  background-color: #f2f1ea;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}

#thumbnail-slider div.inner {
  /* the followings should not be changed */
  position: relative;
  overflow: hidden;
  padding: 2px 0;
  margin: 0;
}

#thumbnail-slider div.inner ul {
  /* the followings should not be changed */
  white-space: nowrap;
  position: relative;
  left: 0;
  top: 0;
  list-style: none;
  font-size: 0;
  padding: 0;
  margin: 0;
  float: left !important;
  width: auto !important;
  height: auto !important;
}

#thumbnail-slider ul li {
  min-height: 100px;
  min-width: 100px;
  display: inline-block;
  display: inline !important;
  /* IE7 hack */
  margin: 0;
  margin-right: 10px;
  /* Spacing between thumbs */
  transition: border-color 0.3s;
  box-sizing: content-box;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  position: relative;
  list-style: none;
  backface-visibility: hidden;
}

#thumbnail-slider ul li.active {
  border-color: white;
}

#thumbnail-slider .thumb {
  opacity: 1;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  position: absolute;
  font-size: 0;
}

/* --------- navigation controls ------- */
/* The nav id should be: slider id + ("-prev", "-next", and "-pause-play") */
#thumbnail-slider-pause-play {
  display: none;
}

/* .pause */
#thumbnail-slider-prev,
#thumbnail-slider-next {
  opacity: 1;
  position: absolute;
  /* background-color:#0346a3; */
  background-color: #ccc;
  /* IE7 hack */
  backface-visibility: hidden;
  width: 32px;
  height: 60px;
  line-height: 60px;
  top: 50%;
  margin: 0;
  margin-top: -30px;
  color: white;
  z-index: 10;
  cursor: pointer;
}

#thumbnail-slider-prev {
  left: -30px;
  right: auto;
}

#thumbnail-slider-next {
  left: auto;
  right: -30px;
}

#thumbnail-slider-next.disabled,
#thumbnail-slider-prev.disabled {
  opacity: 0.3;
  cursor: default;
}

/* arrows */
#thumbnail-slider-prev::before,
#thumbnail-slider-next::before {
  position: absolute;
  top: 19px;
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-left: 6px solid black;
  border-top: 6px solid black;
}

#thumbnail-slider-prev::before {
  left: 7px;
  -ms-transform: rotate(-45deg);
  /* IE9 */
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#thumbnail-slider-next::before {
  right: 7px;
  -ms-transform: rotate(135deg);
  /* IE9 */
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

/* Responsive settings */
@media only screen and (max-width: 736px) {
  #thumbnail-slider {
    padding: 10px 26px;
  }

  #thumbnail-slider-prev {
    left: 0px;
  }

  #thumbnail-slider-next {
    right: 0px;
  }
}

button.slick-arrow.slick-prev:before {
  font-size: 24px;
  color: #b1eb3f !important;
  content: " \003C" !important;
  font-weight: bold;
  opacity: 1;
}

button.slick-arrow.slick-next:before {
  font-size: 24px;
  color: #b1eb3f !important;
  font-weight: bold;
  opacity: 1;
  content: " \003E" !important;
}

img.thumb:hover {
  -ms-transform: scale(1.8);
  /* IE 9 */
  -webkit-transform: scale(1.8);
  /* Safari 3-8 */
  transform: scale(1.8);
}

.slick-list {
  padding-left: 0px !important;
  padding: 10px;
  padding-right: 0px !important;
}

img#blah:hover {
  height: 40%;
  width: 40%;
}

.mt-0 {
  margin: 0px !important;
}

.personal-notify .notiVal {
  position: relative;
  top: 0px;
  right: auto;
}

.checknotify {
  margin-top: 6px;
}

.tradingChatList {
  max-height: 270px;
}

.dashboardSection a.nav-link:hover {
  cursor: pointer;
}

.featuredLogo {
  align-items: center;
}

.featuredContent {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
}

.featuredLogo img {
  /* width: 100%; */
  width: 65%;
  object-fit: cover;
}

.footerIcon {
  /* margin-top: 30px; */
  display: flex;
  justify-content: center;
}

.footerLogo img {
  max-width: 150px;
}

.footerLogo {
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* .textbutton{float: right} */
a.btn.btnHeader.btnActive.ml15.newButton {
  float: right;
}

.textbutton {
  margin-top: 30px;
}

input.form-control.borderRight0.radius-TR0.radius-BR0 {
  /* border: none; */
  font-size: 14px;
  font-weight: 600;
}

select.form-control.select-style1.radius-TL0.radius-BL0.borderLeft0 {
  /* border: none; */
  font-family: "Quicksand-Regular";
  cursor: pointer;
}

.input-group {
  /* border: 1px solid #898989; */
  border-radius: 0.25rem;
}

.sectionHead p {
  margin: 20px 0 0px !important;
  color: var(--text-grey);
  font-size: 16px;
  text-align: justify;
}

.largeImg {
  width: 65%;
  margin: 0 auto;
}

p.parastyle {
  font-size: 16px;
  line-height: 1.4;
  text-align: justify;
  font-family: "Quicksand-Regular";
  color: #3a3a3a;
}

/* .nav-tabs.globalTab .nav-link {
  min-height: 62px;
  padding-top: 22px!important;
  font-size: 12px!important;
  padding: 4px 4px!important;
} */
.tableBoxBody {
  /* border: 1px solid #c3c3c3; */
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;
}

.newsCardBox figure img {
  max-height: 222px;
  max-width: 100%;
  object-fit: cover;
}

.footerIcon {
  overflow: auto;
}

button.btn.btnGreen {
  font-family: "Quicksand-Regular" !important;
}

button.btn.btnSearch {
  font-family: "Quicksand-Regular" !important;
}

a.forgotpass.ml-auto {
  font-family: "Quicksand-Bold" !important;
}

label.checkbox-design.mb0 {
  font-family: "Quicksand-Regular" !important;
}

form {
  font-family: "Quicksand-Regular" !important;
}

form label {
  font-family: "Quicksand-Bold" !important;
}

p.formLinksInfo {
  font-family: "Quicksand-Regular" !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "Quicksand-Regular" !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Quicksand-Regular" !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "Quicksand-Regular" !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-family: "Quicksand-Regular" !important;
}

input.form-control {
  font-family: "Quicksand-Regular" !important;
}

button.btn {
  font-family: "Quicksand-Regular" !important;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

/* body { top: 0px !important;color: #fff; } */
/* a.goog-logo-link{
  display: none;

} */
select.goog-te-combo {
  padding: 6px 8px;
  border-radius: 4px;
}

.tradeAln {
  color: #fff;
  font-size: 13px;
  cursor: pointer;
  width: 25%;
  height: 34px;
  border-radius: 4px;
  padding: 6px 5px;
  background-color: #17a2b8;
  text-align: center;
  margin-left: auto;
  margin-right: 15px;
}

.tradePr {
  /* display: flex; */
}

.mk2 {
  width: 62%;
}

/* ******sanjeev css ********* */
.tagLine {
  text-align: center;
  margin-top: 0px;
  font-size: 35px;
  font-weight: bold;
  color: #000;
}

.tagLab {
  margin-bottom: 20px;
}

.buysll {
  width: 100%;
  position: relative;
  text-align: -webkit-center;
}

.nww {
  width: 60%;
  background: #110637;
  /* background-color: #cbeef9; */
  padding: 15px;
  border-radius: 0 0 20px 20px;
  /* background: linear-gradient(to right, #161654 0%, #0f0230 100%); */
  /* background:linear-gradient(to right,#430905,#0f020a); */
  box-shadow: 0px 4px 8px 4px #d3f9ff;
}

.sectMar {
  margin-top: -68px;
}

.tbl {
  border-radius: 5px;
  /* border: 1px solid #c3c3c3; */
  border: none;
  /* box-shadow: 0px 1px 14px 2px #cbc9d1; */
  box-shadow: 0px 1px 14px 2px var(--black-color);
}

.tbl_hd {
  /* border-bottom: 10px solid #f7f375; */
  margin-top: 10px;
  background-color: yellow;
}

.table-bordered td,
.table-bordered th {
  border: none !important;
}

.usr_st {
  padding-left: 14px;
  text-align: left;
}

.Toastify__toast--error {
  border-radius: 3px 15px !important;
  background: #dc3545d9 !important;
}

.Toastify__toast--success {
  border-radius: 3px 15px !important;
  background: #17a2b8d9 !important;
}

button.slick-arrow.slick-next:before {
  font-size: 20px !important;
  color: #30b5d1 !important;
  font-family: monospace;
}

.slick-next {
  border-radius: 50%;
  background: transparent;
  border: 1px solid #3e5c85;
}

.slick-prev {
  border-radius: 50%;
  background: transparent;
  border: 1px solid #3e5c85;
}

.slick-prev,
.slick-next {
  width: 25px !important;
  height: 25px !important;
}

button.slick-arrow.slick-prev:before {
  font-size: 20px !important;
  color: #30b5d1 !important;
  font-family: monospace;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background: #3e5c85;
}

.lang {
  width: 164px;
  float: right;
}

.stars {
  z-index: 10;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 2387px 226px #fff, 1165px 569px #fff, 765px 1910px #fff,
    1455px 311px #fff, 477px 969px #fff, 548px 1571px #fff, 891px 992px #fff,
    1687px 1454px #fff, 2416px 1259px #fff, 340px 1306px #fff, 2511px 934px #fff,
    2031px 451px #fff, 242px 352px #fff, 1961px 35px #fff, 1506px 18px #fff,
    458px 2420px #fff, 2400px 1861px #fff, 1556px 681px #fff, 1603px 674px #fff,
    2179px 2283px #fff, 250px 1826px #fff, 452px 2120px #fff, 400px 24px #fff,
    825px 2070px #fff, 140px 878px #fff, 7px 1966px #fff, 391px 2474px #fff,
    1954px 2559px #fff, 48px 1494px #fff, 614px 2096px #fff, 161px 824px #fff,
    1983px 1539px #fff, 2430px 2452px #fff, 2249px 1238px #fff,
    1919px 213px #fff, 1005px 844px #fff, 1147px 1236px #fff, 1631px 2402px #fff,
    1501px 1968px #fff, 2244px 498px #fff, 1748px 753px #fff, 2555px 1857px #fff,
    886px 1686px #fff, 1844px 363px #fff, 357px 227px #fff, 1128px 2361px #fff,
    719px 2012px #fff, 2332px 579px #fff, 1866px 1841px #fff, 2136px 723px #fff,
    1747px 1959px #fff, 1948px 2032px #fff, 2548px 1285px #fff, 316px 599px #fff,
    765px 565px #fff, 1138px 1066px #fff, 1365px 1275px #fff, 268px 593px #fff,
    1228px 417px #fff, 607px 2075px #fff, 63px 164px #fff, 2308px 668px #fff,
    1756px 512px #fff, 1056px 1854px #fff, 1485px 555px #fff, 870px 2531px #fff,
    1151px 1981px #fff, 2334px 764px #fff, 1483px 94px #fff, 173px 526px #fff,
    1210px 2077px #fff, 1329px 551px #fff, 1535px 922px #fff, 2318px 742px #fff,
    2258px 774px #fff, 166px 361px #fff, 1472px 927px #fff, 520px 331px #fff,
    1360px 1677px #fff, 2498px 634px #fff, 284px 1972px #fff, 289px 260px #fff,
    632px 181px #fff, 2308px 1179px #fff, 2045px 14px #fff, 858px 1154px #fff,
    1669px 789px #fff, 2368px 206px #fff, 320px 703px #fff, 463px 1221px #fff,
    2102px 2490px #fff, 904px 2296px #fff, 2373px 1033px #fff,
    2487px 1678px #fff, 226px 702px #fff, 747px 595px #fff, 391px 1927px #fff,
    2335px 1037px #fff, 853px 1549px #fff, 802px 176px #fff, 2234px 66px #fff,
    2523px 676px #fff, 2405px 406px #fff, 1145px 151px #fff, 1564px 2138px #fff,
    1053px 1732px #fff, 2354px 2032px #fff, 952px 838px #fff, 732px 2202px #fff,
    464px 1519px #fff, 1323px 684px #fff, 1086px 1660px #fff, 1257px 1209px #fff,
    1328px 21px #fff, 815px 1449px #fff, 1310px 932px #fff, 1845px 2361px #fff,
    587px 248px #fff, 1498px 406px #fff, 1444px 1847px #fff, 1384px 1747px #fff,
    2078px 670px #fff, 1441px 1001px #fff, 620px 55px #fff, 67px 1051px #fff,
    1511px 1589px #fff, 1827px 1328px #fff, 1858px 1281px #fff,
    2406px 2449px #fff, 1426px 129px #fff, 2385px 2490px #fff, 306px 638px #fff,
    1877px 438px #fff, 468px 2396px #fff, 247px 146px #fff, 646px 1539px #fff,
    2004px 1509px #fff, 1022px 1076px #fff, 1782px 1885px #fff,
    1948px 2204px #fff, 2488px 618px #fff, 2465px 2530px #fff,
    1699px 1312px #fff, 2191px 1142px #fff, 859px 2044px #fff, 262px 1329px #fff,
    269px 555px #fff, 496px 5px #fff, 950px 1240px #fff, 533px 2151px #fff,
    2271px 2340px #fff, 2560px 2437px #fff, 1523px 991px #fff, 837px 977px #fff,
    633px 1769px #fff, 116px 1246px #fff, 629px 974px #fff, 434px 1048px #fff,
    1720px 905px #fff, 116px 125px #fff, 1700px 2136px #fff, 254px 1115px #fff,
    2477px 167px #fff, 106px 2558px #fff, 832px 2247px #fff, 1886px 2354px #fff,
    1074px 1811px #fff, 2469px 475px #fff, 2454px 775px #fff, 1176px 2067px #fff,
    1690px 1544px #fff, 1985px 47px #fff, 414px 2166px #fff, 1986px 1875px #fff,
    97px 1577px #fff, 884px 607px #fff, 1531px 213px #fff, 1383px 789px #fff,
    1209px 1352px #fff, 2527px 1284px #fff, 1286px 1628px #fff,
    1284px 362px #fff, 2478px 1858px #fff, 2058px 1134px #fff, 1782px 123px #fff,
    553px 1221px #fff, 1289px 1041px #fff, 240px 543px #fff, 1575px 1584px #fff,
    1740px 1969px #fff, 478px 531px #fff, 2494px 1166px #fff, 178px 1321px #fff,
    890px 700px #fff, 1571px 2150px #fff, 42px 878px #fff, 126px 720px #fff,
    1304px 139px #fff, 2512px 1066px #fff, 17px 1559px #fff, 1055px 140px #fff,
    1490px 2554px #fff, 242px 575px #fff, 1920px 2006px #fff, 180px 1507px #fff,
    875px 539px #fff, 645px 1254px #fff, 1050px 1857px #fff, 2434px 1724px #fff,
    1628px 2138px #fff, 1695px 2468px #fff, 2131px 305px #fff, 250px 1593px #fff,
    780px 1809px #fff, 768px 1470px #fff, 1651px 431px #fff, 858px 1269px #fff,
    1743px 1004px #fff, 36px 1353px #fff, 1069px 736px #fff, 1397px 1157px #fff,
    876px 287px #fff, 1778px 2427px #fff, 432px 1102px #fff, 1085px 1121px #fff,
    1386px 1px #fff, 1406px 1133px #fff, 2214px 590px #fff, 17px 2436px #fff,
    885px 1685px #fff, 1516px 1716px #fff, 1835px 1059px #fff, 122px 2112px #fff,
    1081px 2545px #fff, 917px 2257px #fff, 2176px 895px #fff, 2438px 1771px #fff,
    544px 865px #fff, 871px 1458px #fff, 572px 866px #fff, 1070px 655px #fff,
    1707px 289px #fff, 2509px 1287px #fff, 1296px 578px #fff, 1202px 2457px #fff,
    327px 574px #fff, 2241px 2558px #fff, 219px 1838px #fff, 2167px 270px #fff,
    344px 872px #fff, 1742px 2337px #fff, 746px 2238px #fff, 117px 2154px #fff,
    2558px 1901px #fff, 2138px 195px #fff, 1592px 230px #fff, 1395px 788px #fff,
    1126px 908px #fff, 517px 2283px #fff, 546px 358px #fff, 290px 248px #fff,
    905px 1743px #fff, 942px 1757px #fff, 1045px 1338px #fff, 1094px 50px #fff,
    2124px 776px #fff, 1269px 1559px #fff, 2255px 949px #fff, 1877px 2009px #fff,
    2159px 1342px #fff, 1508px 2512px #fff, 581px 147px #fff, 2462px 169px #fff,
    163px 873px #fff, 686px 2452px #fff, 1717px 1925px #fff, 705px 2290px #fff,
    1028px 2059px #fff, 294px 1040px #fff, 2322px 1085px #fff,
    1425px 2095px #fff, 811px 2128px #fff, 936px 893px #fff, 1273px 1904px #fff,
    665px 2519px #fff, 795px 1559px #fff, 352px 1997px #fff, 13px 1171px #fff,
    483px 1064px #fff, 270px 568px #fff, 1px 66px #fff, 1702px 379px #fff,
    2023px 1815px #fff, 432px 841px #fff, 734px 1756px #fff, 1999px 2494px #fff,
    2009px 2380px #fff, 906px 2195px #fff, 2250px 2181px #fff,
    2061px 2071px #fff, 251px 2198px #fff, 1303px 2248px #fff,
    1434px 1438px #fff, 1395px 1830px #fff, 2392px 1391px #fff, 83px 1443px #fff,
    440px 893px #fff, 1497px 1673px #fff, 1251px 1366px #fff, 1919px 1994px #fff,
    1187px 1540px #fff, 2331px 1272px #fff, 1833px 980px #fff,
    1365px 1177px #fff, 291px 2279px #fff, 126px 1781px #fff, 518px 1323px #fff,
    1041px 2126px #fff, 1226px 1250px #fff, 1994px 326px #fff,
    1515px 1903px #fff, 1882px 508px #fff, 2287px 1563px #fff,
    2477px 2322px #fff, 22px 421px #fff, 2081px 2250px #fff, 1014px 2060px #fff,
    1520px 746px #fff, 335px 1188px #fff, 1957px 1021px #fff, 2096px 1036px #fff,
    169px 1253px #fff, 1728px 755px #fff, 1868px 1063px #fff, 448px 51px #fff,
    831px 686px #fff, 743px 953px #fff, 1906px 1300px #fff, 669px 424px #fff,
    1508px 1820px #fff, 2289px 1993px #fff, 727px 680px #fff, 892px 820px #fff,
    2068px 403px #fff, 2059px 2411px #fff, 723px 1119px #fff, 602px 2008px #fff,
    977px 680px #fff, 2395px 1461px #fff, 1605px 2235px #fff, 776px 1462px #fff,
    111px 1713px #fff, 1179px 1759px #fff, 2086px 1010px #fff, 556px 1262px #fff,
    2430px 1703px #fff, 124px 882px #fff, 1142px 227px #fff, 1674px 384px #fff,
    1100px 401px #fff, 2283px 2321px #fff, 1227px 1208px #fff,
    2027px 1565px #fff, 173px 761px #fff, 800px 2555px #fff, 1482px 2207px #fff,
    1718px 912px #fff, 1930px 206px #fff, 701px 2149px #fff, 2052px 1784px #fff,
    1688px 322px #fff, 461px 2291px #fff, 927px 1654px #fff, 1051px 2292px #fff,
    2423px 1286px #fff, 941px 1550px #fff, 598px 1020px #fff, 155px 1940px #fff,
    2414px 2486px #fff, 815px 233px #fff, 505px 1965px #fff, 465px 1673px #fff,
    1449px 1329px #fff, 2046px 546px #fff, 825px 201px #fff, 2525px 171px #fff,
    2404px 1389px #fff, 759px 1957px #fff, 1029px 1557px #fff, 140px 2523px #fff,
    1197px 881px #fff, 2492px 1957px #fff, 621px 1444px #fff, 254px 24px #fff,
    1077px 2553px #fff, 2024px 161px #fff, 1302px 1571px #fff,
    1393px 1264px #fff, 119px 2313px #fff, 2374px 2206px #fff,
    1805px 1357px #fff, 238px 1427px #fff, 2478px 1455px #fff, 1311px 779px #fff,
    1589px 1218px #fff, 1193px 2118px #fff, 595px 998px #fff, 2482px 1237px #fff,
    2267px 2015px #fff, 1082px 659px #fff, 2481px 980px #fff, 458px 758px #fff,
    949px 857px #fff, 1921px 108px #fff, 894px 732px #fff, 1287px 2321px #fff,
    67px 118px #fff, 1047px 967px #fff, 2276px 1221px #fff, 2221px 2078px #fff,
    1809px 232px #fff, 1323px 1363px #fff, 227px 262px #fff, 1998px 211px #fff,
    631px 1262px #fff, 2487px 468px #fff, 2510px 1217px #fff, 48px 535px #fff,
    2011px 884px #fff, 85px 16px #fff, 1361px 2447px #fff, 2351px 1488px #fff,
    2082px 849px #fff, 1572px 1981px #fff, 2211px 583px #fff, 775px 1082px #fff,
    118px 1803px #fff, 1700px 425px #fff, 2065px 274px #fff, 967px 349px #fff,
    1452px 1173px #fff, 1237px 308px #fff, 1277px 2088px #fff,
    1948px 1848px #fff, 18px 1399px #fff, 1399px 819px #fff, 914px 606px #fff,
    435px 322px #fff, 148px 633px #fff, 342px 1358px #fff, 1899px 343px #fff,
    1646px 1802px #fff, 549px 25px #fff, 777px 76px #fff, 2290px 2305px #fff,
    403px 1582px #fff, 450px 1038px #fff, 1711px 212px #fff, 1567px 148px #fff,
    242px 980px #fff, 1632px 2186px #fff, 625px 198px #fff, 298px 1562px #fff,
    1312px 1734px #fff, 1864px 1519px #fff, 93px 218px #fff, 133px 2454px #fff,
    568px 2306px #fff, 2076px 1979px #fff, 2191px 282px #fff, 2230px 995px #fff,
    1995px 416px #fff, 1700px 934px #fff, 618px 419px #fff, 2498px 1187px #fff,
    643px 1531px #fff, 1643px 157px #fff, 2057px 117px #fff, 678px 609px #fff,
    1902px 1533px #fff, 1168px 2144px #fff, 565px 2286px #fff, 643px 1005px #fff,
    610px 16px #fff, 1578px 1296px #fff, 1604px 1970px #fff, 1215px 2462px #fff,
    158px 1260px #fff, 2055px 1063px #fff, 955px 2397px #fff, 953px 1766px #fff,
    1654px 2310px #fff, 2446px 65px #fff, 1900px 1840px #fff, 1101px 2464px #fff,
    917px 389px #fff, 2036px 303px #fff, 81px 1448px #fff, 701px 2527px #fff,
    1296px 1745px #fff, 876px 510px #fff, 1699px 149px #fff, 44px 1933px #fff,
    2528px 1972px #fff, 1486px 2044px #fff, 1215px 1834px #fff,
    1876px 767px #fff, 1218px 389px #fff, 2105px 1948px #fff, 1454px 955px #fff,
    165px 1790px #fff, 1893px 2377px #fff, 622px 1454px #fff, 1971px 415px #fff,
    2372px 2112px #fff, 1473px 1993px #fff, 1208px 2329px #fff,
    2276px 1119px #fff, 2445px 2419px #fff, 642px 1297px #fff, 653px 2204px #fff,
    2556px 96px #fff, 2109px 1946px #fff, 1036px 1500px #fff, 8px 1882px #fff,
    2357px 1002px #fff, 1803px 1973px #fff, 925px 269px #fff, 365px 2551px #fff,
    1998px 2409px #fff, 675px 1505px #fff, 426px 29px #fff, 1898px 529px #fff,
    2000px 2490px #fff, 259px 333px #fff, 1035px 2517px #fff, 2351px 961px #fff,
    2467px 16px #fff, 1543px 1454px #fff, 2116px 276px #fff, 2125px 365px #fff,
    1967px 2146px #fff, 1486px 326px #fff, 2182px 817px #fff, 1485px 1251px #fff,
    1480px 1130px #fff, 2296px 595px #fff, 719px 1743px #fff, 1941px 1755px #fff,
    1765px 1345px #fff, 2025px 1550px #fff, 765px 1265px #fff, 685px 404px #fff,
    1785px 2334px #fff, 136px 2028px #fff, 1040px 605px #fff, 326px 1295px #fff,
    1987px 1289px #fff, 1235px 1368px #fff, 1262px 753px #fff,
    2123px 1486px #fff, 1346px 2139px #fff, 794px 1578px #fff,
    1865px 2023px #fff, 497px 546px #fff, 826px 2299px #fff, 368px 131px #fff,
    142px 2420px #fff, 796px 2032px #fff, 1240px 1027px #fff, 1672px 195px #fff,
    1747px 1956px #fff, 1340px 2408px #fff, 1007px 1511px #fff,
    957px 1205px #fff, 294px 1610px #fff, 2122px 2291px #fff, 1179px 901px #fff,
    683px 576px #fff, 214px 1999px #fff, 902px 2256px #fff, 1513px 965px #fff,
    1325px 2169px #fff, 18px 1690px #fff, 2540px 1928px #fff, 2242px 1108px #fff,
    2446px 2320px #fff, 2089px 553px #fff, 1447px 2503px #fff,
    1474px 1612px #fff, 580px 2524px #fff, 408px 727px #fff, 1549px 218px #fff,
    2011px 1290px #fff, 2446px 2166px #fff, 80px 172px #fff, 861px 2010px #fff,
    246px 1733px #fff, 1807px 1693px #fff, 1173px 1632px #fff, 1386px 527px #fff,
    1387px 376px #fff, 1171px 918px #fff, 478px 706px #fff, 562px 1309px #fff,
    799px 72px #fff, 2402px 984px #fff, 1036px 1929px #fff, 1194px 1655px #fff,
    1968px 2032px #fff, 1009px 139px #fff, 1363px 2190px #fff, 534px 296px #fff,
    1702px 1063px #fff, 163px 1987px #fff, 1932px 1758px #fff, 708px 1683px #fff,
    969px 1916px #fff, 779px 1942px #fff, 2394px 666px #fff, 221px 1467px #fff,
    1490px 2158px #fff, 1690px 137px #fff, 137px 1228px #fff, 1046px 2020px #fff,
    2203px 1693px #fff, 2266px 1831px #fff, 59px 2404px #fff, 1620px 263px #fff,
    1713px 588px #fff, 419px 2246px #fff, 1246px 2432px #fff, 778px 1942px #fff,
    2514px 658px #fff, 2084px 259px #fff, 1613px 107px #fff, 2115px 2506px #fff,
    2121px 4px #fff, 1784px 307px #fff, 182px 1496px #fff, 1683px 995px #fff,
    929px 1315px #fff, 2118px 1747px #fff, 1891px 2395px #fff, 518px 2103px #fff,
    1593px 2383px #fff, 1391px 2290px #fff, 1551px 2393px #fff,
    1459px 925px #fff, 1106px 2199px #fff, 533px 852px #fff, 1919px 420px #fff,
    211px 1040px #fff, 1320px 2128px #fff, 406px 1296px #fff, 2430px 2230px #fff,
    1980px 405px #fff, 2155px 1018px #fff, 1408px 1023px #fff,
    1539px 2235px #fff, 284px 1283px #fff, 1098px 1745px #fff,
    1438px 1343px #fff, 1020px 1454px #fff, 1274px 2536px #fff,
    663px 1278px #fff, 71px 2515px #fff, 1914px 1201px #fff, 1721px 1137px #fff,
    1622px 2191px #fff, 2110px 1260px #fff, 2495px 1590px #fff,
    1846px 2068px #fff, 1570px 1853px #fff, 478px 1452px #fff, 699px 1694px #fff,
    984px 2154px #fff, 2205px 1613px #fff, 1787px 1987px #fff,
    2351px 2240px #fff, 1227px 1425px #fff, 2430px 2144px #fff,
    2404px 357px #fff, 560px 1754px #fff, 1305px 796px #fff, 1429px 2287px #fff,
    185px 1132px #fff, 2095px 116px #fff, 609px 608px #fff, 329px 2417px #fff,
    858px 1141px #fff, 85px 229px #fff, 1254px 293px #fff, 1161px 1999px #fff,
    908px 1391px #fff, 1744px 2282px #fff, 120px 1300px #fff, 1848px 976px #fff,
    1278px 1393px #fff, 156px 1206px #fff, 1107px 304px #fff, 692px 1099px #fff,
    2328px 586px #fff, 2394px 1823px #fff, 1488px 185px #fff, 1456px 1328px #fff,
    1028px 2207px #fff, 2112px 337px #fff, 1234px 413px #fff, 523px 1903px #fff,
    1159px 478px #fff, 2406px 753px #fff, 178px 1562px #fff, 1449px 71px #fff,
    1434px 2335px #fff, 1357px 2031px #fff, 1398px 926px #fff,
    1591px 2161px #fff, 2202px 2386px #fff, 1853px 1899px #fff,
    1650px 675px #fff, 2068px 555px #fff, 984px 2234px #fff, 1388px 258px #fff,
    137px 1613px #fff, 1950px 1193px #fff, 127px 281px #fff, 662px 2px #fff,
    835px 1632px #fff, 1091px 229px #fff, 1524px 2126px #fff, 1621px 2414px #fff,
    1286px 1161px #fff, 246px 2049px #fff, 797px 2244px #fff, 88px 956px #fff,
    502px 1994px #fff, 33px 99px #fff, 433px 283px #fff, 539px 1562px #fff,
    2166px 871px #fff, 477px 2463px #fff, 118px 727px #fff, 1824px 1732px #fff,
    2410px 147px #fff, 2056px 1001px #fff, 2211px 416px #fff, 741px 2306px #fff,
    2343px 904px #fff, 507px 1853px #fff, 2258px 1922px #fff, 1422px 1019px #fff,
    1635px 2373px #fff, 1981px 916px #fff, 392px 580px #fff, 1598px 714px #fff,
    2056px 1759px #fff, 406px 530px #fff, 1293px 1154px #fff, 38px 433px #fff,
    79px 2359px #fff, 419px 2128px #fff, 113px 1300px #fff, 575px 2324px #fff,
    2160px 1696px #fff, 1576px 1071px #fff, 1968px 392px #fff, 916px 209px #fff,
    921px 530px #fff, 2378px 2500px #fff, 1025px 310px #fff, 2254px 1830px #fff,
    2221px 1958px #fff, 1143px 277px #fff, 925px 243px #fff, 149px 963px #fff,
    2296px 1962px #fff, 481px 2353px #fff, 1744px 1127px #fff,
    2310px 1793px #fff, 18px 390px #fff, 923px 852px #fff, 981px 1607px #fff,
    711px 514px #fff, 334px 1706px #fff, 2452px 2431px #fff, 1122px 2304px #fff,
    1118px 1908px #fff, 1130px 1157px #fff, 359px 2021px #fff, 482px 793px #fff,
    1671px 2337px #fff, 683px 2182px #fff, 2170px 254px #fff, 770px 938px #fff,
    2118px 1134px #fff, 1589px 1049px #fff, 148px 1090px #fff,
    1486px 1654px #fff, 2357px 2096px #fff, 1944px 860px #fff,
    1620px 2115px #fff, 1028px 702px #fff, 1994px 144px #fff, 938px 1246px #fff,
    2381px 851px #fff, 1940px 761px #fff, 574px 13px #fff, 2173px 1435px #fff,
    860px 45px #fff, 1949px 2138px #fff, 1390px 315px #fff, 1913px 2188px #fff,
    506px 1139px #fff, 2265px 2468px #fff, 1105px 195px #fff, 397px 348px #fff,
    1488px 1341px #fff, 1846px 1772px #fff, 970px 1165px #fff, 1420px 815px #fff,
    2163px 2423px #fff, 1134px 445px #fff, 2275px 1203px #fff, 788px 592px #fff,
    1888px 2030px #fff, 1037px 2150px #fff, 1859px 2104px #fff,
    2081px 985px #fff, 738px 1415px #fff, 2372px 439px #fff, 756px 1760px #fff,
    2260px 427px #fff, 1639px 1681px #fff, 1267px 931px #fff, 1490px 1077px #fff,
    2292px 2279px #fff, 2279px 2129px #fff, 1240px 886px #fff, 461px 1423px #fff,
    294px 106px #fff, 82px 1774px #fff, 1610px 873px #fff, 483px 2390px #fff,
    605px 1593px #fff, 440px 422px #fff, 702px 798px #fff, 1146px 2255px #fff,
    347px 1085px #fff, 69px 853px #fff, 1210px 1210px #fff, 135px 195px #fff,
    1631px 745px #fff, 781px 788px #fff, 1060px 1349px #fff, 1424px 1875px #fff,
    1427px 412px #fff, 929px 1703px #fff, 621px 213px #fff, 1923px 942px #fff,
    532px 630px #fff, 1721px 769px #fff, 467px 861px #fff, 1803px 2000px #fff,
    1812px 718px #fff, 2128px 1219px #fff, 648px 2283px #fff, 269px 1821px #fff,
    756px 1591px #fff, 1729px 1230px #fff, 9px 1473px #fff, 2279px 1333px #fff,
    1669px 1182px #fff, 1495px 149px #fff, 1802px 2436px #fff, 729px 456px #fff,
    758px 640px #fff, 988px 1427px #fff, 507px 908px #fff, 2126px 1037px #fff,
    1465px 752px #fff, 850px 904px #fff, 434px 1150px #fff, 715px 204px #fff,
    2115px 501px #fff, 2458px 1720px #fff, 1149px 1300px #fff, 2238px 523px #fff,
    337px 873px #fff, 2025px 2112px #fff, 1124px 2329px #fff, 1081px 235px #fff,
    2322px 1063px #fff, 2499px 256px #fff, 1031px 83px #fff, 1599px 2113px #fff,
    921px 2008px #fff, 908px 894px #fff, 2110px 1914px #fff, 768px 141px #fff,
    313px 1241px #fff, 1567px 406px #fff, 163px 1290px #fff, 855px 313px #fff,
    2500px 1597px #fff, 1130px 906px #fff, 1008px 2107px #fff,
    1949px 1190px #fff, 756px 612px #fff, 1970px 1481px #fff, 363px 554px #fff,
    320px 1387px #fff, 873px 384px #fff, 538px 1731px #fff, 1671px 2240px #fff,
    1297px 980px #fff, 1197px 736px #fff, 391px 2172px #fff, 2005px 2054px #fff,
    1653px 2543px #fff, 199px 1136px #fff, 1227px 564px #fff, 977px 1199px #fff,
    495px 2558px #fff, 623px 1791px #fff, 2122px 783px #fff, 1397px 2045px #fff,
    1728px 168px #fff, 2416px 490px #fff, 2347px 1153px #fff, 1653px 467px #fff,
    922px 294px #fff, 342px 1368px #fff, 323px 1088px #fff, 395px 1235px #fff,
    133px 1995px #fff, 1238px 2102px #fff, 378px 644px #fff, 392px 540px #fff,
    942px 957px #fff, 1672px 2164px #fff, 1806px 74px #fff, 1971px 130px #fff,
    821px 1453px #fff, 969px 1695px #fff, 517px 2484px #fff, 684px 1647px #fff,
    56px 1895px #fff, 2545px 276px #fff, 1595px 210px #fff, 671px 1837px #fff,
    115px 279px #fff, 2041px 1352px #fff, 1694px 2457px #fff, 1219px 1408px #fff,
    2505px 488px #fff, 2502px 1814px #fff, 595px 410px #fff, 1896px 1122px #fff,
    603px 762px #fff, 994px 1605px #fff, 1025px 655px #fff, 654px 2361px #fff,
    1799px 1757px #fff, 1230px 1238px #fff, 2147px 2300px #fff,
    1768px 456px #fff, 5px 624px #fff, 556px 262px #fff, 595px 2441px #fff,
    527px 1188px #fff, 2310px 1461px #fff, 1939px 2488px #fff,
    1079px 2125px #fff, 417px 1399px #fff, 845px 415px #fff, 2370px 846px #fff,
    2056px 859px #fff, 1350px 2165px #fff, 979px 2020px #fff, 1438px 2136px #fff,
    1873px 559px #fff, 1705px 1127px #fff, 1077px 2217px #fff, 490px 2203px #fff,
    523px 2179px #fff, 2196px 83px #fff, 273px 592px #fff, 1997px 1559px #fff,
    1493px 1421px #fff, 156px 1290px #fff, 1505px 531px #fff, 759px 2167px #fff,
    1605px 1190px #fff, 1472px 858px #fff, 823px 2528px #fff, 2433px 385px #fff,
    2440px 1410px #fff, 1811px 25px #fff, 113px 2090px #fff, 238px 2248px #fff,
    105px 1338px #fff, 2472px 463px #fff, 918px 1978px #fff, 1966px 440px #fff,
    686px 1917px #fff, 1416px 2412px #fff, 1218px 2002px #fff,
    1942px 1816px #fff, 2554px 1184px #fff, 2209px 816px #fff, 291px 705px #fff,
    820px 1613px #fff, 521px 228px #fff, 824px 1929px #fff, 2558px 738px #fff,
    2455px 927px #fff, 584px 735px #fff, 1170px 1890px #fff, 1158px 1233px #fff,
    906px 865px #fff, 1073px 2496px #fff, 696px 1473px #fff, 1095px 396px #fff,
    728px 2px #fff, 1782px 1602px #fff, 1265px 429px #fff, 1552px 1147px #fff,
    2245px 1151px #fff, 720px 319px #fff, 2441px 374px #fff, 2007px 2348px #fff,
    1326px 1250px #fff, 2097px 590px #fff, 2092px 429px #fff, 102px 1733px #fff,
    1213px 1167px #fff, 1307px 205px #fff, 495px 238px #fff, 45px 909px #fff,
    663px 260px #fff, 1458px 2030px #fff, 1435px 1042px #fff, 1223px 1373px #fff,
    476px 1611px #fff, 1362px 1624px #fff, 2297px 1802px #fff, 1621px 720px #fff,
    1356px 991px #fff, 2441px 1976px #fff, 899px 1069px #fff, 2152px 1830px #fff,
    105px 983px #fff, 1022px 1415px #fff, 1483px 1752px #fff, 2090px 2132px #fff,
    1627px 1238px #fff, 1514px 1701px #fff, 2219px 1982px #fff,
    2208px 274px #fff, 212px 1279px #fff, 5px 1346px #fff, 728px 505px #fff,
    99px 2179px #fff, 1075px 1725px #fff, 498px 1420px #fff, 791px 1957px #fff,
    2537px 2081px #fff, 653px 977px #fff, 817px 2436px #fff, 1982px 1514px #fff,
    1630px 2213px #fff, 2237px 1887px #fff, 2556px 2152px #fff,
    354px 1449px #fff, 1426px 1432px #fff, 1100px 588px #fff, 1261px 1580px #fff,
    1430px 289px #fff, 2422px 671px #fff, 71px 511px #fff, 1809px 127px #fff,
    1946px 545px #fff, 1356px 2287px #fff, 1762px 2337px #fff,
    2429px 2342px #fff, 2326px 1475px #fff, 516px 2494px #fff, 798px 709px #fff,
    1961px 658px #fff, 2270px 1899px #fff, 1301px 1663px #fff, 2275px 935px #fff,
    1309px 1003px #fff, 2533px 1508px #fff, 1489px 78px #fff, 277px 373px #fff,
    2025px 1668px #fff, 1035px 178px #fff, 952px 2053px #fff, 1604px 1371px #fff,
    1095px 1148px #fff, 1506px 848px #fff, 96px 891px #fff, 2008px 2395px #fff,
    2114px 1948px #fff, 349px 1205px #fff, 1107px 2361px #fff,
    2276px 1813px #fff, 1613px 1886px #fff, 2062px 2399px #fff,
    1167px 1502px #fff, 2197px 2172px #fff, 19px 2339px #fff, 1642px 557px #fff,
    1727px 331px #fff, 1073px 2074px #fff, 952px 912px #fff, 2203px 195px #fff,
    1768px 502px #fff, 653px 267px #fff, 1787px 2531px #fff, 461px 334px #fff,
    277px 1922px #fff, 1305px 2500px #fff, 572px 1357px #fff, 1215px 1396px #fff,
    828px 1357px #fff, 1346px 1814px #fff, 1471px 635px #fff, 1804px 1675px #fff,
    2198px 1274px #fff, 2356px 1290px #fff, 277px 120px #fff, 843px 1871px #fff,
    87px 556px #fff, 225px 2545px #fff, 252px 604px #fff, 2012px 113px #fff,
    1140px 283px #fff, 220px 349px #fff, 345px 499px #fff, 1800px 946px #fff,
    1647px 1050px #fff, 375px 1466px #fff, 9px 699px #fff, 484px 2113px #fff,
    2382px 933px #fff, 22px 1763px #fff, 1580px 1117px #fff, 1634px 571px #fff,
    2121px 726px #fff, 2264px 39px #fff, 1530px 1757px #fff, 1486px 296px #fff,
    1947px 293px #fff, 92px 2174px #fff, 478px 1656px #fff, 2427px 2096px #fff,
    446px 1549px #fff, 1483px 501px #fff, 551px 2280px #fff, 1675px 306px #fff,
    1420px 1630px #fff, 1739px 1000px #fff, 488px 830px #fff, 1980px 1407px #fff,
    1886px 2450px #fff, 1341px 675px #fff, 355px 2523px #fff, 1817px 2165px #fff,
    37px 678px #fff, 2214px 1033px #fff, 1249px 1377px #fff, 300px 53px #fff,
    49px 2181px #fff, 240px 907px #fff, 2135px 1246px #fff, 2400px 1510px #fff,
    2259px 2208px #fff, 2013px 533px #fff, 1074px 1389px #fff, 1842px 16px #fff,
    909px 2316px #fff, 1940px 241px #fff, 588px 352px #fff, 614px 854px #fff,
    1062px 651px #fff, 5px 2253px #fff, 2209px 1020px #fff, 1934px 200px #fff,
    879px 1973px #fff, 1125px 167px #fff, 2119px 1975px #fff, 1210px 2187px #fff,
    1512px 1947px #fff, 1093px 2356px #fff, 1308px 1356px #fff,
    884px 1234px #fff, 1489px 5px #fff, 614px 1927px #fff, 643px 2038px #fff,
    1797px 2253px #fff, 318px 372px #fff, 368px 392px #fff, 2213px 25px #fff,
    2302px 1856px #fff, 1437px 1216px #fff, 1442px 264px #fff, 481px 1343px #fff,
    1056px 360px #fff, 888px 1932px #fff, 476px 195px #fff, 2194px 2160px #fff,
    2135px 1843px #fff, 651px 1777px #fff, 1167px 1774px #fff,
    1771px 1650px #fff, 1740px 610px #fff, 1186px 1753px #fff, 2117px 552px #fff,
    430px 1253px #fff, 1797px 1899px #fff, 399px 1004px #fff, 225px 2501px #fff,
    1314px 2493px #fff, 1400px 183px #fff, 1046px 756px #fff, 373px 881px #fff,
    2451px 1875px #fff, 578px 311px #fff, 114px 14px #fff, 1857px 980px #fff,
    2170px 1968px #fff, 1912px 743px #fff, 1885px 52px #fff, 1432px 45px #fff,
    1914px 1681px #fff, 2072px 315px #fff, 447px 2333px #fff, 317px 2066px #fff,
    84px 878px #fff, 868px 1387px #fff, 1557px 1287px #fff, 1781px 1728px #fff,
    2375px 1317px #fff, 335px 2064px #fff, 202px 2018px #fff, 759px 2137px #fff,
    1971px 76px #fff, 1672px 472px #fff, 699px 1748px #fff, 1993px 959px #fff,
    2346px 1933px #fff, 436px 1648px #fff, 2445px 1219px #fff,
    2125px 2025px #fff, 2311px 2006px #fff, 386px 1556px #fff,
    1231px 2228px #fff, 1718px 2301px #fff, 2354px 1288px #fff, 93px 1590px #fff,
    1446px 1556px #fff, 1805px 1016px #fff, 576px 2393px #fff, 340px 1327px #fff,
    529px 1247px #fff, 1764px 362px #fff, 2277px 753px #fff, 1047px 756px #fff,
    822px 2093px #fff, 1263px 2242px #fff, 491px 1953px #fff, 2282px 1003px #fff,
    938px 1880px #fff, 2430px 960px #fff, 774px 1502px #fff, 2202px 576px #fff,
    841px 726px #fff, 2011px 470px #fff, 1372px 26px #fff, 437px 1934px #fff,
    1384px 1523px #fff, 866px 390px #fff, 2127px 199px #fff, 1457px 1030px #fff,
    1856px 1395px #fff, 2094px 1711px #fff, 1778px 1375px #fff,
    2202px 938px #fff, 372px 295px #fff, 2371px 692px #fff, 1054px 1076px #fff,
    1702px 586px #fff, 1964px 1160px #fff, 546px 140px #fff, 148px 648px #fff,
    12px 1168px #fff, 1612px 2525px #fff, 2185px 1436px #fff, 1437px 2439px #fff,
    2060px 1331px #fff, 2440px 1578px #fff, 1361px 401px #fff,
    2379px 1851px #fff, 1902px 503px #fff, 1833px 1169px #fff,
    2143px 1797px #fff, 1629px 1114px #fff, 1528px 2334px #fff, 167px 358px #fff,
    29px 2077px #fff, 1135px 2273px #fff, 851px 27px #fff, 1045px 1489px #fff,
    160px 2541px #fff, 840px 208px #fff, 2462px 2059px #fff, 2404px 1614px #fff,
    696px 691px #fff, 741px 698px #fff, 1744px 1050px #fff, 2036px 1873px #fff,
    1824px 129px #fff, 2111px 959px #fff, 2394px 2295px #fff, 85px 96px #fff,
    522px 1648px #fff, 1279px 383px #fff, 260px 337px #fff, 1058px 1297px #fff,
    1588px 2456px #fff, 867px 556px #fff, 2441px 1082px #fff, 2028px 427px #fff,
    1744px 2207px #fff, 2290px 1710px #fff, 455px 1423px #fff,
    1664px 1215px #fff, 1674px 2474px #fff, 1698px 1652px #fff,
    108px 2374px #fff, 2007px 2393px #fff, 984px 1809px #fff, 809px 326px #fff,
    2258px 452px #fff, 1252px 2068px #fff, 1082px 1200px #fff, 315px 1462px #fff,
    1062px 452px #fff, 283px 1284px #fff, 1752px 689px #fff, 2327px 2495px #fff,
    592px 1229px #fff, 1745px 1066px #fff, 1837px 2276px #fff, 781px 2333px #fff,
    1435px 115px #fff, 397px 1387px #fff, 2102px 2039px #fff, 826px 2235px #fff,
    192px 1086px #fff, 149px 1273px #fff, 166px 364px #fff, 2516px 1644px #fff,
    248px 353px #fff, 1685px 464px #fff, 1867px 79px #fff, 2036px 2334px #fff,
    409px 1141px #fff, 310px 619px #fff, 1887px 928px #fff, 2146px 1283px #fff,
    1086px 2034px #fff, 2226px 1468px #fff, 2184px 1548px #fff,
    2460px 994px #fff, 1621px 1544px #fff, 927px 66px #fff, 767px 682px #fff,
    2523px 1443px #fff, 1423px 542px #fff, 2056px 808px #fff, 1546px 1198px #fff,
    81px 211px #fff, 251px 2203px #fff, 2122px 1591px #fff, 263px 893px #fff,
    757px 147px #fff, 635px 387px #fff, 1113px 2392px #fff, 2559px 2298px #fff,
    2302px 1444px #fff, 266px 1851px #fff, 807px 1769px #fff, 890px 237px #fff,
    94px 204px #fff, 2225px 2456px #fff, 233px 1012px #fff, 954px 2214px #fff,
    2016px 1872px #fff, 2474px 576px #fff, 343px 1945px #fff, 765px 74px #fff,
    1141px 769px #fff, 65px 577px #fff, 2183px 1410px #fff, 636px 1196px #fff,
    245px 285px #fff, 2123px 2426px #fff, 2202px 88px #fff, 1256px 201px #fff,
    275px 1751px #fff, 2219px 911px #fff, 1773px 667px #fff, 2180px 43px #fff,
    1122px 1899px #fff, 450px 1467px #fff, 312px 2097px #fff, 2404px 1173px #fff,
    1630px 1839px #fff, 1719px 780px #fff, 16px 1956px #fff, 1770px 1008px #fff,
    2421px 78px #fff, 880px 329px #fff, 1999px 1369px #fff, 1924px 1420px #fff,
    630px 58px #fff, 2229px 1510px #fff, 948px 880px #fff, 1119px 370px #fff,
    2020px 1314px #fff, 705px 2039px #fff, 256px 657px #fff, 1678px 1437px #fff,
    717px 150px #fff, 372px 335px #fff, 2251px 1262px #fff, 2485px 99px #fff,
    1124px 2157px #fff, 1726px 2126px #fff, 1819px 720px #fff, 545px 141px #fff,
    458px 646px #fff, 2302px 1661px #fff, 986px 530px #fff, 249px 673px #fff,
    1727px 1820px #fff, 1105px 689px #fff, 2489px 195px #fff, 838px 2349px #fff,
    1783px 1152px #fff, 505px 935px #fff, 674px 539px #fff, 1447px 676px #fff,
    1064px 816px #fff, 660px 1996px #fff, 1956px 490px #fff, 1864px 1992px #fff,
    1073px 2351px #fff, 2071px 2147px #fff, 2378px 2341px #fff,
    1369px 801px #fff, 1016px 392px #fff, 424px 2216px #fff, 1076px 2146px #fff,
    159px 281px #fff, 1096px 22px #fff, 217px 1923px #fff, 2231px 1036px #fff,
    860px 969px #fff, 2518px 87px #fff, 1963px 415px #fff, 1658px 448px #fff,
    1677px 27px #fff, 1962px 403px #fff, 1633px 1603px #fff, 1508px 527px #fff,
    1621px 1917px #fff, 499px 909px #fff, 96px 1561px #fff, 1320px 1522px #fff,
    176px 2495px #fff, 100px 2528px #fff, 637px 1890px #fff, 978px 396px #fff,
    2309px 821px #fff, 375px 2218px #fff, 2496px 12px #fff, 2249px 1610px #fff,
    390px 1672px #fff, 2005px 1726px #fff, 1562px 908px #fff, 1371px 783px #fff,
    759px 1836px #fff, 277px 2026px #fff, 544px 2504px #fff, 1983px 429px #fff,
    87px 350px #fff, 992px 2431px #fff, 1803px 1876px #fff, 1300px 1612px #fff,
    1284px 1613px #fff, 790px 382px #fff, 2404px 281px #fff, 804px 2338px #fff,
    529px 883px #fff, 2415px 2270px #fff, 569px 1769px #fff, 655px 985px #fff,
    1390px 1734px #fff, 2148px 913px #fff, 2161px 2519px #fff,
    1679px 1095px #fff, 1831px 2531px #fff, 1928px 1472px #fff, 473px 566px #fff,
    1455px 505px #fff, 481px 1535px #fff, 1796px 1904px #fff, 1673px 1986px #fff,
    1633px 333px #fff, 927px 930px #fff, 898px 1234px #fff, 2233px 1340px #fff,
    1579px 2383px #fff, 978px 1685px #fff, 27px 655px #fff, 499px 2463px #fff,
    2172px 501px #fff, 2157px 1304px #fff, 1023px 2223px #fff,
    2289px 2492px #fff, 2031px 2489px #fff, 953px 523px #fff, 2538px 767px #fff,
    933px 723px #fff, 298px 659px #fff, 768px 939px #fff, 1200px 1855px #fff,
    1345px 373px #fff, 2036px 2118px #fff, 1550px 818px #fff, 1526px 2132px #fff,
    1550px 1032px #fff, 2474px 1073px #fff, 1831px 2418px #fff,
    764px 2532px #fff, 2488px 1828px #fff, 1767px 1680px #fff, 313px 2250px #fff,
    1549px 1089px #fff, 1218px 95px #fff, 1879px 1486px #fff, 1022px 2482px #fff,
    875px 1042px #fff, 215px 663px #fff, 2002px 1255px #fff, 1046px 2234px #fff,
    823px 2011px #fff, 258px 2223px #fff, 315px 975px #fff, 534px 34px #fff,
    1530px 1699px #fff, 28px 90px #fff, 1881px 1162px #fff, 1847px 2224px #fff,
    1931px 932px #fff, 858px 46px #fff, 719px 1314px #fff, 2333px 2130px #fff,
    1348px 2226px #fff, 1870px 1744px #fff, 1102px 206px #fff,
    1438px 1309px #fff, 400px 1569px #fff, 2352px 2037px #fff,
    1770px 2308px #fff, 2044px 1459px #fff, 659px 1918px #fff, 96px 718px #fff,
    268px 997px #fff, 840px 713px #fff, 2030px 2351px #fff, 2114px 2269px #fff,
    2210px 1889px #fff, 223px 212px #fff, 2299px 1059px #fff, 613px 1193px #fff,
    1155px 1625px #fff, 801px 2511px #fff, 358px 198px #fff, 160px 652px #fff,
    1061px 1577px #fff, 1999px 1645px #fff, 2411px 1453px #fff, 916px 523px #fff,
    2111px 1826px #fff, 2101px 1278px #fff, 244px 1013px #fff, 129px 2291px #fff,
    2033px 849px #fff, 595px 1308px #fff, 1149px 536px #fff, 2223px 1851px #fff,
    525px 982px #fff, 2506px 902px #fff, 2323px 2529px #fff, 1683px 1612px #fff,
    1672px 2138px #fff, 190px 234px #fff, 447px 896px #fff, 957px 2031px #fff,
    2337px 1920px #fff, 2292px 824px #fff, 1787px 718px #fff, 2125px 198px #fff,
    2510px 2301px #fff, 2026px 942px #fff, 592px 1801px #fff, 922px 468px #fff,
    2338px 1979px #fff, 44px 1599px #fff, 843px 191px #fff, 1873px 1011px #fff,
    1169px 1952px #fff, 365px 2100px #fff, 1388px 524px #fff, 15px 2101px #fff,
    1636px 1550px #fff, 2331px 6px #fff, 142px 634px #fff, 2121px 1049px #fff,
    1159px 1044px #fff, 859px 1430px #fff, 951px 2550px #fff, 582px 2324px #fff,
    1927px 1736px #fff, 2449px 1402px #fff, 1414px 1329px #fff,
    1013px 632px #fff, 1717px 1519px #fff, 360px 997px #fff, 659px 709px #fff,
    1609px 801px #fff, 602px 2181px #fff, 801px 2049px #fff, 1233px 1203px #fff,
    1352px 27px #fff, 1382px 1178px #fff, 1255px 768px #fff, 785px 2447px #fff,
    1408px 614px #fff, 1784px 310px #fff, 1427px 1167px #fff, 168px 408px #fff,
    73px 341px #fff, 1275px 1179px #fff, 744px 1330px #fff, 2231px 2374px #fff,
    1289px 450px #fff, 1898px 7px #fff, 1220px 758px #fff, 2178px 1961px #fff,
    290px 831px #fff, 258px 329px #fff, 505px 645px #fff, 559px 1090px #fff,
    2409px 1941px #fff, 1298px 836px #fff, 1656px 887px #fff, 1111px 453px #fff,
    833px 182px #fff, 1797px 1943px #fff, 1364px 1913px #fff, 1164px 1266px #fff,
    1471px 666px #fff, 2100px 370px #fff, 2186px 2418px #fff, 769px 2518px #fff,
    1503px 1162px #fff, 1511px 122px #fff, 1049px 1262px #fff, 299px 1148px #fff,
    2154px 863px #fff, 1963px 2187px #fff, 2483px 58px #fff, 1298px 57px #fff,
    952px 892px #fff, 2200px 1097px #fff, 1175px 434px #fff, 405px 1094px #fff,
    2050px 971px #fff, 1294px 2108px #fff, 1773px 837px #fff, 1144px 446px #fff,
    468px 637px #fff, 945px 898px #fff;
  animation: animStar 100s linear infinite;
}

.stars:after {
  content: " ";
  top: -600px;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  position: absolute;
  background: transparent;
  box-shadow: 278px 2441px #fff, 50px 1828px #fff, 2021px 622px #fff,
    321px 1144px #fff, 898px 546px #fff, 1540px 1891px #fff, 767px 992px #fff,
    2425px 2101px #fff, 609px 297px #fff, 2145px 1218px #fff, 1700px 2366px #fff,
    9px 1085px #fff, 1554px 625px #fff, 1536px 2156px #fff, 2009px 86px #fff,
    162px 2484px #fff, 1454px 1442px #fff, 562px 1381px #fff, 1363px 618px #fff,
    2382px 2223px #fff, 19px 844px #fff, 696px 2222px #fff, 1698px 855px #fff,
    198px 1723px #fff, 1776px 304px #fff, 1768px 2344px #fff, 2099px 929px #fff,
    1631px 231px #fff, 1097px 38px #fff, 1209px 954px #fff, 2261px 732px #fff,
    405px 902px #fff, 1054px 1266px #fff, 2370px 619px #fff, 1456px 1917px #fff,
    2475px 1849px #fff, 360px 2146px #fff, 154px 1347px #fff, 1531px 1658px #fff,
    744px 2210px #fff, 1137px 288px #fff, 1091px 2170px #fff, 1518px 1028px #fff,
    2137px 362px #fff, 1872px 2479px #fff, 1070px 578px #fff, 213px 345px #fff,
    2267px 2130px #fff, 359px 1114px #fff, 382px 1899px #fff, 1268px 361px #fff,
    2154px 1076px #fff, 1846px 1428px #fff, 1663px 2391px #fff, 1474px 75px #fff,
    2143px 2180px #fff, 1215px 2557px #fff, 1394px 1592px #fff,
    2433px 966px #fff, 1927px 1805px #fff, 56px 2047px #fff, 756px 1821px #fff,
    1415px 343px #fff, 1849px 1306px #fff, 826px 500px #fff, 1552px 730px #fff,
    1445px 1759px #fff, 1222px 1758px #fff, 2288px 660px #fff, 792px 291px #fff,
    1559px 1540px #fff, 2127px 146px #fff, 1301px 2442px #fff, 661px 1462px #fff,
    427px 1400px #fff, 1160px 410px #fff, 612px 1849px #fff, 2359px 1575px #fff,
    1012px 161px #fff, 2217px 1515px #fff, 28px 1041px #fff, 1447px 1393px #fff,
    1137px 956px #fff, 578px 683px #fff, 1023px 1317px #fff, 939px 244px #fff,
    356px 820px #fff, 1603px 65px #fff, 651px 132px #fff, 821px 1579px #fff,
    2452px 2264px #fff, 206px 697px #fff, 51px 1126px #fff, 9px 798px #fff,
    1451px 683px #fff, 2302px 1942px #fff, 2463px 816px #fff, 1672px 1114px #fff,
    2308px 72px #fff, 2509px 1567px #fff, 345px 139px #fff, 584px 2177px #fff,
    2400px 1147px #fff, 1529px 2514px #fff, 500px 2497px #fff,
    1971px 1245px #fff, 2309px 1369px #fff, 878px 858px #fff, 2194px 1598px #fff,
    1442px 2185px #fff, 1881px 548px #fff, 1173px 1031px #fff,
    1015px 1922px #fff, 1639px 1431px #fff, 1158px 2468px #fff,
    1838px 263px #fff, 384px 837px #fff, 950px 220px #fff, 763px 1684px #fff,
    474px 1425px #fff, 1545px 72px #fff, 1110px 685px #fff, 2228px 443px #fff,
    1802px 237px #fff, 876px 2450px #fff, 332px 863px #fff, 686px 889px #fff,
    897px 2514px #fff, 2191px 393px #fff, 1761px 1151px #fff, 1877px 1017px #fff,
    1979px 1351px #fff, 1849px 191px #fff, 1429px 262px #fff, 1795px 1771px #fff,
    387px 1326px #fff, 513px 1414px #fff, 1912px 2424px #fff, 1956px 728px #fff,
    1375px 595px #fff, 1014px 1905px #fff, 2242px 269px #fff, 2125px 2193px #fff,
    2372px 2295px #fff, 99px 2363px #fff, 2359px 53px #fff, 1471px 686px #fff,
    297px 420px #fff, 731px 441px #fff, 1392px 1375px #fff, 131px 1934px #fff,
    1976px 1064px #fff, 607px 759px #fff, 432px 1299px #fff, 883px 2146px #fff,
    1971px 2357px #fff, 1620px 2028px #fff, 28px 884px #fff, 848px 243px #fff,
    112px 713px #fff, 692px 578px #fff, 402px 1991px #fff, 1118px 1484px #fff,
    74px 878px #fff, 2056px 248px #fff, 910px 847px #fff, 1248px 1160px #fff,
    2071px 842px #fff, 2359px 1957px #fff, 1309px 237px #fff, 2196px 944px #fff,
    2427px 1633px #fff, 1933px 981px #fff, 1364px 1472px #fff, 100px 438px #fff,
    1664px 2264px #fff, 896px 2549px #fff, 1420px 1957px #fff, 422px 1100px #fff,
    1629px 233px #fff, 102px 2193px #fff, 553px 892px #fff, 119px 190px #fff,
    689px 2328px #fff, 1639px 1436px #fff, 285px 1743px #fff, 22px 400px #fff,
    126px 2198px #fff, 120px 763px #fff, 1919px 1992px #fff, 1px 13px #fff,
    1809px 676px #fff, 446px 1167px #fff, 787px 1087px #fff, 860px 22px #fff,
    2048px 684px #fff, 1542px 767px #fff, 1200px 1587px #fff, 1460px 553px #fff,
    2449px 810px #fff, 11px 682px #fff, 1101px 2498px #fff, 1800px 1948px #fff,
    534px 271px #fff, 1839px 1208px #fff, 1398px 776px #fff, 2086px 156px #fff,
    212px 1472px #fff, 2423px 2410px #fff, 538px 1271px #fff, 2265px 64px #fff,
    665px 1171px #fff, 813px 2062px #fff, 1203px 1512px #fff, 343px 2281px #fff,
    1198px 2318px #fff, 467px 2229px #fff, 2301px 1169px #fff, 1444px 133px #fff,
    1882px 2487px #fff, 875px 1189px #fff, 2476px 2316px #fff,
    2186px 2340px #fff, 282px 430px #fff, 223px 1472px #fff, 2074px 773px #fff,
    329px 2362px #fff, 48px 1209px #fff, 154px 463px #fff, 574px 951px #fff,
    645px 395px #fff, 757px 1830px #fff, 548px 2288px #fff, 1749px 2051px #fff,
    1243px 933px #fff, 1187px 1678px #fff, 2384px 64px #fff, 31px 1753px #fff,
    2097px 2275px #fff, 1218px 797px #fff, 1401px 320px #fff, 2153px 946px #fff,
    1556px 2543px #fff, 1359px 1112px #fff, 2130px 1979px #fff,
    122px 1775px #fff, 713px 2035px #fff, 1675px 2052px #fff, 94px 1389px #fff,
    204px 141px #fff, 2199px 1020px #fff, 2440px 1315px #fff, 1818px 2211px #fff,
    770px 317px #fff, 372px 633px #fff, 245px 255px #fff, 1560px 906px #fff,
    633px 2342px #fff, 44px 795px #fff, 1072px 1799px #fff, 836px 1119px #fff,
    2254px 1600px #fff, 21px 419px #fff, 1560px 383px #fff, 165px 4px #fff,
    1232px 864px #fff, 1958px 1780px #fff, 2005px 821px #fff, 2053px 714px #fff,
    1881px 1421px #fff, 902px 2267px #fff, 2469px 2326px #fff, 1650px 524px #fff,
    1564px 794px #fff, 1028px 927px #fff, 1573px 2337px #fff, 2126px 925px #fff,
    2467px 685px #fff, 1871px 1117px #fff, 1212px 616px #fff, 444px 886px #fff,
    1163px 2001px #fff, 1175px 2171px #fff, 1521px 2022px #fff,
    2547px 2277px #fff, 1100px 1052px #fff, 1817px 2044px #fff,
    1363px 1847px #fff, 252px 326px #fff, 128px 258px #fff, 2232px 197px #fff,
    742px 2104px #fff, 2514px 502px #fff, 2298px 1872px #fff, 1559px 2557px #fff,
    1357px 972px #fff, 1816px 137px #fff, 2014px 1058px #fff, 612px 1281px #fff,
    795px 1527px #fff, 1333px 797px #fff, 1267px 596px #fff, 1736px 1128px #fff,
    2552px 109px #fff, 1471px 2277px #fff, 858px 1211px #fff, 2382px 352px #fff,
    1992px 945px #fff, 2178px 1089px #fff, 298px 968px #fff, 2128px 2473px #fff,
    826px 1680px #fff, 2175px 243px #fff, 940px 1337px #fff, 1876px 2120px #fff,
    1017px 780px #fff, 2249px 2441px #fff, 675px 390px #fff, 2544px 690px #fff,
    2464px 2155px #fff, 2400px 2036px #fff, 863px 2434px #fff, 2428px 245px #fff,
    2095px 136px #fff, 46px 1079px #fff, 1491px 2546px #fff, 2426px 358px #fff,
    1544px 1563px #fff, 1887px 452px #fff, 2424px 372px #fff, 533px 1860px #fff,
    295px 781px #fff, 1034px 726px #fff, 345px 2194px #fff, 2061px 1756px #fff,
    254px 1695px #fff, 1663px 1046px #fff, 801px 1651px #fff, 1074px 1832px #fff,
    1100px 2459px #fff, 2115px 558px #fff, 1776px 1938px #fff,
    1608px 2058px #fff, 299px 858px #fff, 824px 681px #fff, 536px 242px #fff,
    2477px 1113px #fff, 1130px 1907px #fff, 31px 1844px #fff, 2485px 1970px #fff,
    1901px 949px #fff, 2077px 47px #fff, 1189px 928px #fff, 2533px 2299px #fff,
    601px 2003px #fff, 1105px 1747px #fff, 512px 1054px #fff, 2225px 695px #fff,
    1213px 1426px #fff, 89px 52px #fff, 990px 1921px #fff, 2543px 1535px #fff,
    460px 729px #fff, 1434px 877px #fff, 894px 51px #fff, 469px 2224px #fff,
    548px 2071px #fff, 49px 868px #fff, 1224px 343px #fff, 1902px 1038px #fff,
    474px 1282px #fff, 1940px 75px #fff, 995px 296px #fff, 566px 2176px #fff,
    1525px 946px #fff, 550px 74px #fff, 2402px 478px #fff, 383px 1773px #fff,
    1796px 1030px #fff, 1161px 491px #fff, 855px 47px #fff, 1849px 724px #fff,
    1191px 2087px #fff, 2095px 303px #fff, 1258px 1817px #fff, 420px 1426px #fff,
    1538px 484px #fff, 508px 500px #fff, 865px 2427px #fff, 933px 704px #fff,
    2473px 1158px #fff, 985px 1535px #fff, 1661px 1876px #fff, 2516px 681px #fff,
    1826px 1895px #fff, 1762px 2412px #fff, 584px 549px #fff, 2499px 1775px #fff,
    421px 279px #fff, 1554px 2331px #fff, 650px 913px #fff, 1438px 417px #fff,
    1706px 707px #fff, 1394px 2431px #fff, 1674px 2362px #fff, 1493px 610px #fff,
    1733px 1868px #fff, 2548px 1753px #fff, 4px 1561px #fff, 897px 1122px #fff,
    1008px 364px #fff, 809px 1171px #fff, 1168px 301px #fff, 2275px 1597px #fff,
    1329px 1416px #fff, 2428px 1463px #fff, 204px 69px #fff, 1553px 127px #fff,
    1825px 988px #fff, 2099px 48px #fff, 2508px 848px #fff, 813px 219px #fff,
    544px 1748px #fff, 305px 1023px #fff, 502px 6px #fff, 2213px 1096px #fff,
    1313px 2539px #fff, 2512px 915px #fff, 1000px 2351px #fff, 523px 1913px #fff,
    1829px 1135px #fff, 1406px 2230px #fff, 2107px 604px #fff, 2080px 623px #fff,
    246px 2216px #fff, 448px 896px #fff, 368px 1034px #fff, 519px 2085px #fff,
    640px 1884px #fff, 2238px 1217px #fff, 2463px 2218px #fff,
    1861px 1638px #fff, 1675px 1198px #fff, 571px 459px #fff, 2307px 332px #fff,
    376px 454px #fff, 1095px 1463px #fff, 5px 907px #fff, 1892px 233px #fff,
    2347px 690px #fff, 367px 390px #fff, 2019px 12px #fff, 745px 2447px #fff,
    2503px 1890px #fff, 1067px 1155px #fff, 1505px 1336px #fff,
    2549px 154px #fff, 2367px 955px #fff, 2178px 1788px #fff, 2455px 1144px #fff,
    2458px 2548px #fff, 174px 1269px #fff, 47px 77px #fff, 2456px 1471px #fff,
    1031px 796px #fff, 1377px 1691px #fff, 315px 256px #fff, 327px 2371px #fff,
    815px 2327px #fff, 2234px 294px #fff, 2135px 727px #fff, 2301px 1852px #fff,
    1695px 505px #fff, 1064px 523px #fff, 2038px 66px #fff, 1237px 1880px #fff,
    1025px 862px #fff, 453px 1037px #fff, 1195px 449px #fff, 1219px 831px #fff,
    1044px 546px #fff, 672px 1221px #fff, 1491px 454px #fff, 2558px 73px #fff,
    2306px 1278px #fff, 643px 922px #fff, 1951px 164px #fff, 1018px 2443px #fff,
    61px 1065px #fff, 306px 440px #fff, 467px 133px #fff, 893px 359px #fff,
    805px 2404px #fff, 2041px 2177px #fff, 1374px 192px #fff, 802px 1781px #fff,
    716px 598px #fff, 133px 2375px #fff, 47px 157px #fff, 2501px 962px #fff,
    1732px 2489px #fff, 2262px 109px #fff, 323px 2513px #fff, 242px 1972px #fff,
    583px 1487px #fff, 390px 1768px #fff, 1359px 1613px #fff, 1319px 1536px #fff,
    1112px 1771px #fff, 2517px 808px #fff, 71px 820px #fff, 2276px 1486px #fff,
    2315px 1804px #fff, 421px 1457px #fff, 1299px 2078px #fff, 617px 581px #fff,
    2374px 2470px #fff, 2471px 937px #fff, 2169px 330px #fff, 2329px 1767px #fff,
    2303px 1816px #fff, 791px 2341px #fff, 528px 1754px #fff, 2296px 1889px #fff,
    1730px 872px #fff, 1550px 422px #fff, 1316px 1362px #fff, 1873px 2223px #fff,
    1883px 901px #fff, 689px 2506px #fff, 1204px 452px #fff, 533px 1502px #fff,
    935px 2329px #fff, 2541px 1960px #fff, 1753px 1293px #fff, 415px 2353px #fff,
    1149px 1362px #fff, 829px 1210px #fff, 1312px 2378px #fff, 191px 23px #fff,
    1528px 1808px #fff, 1157px 2310px #fff, 824px 412px #fff, 351px 894px #fff,
    2173px 1498px #fff, 2326px 2360px #fff, 500px 1920px #fff, 2415px 263px #fff,
    1643px 1602px #fff, 2286px 2441px #fff, 2205px 1169px #fff,
    754px 1488px #fff, 597px 1058px #fff, 114px 554px #fff, 303px 874px #fff,
    496px 2495px #fff, 2441px 876px #fff, 304px 209px #fff, 1071px 75px #fff,
    2002px 1227px #fff, 1565px 2095px #fff, 1040px 125px #fff, 1755px 109px #fff,
    2554px 2389px #fff, 1009px 431px #fff, 2098px 1216px #fff,
    2009px 1360px #fff, 300px 1196px #fff, 1686px 667px #fff, 449px 1274px #fff,
    1938px 701px #fff, 1659px 391px #fff, 1751px 1579px #fff, 1295px 2309px #fff,
    1505px 2037px #fff, 2235px 1195px #fff, 687px 650px #fff, 1123px 1044px #fff,
    1676px 165px #fff, 292px 2405px #fff, 1205px 2533px #fff, 1271px 1557px #fff,
    846px 2176px #fff, 568px 90px #fff, 1766px 674px #fff, 1913px 1098px #fff,
    2414px 1124px #fff, 1905px 2360px #fff, 869px 241px #fff, 1708px 1276px #fff,
    832px 687px #fff, 2094px 386px #fff, 1815px 1931px #fff, 2520px 499px #fff,
    15px 171px #fff, 693px 2429px #fff, 1340px 1788px #fff, 1628px 1529px #fff,
    110px 1224px #fff, 1819px 660px #fff, 1797px 222px #fff, 432px 654px #fff,
    81px 2466px #fff, 860px 562px #fff, 2255px 901px #fff, 1735px 2112px #fff,
    14px 1897px #fff, 1572px 224px #fff, 1126px 360px #fff, 880px 541px #fff,
    1626px 2272px #fff, 2310px 1997px #fff, 1322px 1355px #fff,
    1662px 2033px #fff, 136px 1555px #fff, 548px 1879px #fff, 2438px 1295px #fff,
    2234px 513px #fff, 2011px 2041px #fff, 105px 1217px #fff, 553px 2243px #fff,
    575px 41px #fff, 244px 1230px #fff, 698px 1650px #fff, 916px 1653px #fff,
    2256px 1411px #fff, 2498px 1528px #fff, 1915px 1147px #fff,
    1602px 1973px #fff, 1479px 539px #fff, 1311px 570px #fff, 1467px 123px #fff,
    387px 2376px #fff, 2550px 1152px #fff, 1657px 578px #fff, 938px 410px #fff,
    1493px 1880px #fff, 713px 1757px #fff, 764px 1891px #fff, 802px 1882px #fff,
    1459px 1575px #fff, 430px 583px #fff, 1490px 1521px #fff, 1007px 1584px #fff,
    165px 1564px #fff, 2006px 535px #fff, 1952px 2179px #fff, 2337px 2355px #fff,
    412px 2443px #fff, 206px 1436px #fff, 2452px 172px #fff, 1987px 2412px #fff,
    2325px 1336px #fff, 2332px 2245px #fff, 1936px 1635px #fff,
    2147px 1764px #fff, 1023px 1147px #fff, 673px 940px #fff, 2291px 2538px #fff,
    2351px 2410px #fff, 929px 1331px #fff, 1965px 1556px #fff, 914px 2152px #fff,
    902px 2356px #fff, 254px 1892px #fff, 2158px 517px #fff, 264px 2334px #fff,
    1756px 1113px #fff, 2175px 1303px #fff, 1128px 497px #fff,
    1903px 2252px #fff, 2468px 662px #fff, 2074px 847px #fff, 1175px 1019px #fff,
    1625px 1628px #fff, 753px 113px #fff, 1574px 1868px #fff, 169px 2502px #fff,
    1761px 1897px #fff, 1278px 894px #fff, 2116px 407px #fff, 727px 208px #fff,
    2513px 1617px #fff, 1438px 1852px #fff, 2111px 348px #fff,
    1100px 1502px #fff, 873px 1160px #fff, 1031px 1826px #fff,
    1852px 1312px #fff, 66px 2499px #fff, 950px 929px #fff, 921px 985px #fff,
    2314px 2413px #fff, 1622px 896px #fff, 1033px 1376px #fff,
    1985px 1958px #fff, 2328px 2466px #fff, 1108px 1956px #fff, 1450px 73px #fff,
    907px 408px #fff, 335px 147px #fff, 1513px 944px #fff, 569px 1728px #fff,
    374px 1597px #fff, 1743px 587px #fff, 1427px 842px #fff, 1574px 334px #fff,
    1574px 243px #fff, 598px 1732px #fff, 1964px 183px #fff, 27px 554px #fff,
    131px 680px #fff, 2283px 1817px #fff, 375px 453px #fff, 1233px 293px #fff,
    133px 211px #fff, 1380px 2324px #fff, 2295px 2066px #fff, 720px 136px #fff,
    1876px 295px #fff, 2488px 2387px #fff, 2528px 2505px #fff, 934px 492px #fff,
    37px 1143px #fff, 222px 198px #fff, 43px 1834px #fff, 1737px 1516px #fff,
    421px 1204px #fff, 245px 1419px #fff, 1869px 1289px #fff, 754px 1524px #fff,
    808px 2444px #fff, 1954px 237px #fff, 2468px 1206px #fff, 1950px 50px #fff,
    376px 228px #fff, 1500px 1844px #fff, 1549px 164px #fff, 1492px 1447px #fff,
    2298px 1793px #fff, 1256px 1417px #fff, 869px 1893px #fff, 681px 461px #fff,
    2393px 2003px #fff, 1562px 1849px #fff, 1049px 1447px #fff,
    1715px 2132px #fff, 734px 2515px #fff, 2259px 78px #fff, 1798px 1714px #fff,
    2546px 208px #fff, 1894px 2315px #fff, 1827px 2321px #fff,
    1464px 1946px #fff, 2498px 1525px #fff, 685px 764px #fff, 234px 174px #fff,
    2412px 811px #fff, 1918px 723px #fff, 1419px 966px #fff, 1421px 463px #fff,
    1267px 280px #fff, 560px 1092px #fff, 791px 1273px #fff, 2003px 742px #fff,
    1959px 1236px #fff, 1927px 505px #fff, 2455px 2353px #fff,
    1715px 1973px #fff, 1493px 1237px #fff, 2076px 1633px #fff,
    968px 1342px #fff, 1459px 748px #fff, 190px 1399px #fff, 904px 2114px #fff,
    61px 1033px #fff, 385px 1229px #fff, 2144px 1166px #fff, 1709px 20px #fff,
    455px 1609px #fff, 481px 149px #fff, 1662px 567px #fff, 1327px 1744px #fff,
    1025px 1113px #fff, 2525px 5px #fff, 1852px 1458px #fff, 1988px 878px #fff,
    557px 2340px #fff, 122px 1501px #fff, 303px 2092px #fff, 998px 1619px #fff,
    458px 2521px #fff, 1140px 1915px #fff, 1682px 752px #fff, 582px 1419px #fff,
    307px 1950px #fff, 1530px 926px #fff, 272px 127px #fff, 721px 1987px #fff,
    2092px 1204px #fff, 2007px 2441px #fff, 1939px 23px #fff, 10px 376px #fff,
    1044px 774px #fff, 1538px 865px #fff, 2190px 970px #fff, 730px 1147px #fff,
    27px 907px #fff, 2475px 2099px #fff, 1794px 1744px #fff, 1947px 2343px #fff,
    1008px 1361px #fff, 2155px 2021px #fff, 1545px 1754px #fff,
    2048px 210px #fff, 2152px 935px #fff, 1591px 114px #fff, 1622px 1062px #fff,
    2412px 1739px #fff, 2058px 2152px #fff, 2168px 1814px #fff,
    1990px 2064px #fff, 329px 1069px #fff, 394px 44px #fff, 1114px 1534px #fff,
    1550px 2196px #fff, 975px 2389px #fff, 310px 1956px #fff, 4px 1794px #fff,
    410px 2371px #fff, 752px 1448px #fff, 1995px 1367px #fff, 1711px 1340px #fff,
    554px 882px #fff, 2519px 2453px #fff, 1125px 357px #fff, 568px 2107px #fff,
    2164px 294px #fff, 1538px 2039px #fff, 1403px 493px #fff, 50px 111px #fff,
    550px 2358px #fff, 976px 542px #fff, 2502px 1572px #fff, 2414px 1436px #fff,
    1143px 179px #fff, 228px 1459px #fff, 1586px 1680px #fff, 2317px 1344px #fff,
    591px 1852px #fff, 85px 112px #fff, 2010px 178px #fff, 657px 552px #fff,
    1747px 395px #fff, 2394px 85px #fff, 706px 1482px #fff, 540px 1307px #fff,
    2171px 1876px #fff, 2044px 1762px #fff, 2px 1761px #fff, 1697px 978px #fff,
    2157px 2251px #fff, 317px 351px #fff, 678px 134px #fff, 1853px 1251px #fff,
    838px 1364px #fff, 2245px 628px #fff, 2347px 1755px #fff, 123px 71px #fff,
    1029px 1799px #fff, 1807px 2461px #fff, 614px 1409px #fff, 2021px 365px #fff,
    1479px 16px #fff, 652px 1337px #fff, 1192px 2198px #fff, 764px 648px #fff,
    1029px 1190px #fff, 764px 833px #fff, 1296px 694px #fff, 366px 1045px #fff,
    2259px 481px #fff, 1129px 2252px #fff, 1730px 87px #fff, 460px 1874px #fff,
    1300px 472px #fff, 282px 2143px #fff, 802px 1425px #fff, 2040px 1071px #fff,
    411px 250px #fff, 1553px 2049px #fff, 194px 2405px #fff, 2150px 1281px #fff,
    1673px 2095px #fff, 2461px 950px #fff, 952px 767px #fff, 1269px 467px #fff,
    2372px 516px #fff, 1127px 758px #fff, 1050px 1516px #fff, 2159px 1451px #fff,
    946px 2102px #fff, 449px 1359px #fff, 1676px 1213px #fff, 353px 235px #fff,
    427px 415px #fff, 1505px 1097px #fff, 489px 753px #fff, 250px 633px #fff,
    2520px 189px #fff, 1323px 1548px #fff, 1197px 826px #fff, 987px 1020px #fff,
    539px 196px #fff, 456px 868px #fff, 1173px 2210px #fff, 179px 943px #fff,
    1395px 772px #fff, 1069px 1351px #fff, 1492px 1595px #fff, 1px 1270px #fff,
    297px 547px #fff, 1640px 353px #fff, 585px 2455px #fff, 902px 1978px #fff,
    2221px 549px #fff, 5px 314px #fff, 1070px 408px #fff, 2446px 803px #fff,
    77px 692px #fff, 292px 377px #fff, 1532px 408px #fff, 414px 880px #fff,
    2226px 759px #fff, 414px 1294px #fff, 1122px 1803px #fff, 1367px 776px #fff,
    685px 1520px #fff, 519px 895px #fff, 188px 1541px #fff, 951px 1161px #fff,
    210px 1356px #fff, 2345px 653px #fff, 2014px 492px #fff, 1255px 2216px #fff,
    400px 430px #fff, 1607px 990px #fff, 314px 43px #fff, 403px 243px #fff,
    2475px 2228px #fff, 2530px 636px #fff, 1594px 1171px #fff,
    2340px 1348px #fff, 1780px 363px #fff, 790px 1374px #fff, 2322px 2016px #fff,
    1567px 1247px #fff, 1443px 2021px #fff, 366px 1523px #fff,
    1235px 1165px #fff, 223px 2022px #fff, 1114px 2495px #fff, 32px 1129px #fff,
    2264px 2519px #fff, 1141px 72px #fff, 738px 769px #fff, 2157px 1021px #fff,
    128px 1434px #fff, 743px 1193px #fff, 1257px 2005px #fff, 1388px 1408px #fff,
    2080px 1397px #fff, 2272px 416px #fff, 1137px 2402px #fff,
    1333px 1525px #fff, 2319px 2560px #fff, 721px 2010px #fff, 1399px 932px #fff,
    614px 2422px #fff, 194px 489px #fff, 1191px 133px #fff, 1779px 614px #fff,
    2343px 1250px #fff, 943px 2226px #fff, 1030px 1165px #fff, 936px 2309px #fff,
    1832px 1092px #fff, 2040px 1532px #fff, 616px 1073px #fff, 1774px 844px #fff,
    2321px 1725px #fff, 75px 1783px #fff, 302px 212px #fff, 1038px 1335px #fff,
    312px 1305px #fff, 2369px 984px #fff, 1184px 238px #fff, 1022px 1902px #fff,
    564px 2295px #fff, 429px 2297px #fff, 2241px 164px #fff, 1084px 1354px #fff,
    1186px 1331px #fff, 1195px 606px #fff, 935px 2180px #fff, 1718px 2064px #fff,
    1335px 1696px #fff, 1684px 1423px #fff, 1920px 1527px #fff,
    2390px 1388px #fff, 1368px 2165px #fff, 168px 797px #fff, 2339px 1780px #fff,
    924px 848px #fff, 439px 1818px #fff, 2399px 241px #fff, 2088px 412px #fff,
    2383px 153px #fff, 266px 2084px #fff, 1284px 771px #fff, 898px 726px #fff,
    1231px 2063px #fff, 1651px 882px #fff, 1668px 365px #fff, 1766px 2427px #fff,
    2145px 813px #fff, 1232px 1130px #fff, 568px 1862px #fff, 2114px 304px #fff,
    916px 2439px #fff, 1398px 659px #fff, 1734px 671px #fff, 1613px 1676px #fff,
    427px 1252px #fff, 247px 1979px #fff, 662px 2124px #fff, 1297px 2427px #fff,
    483px 2400px #fff, 2146px 814px #fff, 644px 2143px #fff, 51px 947px #fff,
    310px 1821px #fff, 2124px 1716px #fff, 953px 446px #fff, 2368px 578px #fff,
    2026px 800px #fff, 679px 1625px #fff, 2531px 2257px #fff, 1269px 2279px #fff,
    413px 1900px #fff, 403px 1007px #fff, 1353px 2162px #fff, 489px 2368px #fff,
    843px 240px #fff, 2546px 32px #fff, 1294px 771px #fff, 990px 308px #fff,
    176px 2086px #fff, 2320px 921px #fff, 2407px 2316px #fff, 413px 1358px #fff,
    620px 522px #fff, 514px 2514px #fff, 66px 2430px #fff, 67px 2071px #fff,
    57px 594px #fff, 2248px 1582px #fff, 2010px 1346px #fff, 2138px 181px #fff,
    2464px 1541px #fff, 2068px 973px #fff, 2450px 1316px #fff, 342px 2011px #fff,
    361px 1577px #fff, 1274px 1182px #fff, 834px 1846px #fff, 750px 1916px #fff,
    2086px 1291px #fff, 2316px 1895px #fff, 834px 519px #fff, 1234px 1556px #fff,
    631px 1530px #fff, 1502px 2032px #fff, 1179px 2011px #fff,
    1523px 2425px #fff, 1168px 561px #fff, 2501px 2488px #fff, 1091px 89px #fff,
    1271px 481px #fff, 64px 551px #fff, 367px 465px #fff, 2501px 2455px #fff,
    776px 1360px #fff, 1810px 1855px #fff, 920px 2342px #fff, 1640px 581px #fff,
    2397px 249px #fff, 200px 2345px #fff, 965px 501px #fff, 345px 1306px #fff,
    2314px 1639px #fff, 795px 115px #fff, 1871px 107px #fff, 1781px 1501px #fff,
    512px 280px #fff, 2104px 729px #fff, 2500px 327px #fff, 813px 751px #fff,
    1673px 632px #fff, 730px 1433px #fff, 1876px 1559px #fff, 493px 403px #fff,
    722px 2038px #fff, 2125px 63px #fff, 338px 2063px #fff, 2526px 445px #fff,
    1214px 1096px #fff, 194px 49px #fff, 1864px 2437px #fff, 2479px 2076px #fff,
    2141px 1728px #fff, 1277px 8px #fff, 1036px 1964px #fff, 2518px 433px #fff,
    1319px 1250px #fff, 186px 1874px #fff, 109px 2386px #fff, 495px 169px #fff,
    171px 776px #fff, 807px 1617px #fff, 1813px 1464px #fff, 652px 288px #fff,
    1154px 893px #fff, 875px 1888px #fff, 2503px 1649px #fff, 1045px 674px #fff,
    894px 2201px #fff, 1918px 2091px #fff, 850px 1284px #fff, 1194px 2473px #fff,
    477px 1896px #fff, 1798px 1217px #fff, 2315px 179px #fff, 280px 1505px #fff,
    1728px 1214px #fff, 1320px 344px #fff, 1403px 694px #fff, 2413px 1247px #fff,
    2463px 382px #fff, 1870px 232px #fff, 2053px 1389px #fff, 2008px 1490px #fff,
    1325px 992px #fff, 194px 1238px #fff, 1853px 1055px #fff, 1698px 1644px #fff,
    1931px 151px #fff, 2026px 2122px #fff, 1773px 344px #fff, 1751px 114px #fff,
    1712px 2148px #fff, 646px 1580px #fff, 1068px 1685px #fff,
    1649px 1446px #fff, 2164px 700px #fff, 299px 319px #fff, 1243px 497px #fff,
    2408px 570px #fff, 473px 745px #fff, 2366px 1817px #fff, 1356px 276px #fff,
    830px 1305px #fff, 620px 2311px #fff, 2345px 2507px #fff, 528px 164px #fff,
    1819px 2529px #fff, 600px 2143px #fff, 320px 2495px #fff, 657px 365px #fff,
    1491px 1306px #fff, 616px 2426px #fff, 44px 2356px #fff, 2058px 200px #fff,
    2266px 1639px #fff, 2046px 2287px #fff, 1937px 977px #fff,
    1755px 1815px #fff, 2204px 1389px #fff, 1069px 1503px #fff,
    2320px 1697px #fff, 799px 1853px #fff, 1053px 1188px #fff,
    2103px 1245px #fff, 2351px 852px #fff, 1689px 1570px #fff, 1066px 181px #fff,
    2529px 2064px #fff, 1382px 1098px #fff, 1241px 44px #fff, 1492px 1859px #fff,
    1668px 1337px #fff, 1860px 1077px #fff, 2047px 144px #fff,
    1813px 1974px #fff, 328px 152px #fff, 501px 1088px #fff, 1285px 1149px #fff,
    1510px 1569px #fff, 259px 1273px #fff, 2019px 257px #fff, 1375px 306px #fff,
    1257px 1325px #fff, 127px 178px #fff, 1909px 1262px #fff, 1274px 1393px #fff,
    1994px 2433px #fff, 913px 2225px #fff, 1814px 2081px #fff, 2147px 919px #fff,
    873px 906px #fff, 188px 1671px #fff, 2455px 2446px #fff, 1297px 1909px #fff,
    158px 2040px #fff, 565px 1207px #fff, 2532px 1442px #fff, 2477px 1829px #fff,
    119px 847px #fff, 102px 387px #fff, 687px 2126px #fff, 321px 24px #fff,
    963px 701px #fff, 682px 2050px #fff, 2377px 940px #fff, 186px 2433px #fff,
    2435px 2497px #fff, 1879px 1001px #fff, 1721px 1683px #fff,
    1665px 966px #fff, 267px 688px #fff, 553px 1581px #fff, 2013px 2003px #fff,
    981px 1275px #fff, 1699px 612px #fff, 610px 1933px #fff, 2482px 1889px #fff,
    396px 1233px #fff, 265px 1698px #fff, 71px 1057px #fff, 2275px 1012px #fff,
    2050px 2404px #fff, 280px 1393px #fff, 2313px 1323px #fff, 1423px 831px #fff,
    1208px 2559px #fff, 688px 2311px #fff, 394px 254px #fff, 1200px 2px #fff,
    137px 2180px #fff, 1396px 565px #fff, 214px 393px #fff, 787px 1471px #fff,
    410px 2086px #fff, 2265px 1295px #fff, 1905px 761px #fff, 800px 1368px #fff,
    1054px 1454px #fff, 2418px 508px #fff, 854px 2387px #fff, 108px 330px #fff,
    1341px 2046px #fff, 337px 1281px #fff, 688px 630px #fff, 187px 596px #fff,
    1550px 2508px #fff, 1928px 376px #fff, 1734px 1900px #fff, 118px 1467px #fff,
    92px 1273px #fff, 2010px 1797px #fff, 1448px 1379px #fff, 2371px 375px #fff,
    1380px 1761px #fff, 2542px 939px #fff, 2224px 1311px #fff,
    2456px 2182px #fff, 1348px 2027px #fff, 1700px 1727px #fff,
    2388px 526px #fff, 834px 1805px #fff, 1354px 370px #fff, 946px 1486px #fff,
    2094px 88px #fff, 383px 2107px #fff, 677px 1157px #fff, 1772px 2225px #fff,
    2300px 2021px #fff, 1332px 1034px #fff, 69px 793px #fff, 165px 2178px #fff,
    309px 1259px #fff, 2365px 1684px #fff, 1953px 52px #fff, 941px 1773px #fff,
    68px 1759px #fff, 1118px 1108px #fff, 694px 283px #fff, 2217px 949px #fff,
    256px 1519px #fff, 2126px 1364px #fff, 1803px 1706px #fff,
    2467px 1832px #fff, 55px 2226px #fff, 2499px 1780px #fff, 672px 1818px #fff,
    554px 684px #fff, 1204px 517px #fff, 522px 1978px #fff, 603px 1522px #fff,
    2021px 2123px #fff, 976px 1146px #fff, 336px 2054px #fff, 2208px 563px #fff,
    1887px 2352px #fff, 2210px 1588px #fff, 2199px 671px #fff,
    1051px 2420px #fff, 1806px 1037px #fff, 136px 1435px #fff, 606px 1402px #fff,
    79px 1218px #fff, 45px 2400px #fff, 2213px 1997px #fff, 1088px 1750px #fff,
    1168px 1001px #fff, 131px 1046px #fff, 2104px 1883px #fff,
    1285px 1751px #fff, 1755px 3px #fff, 1840px 907px #fff, 1179px 1287px #fff,
    1672px 1198px #fff, 497px 270px #fff, 1907px 32px #fff, 1963px 1341px #fff,
    1820px 197px #fff, 1303px 1149px #fff, 2081px 1173px #fff,
    1444px 1317px #fff, 1617px 351px #fff, 1495px 244px #fff, 932px 1429px #fff,
    1627px 2171px #fff, 955px 1111px #fff, 1870px 2123px #fff, 752px 1332px #fff,
    2009px 228px #fff, 1269px 2462px #fff, 2475px 1136px #fff, 684px 2445px #fff,
    817px 1232px #fff, 1090px 1970px #fff, 365px 2221px #fff, 2015px 2453px #fff,
    2263px 142px #fff, 529px 2210px #fff, 1384px 2190px #fff, 2180px 2355px #fff,
    2437px 2357px #fff, 668px 1879px #fff, 974px 380px #fff, 2474px 1682px #fff,
    364px 223px #fff, 2366px 86px #fff, 358px 549px #fff, 2130px 2525px #fff,
    945px 1055px #fff, 941px 2426px #fff, 2043px 1509px #fff, 2133px 2004px #fff,
    1997px 256px #fff, 1167px 99px #fff, 109px 2131px #fff, 1127px 694px #fff,
    624px 868px #fff, 648px 1717px #fff, 1333px 190px #fff, 1756px 1202px #fff,
    798px 1566px #fff, 857px 1349px #fff, 2284px 1966px #fff, 424px 2172px #fff,
    188px 2228px #fff, 524px 286px #fff, 747px 286px #fff, 1578px 173px #fff,
    1205px 1933px #fff, 969px 253px #fff, 1013px 465px #fff, 39px 1921px #fff,
    2327px 1358px #fff, 760px 1675px #fff, 1913px 88px #fff, 299px 2523px #fff,
    212px 1027px #fff, 484px 713px #fff, 1132px 1461px #fff, 744px 924px #fff,
    1383px 2276px #fff, 2105px 95px #fff, 2462px 2535px #fff, 1114px 708px #fff,
    503px 568px #fff, 278px 1217px #fff, 1244px 1615px #fff, 802px 1919px #fff,
    1469px 258px #fff, 940px 2259px #fff, 1000px 2127px #fff, 707px 715px #fff,
    2395px 65px #fff, 2084px 2533px #fff, 80px 2123px #fff, 177px 1490px #fff,
    1866px 591px #fff, 2392px 1416px #fff, 2162px 1865px #fff, 559px 146px #fff,
    650px 1731px #fff, 1031px 2116px #fff, 1597px 1053px #fff, 33px 2054px #fff,
    1693px 2511px #fff, 1561px 1947px #fff, 1273px 627px #fff, 680px 2516px #fff,
    8px 45px #fff, 915px 2180px #fff, 1672px 1593px #fff, 597px 384px #fff,
    1664px 1242px #fff, 1424px 96px #fff, 26px 2309px #fff, 114px 919px #fff,
    1315px 1557px #fff, 828px 710px #fff, 1992px 2255px #fff, 2156px 557px #fff,
    173px 262px #fff, 1712px 122px #fff, 364px 1661px #fff, 2414px 859px #fff,
    328px 1311px #fff, 1425px 1115px #fff, 176px 1125px #fff, 994px 2336px #fff,
    1790px 2220px #fff, 2115px 2347px #fff, 1701px 1029px #fff,
    2113px 1348px #fff, 1363px 909px #fff, 2023px 2292px #fff, 653px 2217px #fff,
    389px 2397px #fff, 754px 1378px #fff, 767px 1747px #fff, 1082px 1252px #fff,
    75px 2057px #fff, 1276px 1393px #fff, 352px 1464px #fff, 2473px 1037px #fff,
    79px 483px #fff, 2392px 785px #fff, 2115px 1595px #fff, 812px 886px #fff,
    1662px 1130px #fff, 2168px 2446px #fff, 605px 416px #fff, 1651px 1735px #fff,
    2212px 1587px #fff, 527px 2514px #fff, 2279px 512px #fff, 172px 363px #fff,
    324px 1735px #fff, 1335px 868px #fff, 1320px 1743px #fff, 200px 1911px #fff,
    1607px 960px #fff, 1972px 1822px #fff, 1359px 616px #fff, 1151px 1263px #fff,
    1843px 1763px #fff, 2463px 1435px #fff, 686px 1714px #fff, 1227px 322px #fff,
    1052px 766px #fff, 1971px 1721px #fff, 1172px 2170px #fff, 339px 1763px #fff,
    887px 502px #fff, 2095px 774px #fff, 1972px 1626px #fff, 1911px 1973px #fff,
    325px 1008px #fff, 1689px 1603px #fff, 1342px 2493px #fff, 902px 1809px #fff,
    1619px 2427px #fff, 1778px 2104px #fff, 10px 2330px #fff, 1462px 1428px #fff,
    2008px 402px #fff, 585px 1907px #fff, 1573px 1934px #fff, 1012px 331px #fff,
    39px 2237px #fff, 1552px 84px #fff, 1067px 1268px #fff, 787px 101px #fff,
    2450px 1778px #fff, 1699px 1465px #fff, 572px 601px #fff, 2401px 1357px #fff,
    532px 1182px #fff, 2075px 1022px #fff, 618px 847px #fff, 1035px 1717px #fff,
    2411px 2514px #fff, 2132px 1581px #fff, 602px 699px #fff, 1005px 1269px #fff,
    1096px 2174px #fff, 2114px 1475px #fff, 147px 2471px #fff, 1366px 446px #fff,
    75px 2215px #fff, 1483px 1180px #fff, 136px 757px #fff, 1954px 1804px #fff,
    874px 1939px #fff, 625px 869px #fff, 2041px 1959px #fff, 658px 1031px #fff,
    687px 1598px #fff, 631px 1822px #fff, 1017px 2296px #fff, 753px 583px #fff,
    90px 2284px #fff, 1606px 1508px #fff, 1489px 1692px #fff, 2245px 2387px #fff,
    488px 2343px #fff, 1056px 2373px #fff, 861px 628px #fff, 1635px 143px #fff,
    126px 2492px #fff, 1810px 281px #fff, 1280px 1246px #fff, 413px 440px #fff,
    1522px 2009px #fff, 893px 2330px #fff, 532px 2003px #fff, 332px 1177px #fff,
    1160px 511px #fff, 2230px 2360px #fff, 1596px 1152px #fff,
    2161px 1760px #fff, 173px 341px #fff, 159px 1516px #fff, 2504px 2555px #fff,
    2285px 1129px #fff, 2090px 692px #fff, 2541px 2174px #fff,
    2515px 1912px #fff, 566px 1197px #fff, 423px 374px #fff, 357px 1193px #fff,
    2005px 1802px #fff, 688px 370px #fff, 886px 492px #fff, 598px 1723px #fff,
    1181px 878px #fff, 989px 2337px #fff, 1329px 2126px #fff, 2167px 2543px #fff,
    336px 231px #fff, 1178px 1016px #fff, 1071px 246px #fff, 919px 2051px #fff,
    1890px 1271px #fff, 859px 1366px #fff, 2474px 1729px #fff, 568px 1563px #fff,
    1091px 386px #fff, 1805px 1563px #fff, 1138px 478px #fff, 813px 691px #fff,
    1310px 1993px #fff, 2374px 1058px #fff, 1236px 1460px #fff, 211px 114px #fff,
    1678px 146px #fff, 749px 1579px #fff, 1243px 801px #fff, 1041px 2330px #fff,
    2436px 1144px #fff, 1632px 1951px #fff, 2133px 1331px #fff, 859px 871px #fff,
    1378px 1661px #fff, 1795px 2388px #fff, 2490px 2280px #fff,
    2497px 731px #fff, 2045px 2103px #fff, 418px 27px #fff, 891px 186px #fff,
    1633px 1328px #fff, 823px 721px #fff, 729px 1046px #fff, 4px 2440px #fff,
    2201px 1475px #fff, 432px 909px #fff, 1216px 1668px #fff, 2524px 2174px #fff,
    1581px 1292px #fff, 976px 2458px #fff, 252px 779px #fff, 621px 940px #fff,
    466px 266px #fff, 1246px 243px #fff, 1744px 1358px #fff, 1143px 2183px #fff,
    1554px 1384px #fff, 640px 668px #fff, 1649px 97px #fff, 1966px 260px #fff,
    2453px 1652px #fff, 735px 1279px #fff, 821px 1954px #fff, 329px 1400px #fff,
    1463px 1073px #fff, 1849px 410px #fff, 1768px 1px #fff, 1214px 576px #fff,
    1079px 483px #fff, 104px 1193px #fff, 388px 2123px #fff, 1167px 777px #fff,
    1197px 1771px #fff, 2494px 1588px #fff, 1228px 2009px #fff,
    2167px 525px #fff, 2389px 2083px #fff, 1216px 2157px #fff, 391px 900px #fff,
    2019px 526px #fff, 794px 30px #fff, 2552px 760px #fff, 413px 1118px #fff,
    1428px 1173px #fff, 2391px 2531px #fff, 1401px 1633px #fff,
    419px 2140px #fff, 630px 2024px #fff, 1840px 2522px #fff, 1564px 578px #fff,
    363px 1406px #fff, 180px 1507px #fff, 1405px 448px #fff, 85px 1837px #fff,
    2015px 280px #fff, 213px 2300px #fff, 1910px 1502px #fff, 1559px 1203px #fff,
    379px 2015px #fff, 2459px 2095px #fff, 678px 1411px #fff, 1356px 1164px #fff,
    1298px 2311px #fff, 173px 2013px #fff, 385px 2445px #fff, 638px 2260px #fff,
    1202px 1103px #fff, 1663px 1740px #fff, 2535px 1584px #fff,
    1931px 1222px #fff, 571px 120px #fff, 941px 1861px #fff, 294px 1441px #fff,
    631px 363px #fff, 2408px 953px #fff, 1301px 2549px #fff, 2381px 1266px #fff,
    1853px 1913px #fff, 1134px 1594px #fff, 2226px 386px #fff, 1492px 679px #fff,
    939px 1861px #fff, 393px 1749px #fff, 2259px 168px #fff;
}

.stars1 {
  z-index: 10;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 2482px 1512px #fff, 1589px 1617px #fff, 1392px 762px #fff,
    864px 724px #fff, 392px 2280px #fff, 636px 704px #fff, 738px 2522px #fff,
    823px 1328px #fff, 244px 705px #fff, 2166px 335px #fff, 1620px 258px #fff,
    1354px 1223px #fff, 1369px 2347px #fff, 317px 1674px #fff, 1025px 220px #fff,
    424px 110px #fff, 226px 59px #fff, 1388px 172px #fff, 1638px 1248px #fff,
    455px 551px #fff, 1034px 314px #fff, 493px 1803px #fff, 921px 2455px #fff,
    807px 1048px #fff, 1701px 1540px #fff, 382px 1866px #fff, 2500px 544px #fff,
    450px 2180px #fff, 528px 356px #fff, 2318px 1292px #fff, 1408px 2459px #fff,
    1404px 533px #fff, 2245px 828px #fff, 620px 1476px #fff, 2042px 165px #fff,
    1043px 285px #fff, 776px 1266px #fff, 30px 1724px #fff, 2449px 1802px #fff,
    2021px 925px #fff, 988px 89px #fff, 1041px 1873px #fff, 2558px 1132px #fff,
    2258px 298px #fff, 267px 2393px #fff, 871px 1760px #fff, 926px 360px #fff,
    2388px 1688px #fff, 1550px 1568px #fff, 1749px 1972px #fff, 2359px 23px #fff,
    168px 2402px #fff, 1508px 1347px #fff, 1069px 1013px #fff, 771px 1615px #fff,
    1365px 2426px #fff, 1297px 1562px #fff, 2326px 1681px #fff,
    964px 1835px #fff, 403px 16px #fff, 746px 2557px #fff, 23px 2118px #fff,
    437px 2274px #fff, 1354px 32px #fff, 823px 1189px #fff, 1242px 227px #fff,
    1406px 289px #fff, 907px 1775px #fff, 1289px 631px #fff, 818px 1113px #fff,
    1068px 190px #fff, 1068px 1603px #fff, 1700px 1534px #fff, 46px 381px #fff,
    805px 1145px #fff, 1081px 1945px #fff, 509px 542px #fff, 960px 458px #fff,
    2208px 332px #fff, 2550px 1300px #fff, 1978px 2154px #fff, 2384px 804px #fff,
    65px 1057px #fff, 894px 596px #fff, 790px 219px #fff, 2154px 1257px #fff,
    635px 2513px #fff, 573px 2141px #fff, 2192px 22px #fff, 681px 83px #fff,
    1020px 147px #fff, 334px 1554px #fff, 1843px 705px #fff, 601px 2371px #fff,
    2473px 586px #fff, 985px 1559px #fff, 1306px 2208px #fff, 1842px 1035px #fff,
    730px 2119px #fff, 886px 1754px #fff, 1102px 556px #fff, 1199px 1498px #fff,
    1466px 1545px #fff, 2141px 228px #fff, 1944px 1358px #fff, 786px 1654px #fff,
    2023px 1px #fff, 1281px 247px #fff, 240px 2555px #fff, 1708px 20px #fff,
    1058px 196px #fff, 1199px 20px #fff, 1728px 2332px #fff, 1155px 135px #fff,
    2274px 1403px #fff, 1886px 2090px #fff, 2161px 2046px #fff,
    341px 2052px #fff, 1159px 1811px #fff, 193px 285px #fff, 203px 2481px #fff,
    2466px 1193px #fff, 1334px 1968px #fff, 548px 1346px #fff, 22px 733px #fff,
    1500px 871px #fff, 883px 1781px #fff, 2419px 1934px #fff, 1712px 149px #fff,
    117px 1720px #fff, 493px 1345px #fff, 1598px 129px #fff, 2256px 178px #fff,
    560px 1696px #fff, 1742px 381px #fff, 702px 618px #fff, 2196px 1381px #fff,
    1548px 1328px #fff, 2010px 2555px #fff, 1452px 1340px #fff,
    870px 2290px #fff, 1842px 2016px #fff, 1592px 20px #fff, 758px 1300px #fff,
    2084px 204px #fff, 738px 440px #fff, 1990px 1258px #fff, 917px 1637px #fff,
    1772px 1525px #fff, 431px 2270px #fff, 815px 1661px #fff, 2251px 282px #fff,
    2001px 1819px #fff, 1460px 728px #fff, 589px 948px #fff, 890px 748px #fff,
    171px 616px #fff, 2193px 1920px #fff, 2225px 2135px #fff, 1991px 2293px #fff,
    1856px 2363px #fff, 1481px 999px #fff, 847px 813px #fff, 2437px 198px #fff,
    546px 1210px #fff, 710px 1405px #fff, 1319px 1223px #fff, 1599px 1256px #fff,
    2013px 2541px #fff, 699px 2344px #fff, 616px 1171px #fff, 1720px 2083px #fff,
    1205px 572px #fff, 795px 6px #fff, 2058px 1669px #fff, 1792px 1001px #fff,
    1841px 1543px #fff, 665px 1454px #fff, 2294px 393px #fff, 1901px 2195px #fff,
    2032px 2111px #fff, 399px 1081px #fff, 2322px 1241px #fff,
    2468px 2185px #fff, 2109px 1968px #fff, 806px 859px #fff, 877px 2083px #fff,
    1885px 1664px #fff, 2405px 1579px #fff, 567px 1529px #fff,
    1150px 2129px #fff, 303px 1321px #fff, 1921px 475px #fff, 905px 1522px #fff,
    421px 1547px #fff, 2044px 1717px #fff, 341px 289px #fff, 1578px 932px #fff,
    69px 1777px #fff, 1097px 444px #fff, 2355px 1677px #fff, 196px 1629px #fff,
    2360px 991px #fff, 373px 1788px #fff, 2247px 2010px #fff, 550px 1126px #fff,
    1964px 2259px #fff, 686px 657px #fff, 2479px 999px #fff, 116px 1973px #fff,
    608px 162px #fff, 849px 201px #fff, 921px 226px #fff, 443px 1786px #fff,
    2008px 1054px #fff, 993px 1784px #fff, 2366px 1197px #fff, 2465px 848px #fff,
    1825px 1605px #fff, 1214px 985px #fff, 1040px 2032px #fff, 2342px 465px #fff,
    1930px 1588px #fff, 1028px 2532px #fff, 285px 2351px #fff, 1115px 677px #fff,
    122px 1740px #fff, 2435px 2403px #fff, 1001px 1222px #fff, 675px 1689px #fff,
    258px 5px #fff, 2203px 2383px #fff, 1170px 1732px #fff, 1387px 1708px #fff,
    2290px 2340px #fff, 84px 1385px #fff, 1735px 1961px #fff, 706px 2148px #fff,
    639px 2005px #fff, 1916px 590px #fff, 810px 1431px #fff, 2067px 1657px #fff,
    2334px 1119px #fff, 2276px 2074px #fff, 1870px 2356px #fff,
    1617px 2288px #fff, 1385px 715px #fff, 1744px 1820px #fff,
    1191px 1023px #fff, 1521px 1256px #fff, 642px 297px #fff, 2267px 2246px #fff,
    2043px 1134px #fff, 2063px 1632px #fff, 108px 446px #fff, 136px 2287px #fff,
    1420px 1778px #fff, 2536px 1209px #fff, 110px 2270px #fff,
    2478px 1438px #fff, 2499px 1892px #fff, 2120px 894px #fff, 977px 1576px #fff,
    19px 1467px #fff, 1513px 1841px #fff, 627px 1469px #fff, 1801px 2386px #fff,
    1021px 2264px #fff, 1595px 775px #fff, 1683px 495px #fff, 1322px 204px #fff,
    2439px 536px #fff, 428px 2121px #fff, 703px 2122px #fff, 383px 2021px #fff,
    2394px 1217px #fff, 19px 527px #fff, 98px 2047px #fff, 2338px 223px #fff,
    909px 1113px #fff, 1711px 827px #fff, 1404px 2482px #fff, 2313px 250px #fff,
    2162px 1426px #fff, 1795px 1228px #fff, 1870px 2047px #fff,
    149px 2123px #fff, 2333px 1370px #fff, 468px 509px #fff, 1786px 418px #fff,
    2161px 1080px #fff, 2304px 204px #fff, 314px 1823px #fff, 838px 536px #fff,
    387px 202px #fff, 2504px 418px #fff, 462px 16px #fff, 124px 2439px #fff,
    1983px 2525px #fff, 2274px 329px #fff, 2051px 749px #fff, 2508px 335px #fff,
    395px 841px #fff, 1263px 648px #fff, 1527px 579px #fff, 165px 568px #fff,
    844px 126px #fff, 2311px 1786px #fff, 1040px 2559px #fff, 2005px 372px #fff,
    1628px 2306px #fff, 20px 1255px #fff, 1819px 2124px #fff, 1540px 745px #fff,
    1113px 290px #fff, 2350px 364px #fff, 2305px 245px #fff, 787px 13px #fff,
    2042px 1915px #fff, 2118px 1588px #fff, 1965px 1704px #fff,
    980px 1312px #fff, 1960px 1784px #fff, 1627px 2210px #fff, 2192px 638px #fff,
    2163px 1177px #fff, 107px 2184px #fff, 1961px 2050px #fff, 807px 1588px #fff,
    708px 445px #fff, 2286px 2253px #fff, 960px 1594px #fff, 739px 419px #fff,
    1853px 2515px #fff, 1400px 227px #fff, 541px 1928px #fff, 545px 1228px #fff,
    2089px 397px #fff, 1326px 876px #fff, 2428px 44px #fff, 95px 1980px #fff,
    1141px 838px #fff, 10px 1199px #fff, 659px 269px #fff, 1597px 2510px #fff,
    691px 2070px #fff, 1971px 1271px #fff, 1380px 2286px #fff, 1774px 979px #fff,
    2463px 838px #fff, 776px 1630px #fff, 2367px 907px #fff, 2019px 1155px #fff,
    2553px 2422px #fff, 2480px 253px #fff, 1902px 2457px #fff, 2045px 253px #fff,
    1000px 201px #fff, 1744px 242px #fff, 1402px 1577px #fff, 1655px 1832px #fff,
    1848px 781px #fff, 186px 161px #fff, 2515px 335px #fff, 965px 1106px #fff,
    316px 633px #fff, 1105px 893px #fff, 570px 323px #fff, 2154px 597px #fff,
    584px 1351px #fff, 2274px 41px #fff, 1465px 610px #fff, 665px 1613px #fff,
    75px 1629px #fff, 905px 1984px #fff, 1194px 2436px #fff, 766px 561px #fff,
    830px 1899px #fff, 168px 1120px #fff, 1037px 322px #fff, 2347px 161px #fff,
    788px 2026px #fff, 2009px 144px #fff, 1010px 244px #fff, 2487px 38px #fff,
    1631px 1825px #fff, 2224px 920px #fff, 1782px 587px #fff, 1195px 2029px #fff,
    1276px 897px #fff, 42px 1145px #fff, 711px 2224px #fff, 2064px 2469px #fff,
    1997px 894px #fff, 1777px 309px #fff, 571px 874px #fff, 2462px 1786px #fff,
    1670px 79px #fff, 2071px 1103px #fff, 2557px 2209px #fff, 1239px 594px #fff,
    460px 460px #fff, 1707px 159px #fff, 871px 1191px #fff, 2402px 822px #fff,
    1394px 1274px #fff, 1690px 1433px #fff, 484px 948px #fff, 341px 312px #fff,
    939px 295px #fff, 855px 11px #fff, 1282px 1609px #fff, 2046px 2219px #fff,
    2488px 321px #fff, 162px 500px #fff, 331px 2426px #fff, 1742px 1962px #fff,
    2229px 653px #fff, 475px 1993px #fff, 2034px 1705px #fff, 691px 172px #fff,
    819px 993px #fff, 938px 1764px #fff, 1216px 1675px #fff, 2072px 1406px #fff,
    982px 2502px #fff, 600px 2554px #fff, 598px 1193px #fff, 2073px 1753px #fff,
    1332px 2460px #fff, 1401px 2327px #fff, 1715px 2259px #fff,
    1068px 2090px #fff, 1092px 1998px #fff, 687px 1214px #fff, 238px 217px #fff,
    510px 2264px #fff, 1960px 1254px #fff, 160px 2208px #fff, 1895px 1228px #fff,
    2050px 650px #fff, 1837px 430px #fff, 1105px 995px #fff, 2524px 656px #fff,
    2118px 2022px #fff, 2330px 689px #fff, 1811px 232px #fff, 2px 2504px #fff,
    2536px 1250px #fff, 1045px 776px #fff, 428px 1315px #fff, 91px 1344px #fff,
    1706px 1469px #fff, 1819px 21px #fff, 1524px 680px #fff, 946px 14px #fff,
    838px 2511px #fff, 1893px 961px #fff, 1349px 1156px #fff, 1638px 1734px #fff,
    1424px 1821px #fff, 1132px 202px #fff, 1758px 485px #fff, 2391px 1857px #fff,
    711px 611px #fff, 1228px 1045px #fff, 670px 2344px #fff, 246px 1118px #fff,
    703px 1023px #fff, 965px 1829px #fff, 1047px 970px #fff, 317px 1923px #fff,
    1313px 1500px #fff, 1570px 2226px #fff, 754px 2271px #fff, 2529px 496px #fff,
    1114px 1464px #fff, 1279px 171px #fff, 1887px 2303px #fff, 793px 2448px #fff,
    110px 91px #fff, 1665px 975px #fff, 1500px 197px #fff, 437px 1847px #fff,
    273px 2157px #fff, 1000px 1724px #fff, 641px 1848px #fff, 249px 2115px #fff,
    1265px 2043px #fff, 371px 2062px #fff, 2045px 1794px #fff, 445px 1573px #fff,
    971px 1470px #fff, 339px 274px #fff, 578px 2234px #fff, 2277px 982px #fff,
    1996px 2447px #fff, 726px 46px #fff, 716px 1156px #fff, 427px 917px #fff,
    1445px 754px #fff, 434px 2469px #fff, 960px 2287px #fff, 729px 1043px #fff,
    2400px 49px #fff, 2148px 1446px #fff, 1300px 466px #fff, 1062px 1473px #fff,
    605px 930px #fff, 2311px 1294px #fff, 1464px 2078px #fff, 2528px 1713px #fff,
    659px 23px #fff, 474px 1292px #fff, 166px 2401px #fff, 482px 1388px #fff,
    614px 1561px #fff, 1606px 2122px #fff, 1684px 2141px #fff,
    1511px 2253px #fff, 991px 1720px #fff, 1511px 2171px #fff, 649px 1482px #fff,
    1337px 1729px #fff, 50px 982px #fff, 24px 352px #fff, 1101px 2426px #fff,
    508px 1316px #fff, 1198px 1362px #fff, 1392px 1285px #fff,
    1876px 1460px #fff, 956px 1404px #fff, 2273px 668px #fff, 495px 568px #fff,
    551px 785px #fff, 1977px 385px #fff, 2371px 1339px #fff, 1331px 1504px #fff,
    1946px 1640px #fff, 553px 1061px #fff, 483px 1980px #fff, 2406px 554px #fff,
    2208px 1912px #fff, 596px 1993px #fff, 299px 1996px #fff, 1659px 1446px #fff,
    983px 1770px #fff, 277px 1235px #fff, 2185px 1769px #fff, 296px 827px #fff,
    2091px 1916px #fff, 119px 1652px #fff, 221px 1403px #fff, 2379px 2115px #fff,
    546px 88px #fff, 437px 1082px #fff, 17px 2322px #fff, 2194px 240px #fff,
    1533px 2013px #fff, 1545px 1652px #fff, 970px 1723px #fff, 1789px 136px #fff,
    1130px 2236px #fff, 1557px 426px #fff, 2229px 274px #fff, 1715px 1153px #fff,
    2425px 1438px #fff, 984px 1054px #fff, 437px 455px #fff, 1582px 1671px #fff,
    581px 82px #fff, 660px 1581px #fff, 1044px 273px #fff, 1213px 275px #fff,
    108px 2292px #fff, 1698px 10px #fff, 1604px 2405px #fff, 2345px 1595px #fff,
    2265px 1207px #fff, 1386px 195px #fff, 109px 341px #fff, 1923px 2376px #fff,
    1939px 2287px #fff, 1723px 1118px #fff, 1389px 39px #fff, 2042px 1780px #fff,
    1922px 1052px #fff, 262px 2011px #fff, 979px 1782px #fff, 1570px 848px #fff,
    1111px 714px #fff, 2394px 889px #fff, 705px 2340px #fff, 274px 463px #fff,
    1383px 349px #fff, 1052px 1264px #fff, 2047px 320px #fff, 635px 1509px #fff,
    1130px 2024px #fff, 1317px 1604px #fff, 1180px 2504px #fff,
    457px 1489px #fff, 1729px 800px #fff, 784px 2094px #fff, 1591px 1982px #fff,
    1748px 2409px #fff, 675px 2492px #fff, 2133px 293px #fff, 943px 413px #fff,
    671px 1519px #fff, 2376px 1006px #fff, 2120px 2417px #fff, 2151px 672px #fff,
    550px 547px #fff, 1451px 256px #fff, 1251px 885px #fff, 717px 1568px #fff,
    1489px 411px #fff, 1210px 972px #fff, 575px 513px #fff, 857px 469px #fff,
    1954px 2129px #fff, 150px 316px #fff, 2416px 2117px #fff, 97px 1315px #fff,
    489px 1523px #fff, 1732px 773px #fff, 2167px 303px #fff, 425px 570px #fff,
    422px 1340px #fff, 1919px 2099px #fff, 163px 770px #fff, 1062px 1728px #fff,
    2404px 571px #fff, 239px 210px #fff, 679px 133px #fff, 2388px 110px #fff,
    1968px 351px #fff, 1258px 1199px #fff, 576px 1602px #fff, 1678px 429px #fff,
    2015px 2291px #fff, 2022px 1071px #fff, 2135px 1699px #fff,
    1448px 1342px #fff, 814px 1245px #fff, 1513px 820px #fff, 1177px 1154px #fff,
    1175px 1530px #fff, 288px 432px #fff, 315px 1659px #fff, 2035px 1881px #fff,
    267px 2065px #fff, 820px 2447px #fff, 1425px 2533px #fff, 1432px 149px #fff,
    1959px 1911px #fff, 873px 1454px #fff, 1157px 614px #fff, 901px 1053px #fff,
    1753px 602px #fff, 909px 1340px #fff, 468px 1814px #fff, 708px 1455px #fff,
    1796px 1332px #fff, 829px 404px #fff, 801px 2289px #fff, 1369px 264px #fff,
    1789px 2505px #fff, 686px 2209px #fff, 1831px 454px #fff, 700px 75px #fff,
    1532px 785px #fff, 1616px 1501px #fff, 1061px 794px #fff, 1331px 1424px #fff,
    234px 776px #fff, 364px 1998px #fff, 1095px 2306px #fff, 968px 1742px #fff,
    1232px 2394px #fff, 1200px 64px #fff, 2295px 1377px #fff, 1055px 1496px #fff,
    1219px 1834px #fff, 1445px 1556px #fff, 1005px 889px #fff, 805px 419px #fff,
    2546px 2533px #fff, 162px 1688px #fff, 31px 1818px #fff, 1391px 1499px #fff,
    212px 895px #fff, 870px 1758px #fff, 964px 1220px #fff, 1733px 273px #fff,
    2059px 346px #fff, 526px 2485px #fff, 1514px 87px #fff, 126px 1108px #fff;
  animation: animStar 125s linear infinite;
}

.stars1:after {
  content: " ";
  top: -600px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  position: absolute;
  background: transparent;
  box-shadow: 411px 2093px #fff, 2315px 1115px #fff, 1918px 1824px #fff,
    1031px 2354px #fff, 197px 2321px #fff, 1720px 1996px #fff,
    1858px 2375px #fff, 1825px 684px #fff, 194px 815px #fff, 694px 1425px #fff,
    1033px 1444px #fff, 1056px 1058px #fff, 402px 416px #fff, 532px 1785px #fff,
    76px 1816px #fff, 209px 2321px #fff, 2191px 2039px #fff, 1363px 710px #fff,
    1372px 1580px #fff, 2526px 199px #fff, 74px 1514px #fff, 406px 814px #fff,
    1521px 470px #fff, 40px 2370px #fff, 1642px 1658px #fff, 658px 1630px #fff,
    2080px 729px #fff, 1217px 442px #fff, 1797px 393px #fff, 2555px 2401px #fff,
    455px 1472px #fff, 770px 627px #fff, 120px 2174px #fff, 1369px 556px #fff,
    1323px 216px #fff, 802px 2307px #fff, 1411px 992px #fff, 1735px 1968px #fff,
    1648px 2368px #fff, 1394px 2188px #fff, 2338px 2046px #fff,
    2504px 451px #fff, 1156px 1441px #fff, 1030px 1374px #fff,
    2430px 2526px #fff, 1383px 1351px #fff, 840px 253px #fff, 2071px 89px #fff,
    1934px 1212px #fff, 1795px 2448px #fff, 417px 326px #fff, 782px 901px #fff,
    207px 2467px #fff, 1518px 1566px #fff, 246px 1019px #fff, 308px 188px #fff,
    1490px 2405px #fff, 1508px 1705px #fff, 1197px 1311px #fff,
    851px 1810px #fff, 2363px 60px #fff, 1546px 732px #fff, 146px 1917px #fff,
    129px 2050px #fff, 802px 978px #fff, 1570px 2212px #fff, 2471px 1222px #fff,
    464px 1619px #fff, 2213px 526px #fff, 138px 1544px #fff, 111px 1773px #fff,
    913px 1098px #fff, 700px 1183px #fff, 1307px 1846px #fff, 1932px 34px #fff,
    581px 1740px #fff, 84px 2190px #fff, 985px 989px #fff, 2123px 557px #fff,
    944px 2103px #fff, 1643px 13px #fff, 68px 536px #fff, 513px 674px #fff,
    1871px 2229px #fff, 9px 1190px #fff, 2535px 1695px #fff, 666px 1791px #fff,
    648px 1358px #fff, 396px 1590px #fff, 1018px 1310px #fff, 925px 2560px #fff,
    1188px 1546px #fff, 2037px 1857px #fff, 215px 1613px #fff, 485px 2270px #fff,
    1554px 897px #fff, 1107px 1485px #fff, 140px 310px #fff, 1682px 550px #fff,
    199px 2367px #fff, 680px 2005px #fff, 1220px 1811px #fff, 207px 1511px #fff,
    852px 587px #fff, 1009px 1604px #fff, 433px 1311px #fff, 214px 423px #fff,
    1680px 2003px #fff, 1065px 1237px #fff, 2535px 1039px #fff,
    1901px 1138px #fff, 673px 1336px #fff, 1819px 2404px #fff,
    1896px 2553px #fff, 2082px 618px #fff, 303px 1796px #fff, 1962px 1259px #fff,
    1678px 5px #fff, 247px 2024px #fff, 1682px 1176px #fff, 17px 650px #fff,
    2467px 2278px #fff, 2052px 1051px #fff, 1996px 1830px #fff,
    917px 1781px #fff, 1318px 2284px #fff, 2333px 2199px #fff, 346px 441px #fff,
    2551px 1124px #fff, 524px 2354px #fff, 678px 783px #fff, 484px 967px #fff,
    2143px 1518px #fff, 2361px 489px #fff, 2472px 443px #fff, 66px 2492px #fff,
    2511px 1629px #fff, 1647px 1031px #fff, 453px 1143px #fff, 957px 1361px #fff,
    440px 1351px #fff, 2458px 2116px #fff, 1354px 2120px #fff, 982px 730px #fff,
    1703px 213px #fff, 2272px 1942px #fff, 746px 295px #fff, 2139px 823px #fff,
    1429px 2390px #fff, 1903px 74px #fff, 1470px 302px #fff, 377px 1301px #fff,
    1002px 927px #fff, 2486px 895px #fff, 722px 2044px #fff, 2452px 1271px #fff,
    429px 623px #fff, 952px 2176px #fff, 1987px 2485px #fff, 1848px 752px #fff,
    1811px 2340px #fff, 896px 1217px #fff, 1718px 1679px #fff,
    1633px 1536px #fff, 2473px 1730px #fff, 124px 2177px #fff,
    1101px 1380px #fff, 995px 333px #fff, 2010px 1510px #fff, 415px 2536px #fff,
    2132px 623px #fff, 266px 2382px #fff, 1322px 320px #fff, 1258px 2522px #fff,
    2157px 2485px #fff, 1740px 551px #fff, 1867px 524px #fff, 2520px 185px #fff,
    884px 983px #fff, 1055px 1583px #fff, 76px 2398px #fff, 1745px 1633px #fff,
    2487px 287px #fff, 709px 2519px #fff, 2097px 359px #fff, 2365px 1923px #fff,
    2347px 1300px #fff, 1421px 1830px #fff, 154px 791px #fff, 810px 2513px #fff,
    2028px 914px #fff, 481px 1131px #fff, 1950px 2001px #fff, 365px 2209px #fff,
    1302px 1495px #fff, 1801px 2451px #fff, 1740px 1321px #fff, 864px 909px #fff,
    903px 880px #fff, 720px 1843px #fff, 1039px 70px #fff, 2253px 1810px #fff,
    320px 1802px #fff, 288px 1027px #fff, 2124px 643px #fff, 1729px 1350px #fff,
    1536px 1832px #fff, 1040px 1163px #fff, 978px 2481px #fff,
    1673px 1988px #fff, 329px 1181px #fff, 2307px 2496px #fff,
    1467px 2125px #fff, 2456px 529px #fff, 1235px 32px #fff, 27px 1709px #fff,
    1694px 1636px #fff, 2323px 1964px #fff, 2404px 2448px #fff, 400px 627px #fff,
    387px 212px #fff, 1558px 1882px #fff, 2474px 2455px #fff, 713px 878px #fff,
    1317px 1492px #fff, 2373px 2247px #fff, 1210px 1934px #fff,
    2475px 264px #fff, 1497px 188px #fff, 162px 311px #fff, 1842px 2401px #fff,
    1249px 1397px #fff, 49px 2312px #fff, 2176px 1247px #fff, 2031px 254px #fff,
    975px 1272px #fff, 1195px 732px #fff, 1016px 508px #fff, 374px 1851px #fff,
    2293px 579px #fff, 677px 1369px #fff, 209px 176px #fff, 1613px 1804px #fff,
    1297px 1946px #fff, 744px 474px #fff, 2011px 1755px #fff, 287px 1977px #fff,
    1840px 682px #fff, 923px 1607px #fff, 1809px 2367px #fff, 1529px 568px #fff,
    2271px 2554px #fff, 1496px 1873px #fff, 2363px 56px #fff, 167px 992px #fff,
    1734px 1612px #fff, 2076px 23px #fff, 1775px 119px #fff, 2267px 1403px #fff,
    1263px 1659px #fff, 287px 2249px #fff, 1059px 2550px #fff, 1280px 3px #fff,
    1171px 1579px #fff, 1286px 1948px #fff, 1563px 732px #fff,
    2497px 2352px #fff, 837px 1024px #fff, 1299px 1379px #fff,
    1315px 2127px #fff, 903px 2206px #fff, 1698px 682px #fff, 402px 860px #fff,
    1999px 1021px #fff, 851px 2203px #fff, 1482px 520px #fff, 29px 1993px #fff,
    989px 220px #fff, 1533px 241px #fff, 431px 700px #fff, 841px 1949px #fff,
    767px 1137px #fff, 1685px 1834px #fff, 1606px 1618px #fff, 359px 1989px #fff,
    1065px 951px #fff, 238px 725px #fff, 1129px 2156px #fff, 2440px 1062px #fff,
    531px 1679px #fff, 459px 2433px #fff, 1524px 2529px #fff, 1550px 1728px #fff,
    2067px 1389px #fff, 1837px 406px #fff, 613px 2039px #fff, 2465px 2054px #fff,
    2174px 1281px #fff, 1434px 2364px #fff, 2140px 334px #fff, 298px 1817px #fff,
    557px 54px #fff, 1511px 1711px #fff, 1546px 343px #fff, 2032px 2202px #fff,
    41px 1333px #fff, 1525px 104px #fff, 2528px 1751px #fff, 286px 1559px #fff,
    409px 289px #fff, 2384px 1836px #fff, 607px 137px #fff, 817px 1180px #fff,
    1241px 773px #fff, 2201px 378px #fff, 1804px 16px #fff, 2318px 1687px #fff,
    1439px 8px #fff, 1217px 30px #fff, 903px 1442px #fff, 922px 661px #fff,
    2435px 1532px #fff, 901px 1591px #fff, 2036px 1592px #fff, 1746px 260px #fff,
    534px 851px #fff, 2047px 2099px #fff, 1307px 1593px #fff, 740px 664px #fff,
    611px 366px #fff, 298px 1021px #fff, 1580px 787px #fff, 2488px 2125px #fff,
    900px 1651px #fff, 1698px 332px #fff, 589px 1429px #fff, 2136px 1638px #fff,
    785px 2281px #fff, 560px 747px #fff, 911px 1396px #fff, 182px 1184px #fff,
    1105px 1859px #fff, 317px 364px #fff, 2084px 1560px #fff, 1978px 473px #fff,
    2020px 1318px #fff, 1412px 2430px #fff, 1937px 307px #fff, 92px 376px #fff,
    1798px 1191px #fff, 1720px 1306px #fff, 2401px 1520px #fff,
    1455px 2461px #fff, 1374px 2560px #fff, 353px 1701px #fff, 506px 1581px #fff,
    1019px 1497px #fff, 2106px 2347px #fff, 717px 682px #fff, 456px 1563px #fff,
    731px 2342px #fff, 1510px 2551px #fff, 2054px 1818px #fff,
    1398px 1194px #fff, 2035px 395px #fff, 533px 1715px #fff, 1754px 340px #fff,
    960px 182px #fff, 1966px 686px #fff, 1815px 1145px #fff, 81px 2337px #fff,
    1686px 876px #fff, 1725px 1865px #fff, 1016px 2189px #fff, 1485px 296px #fff,
    1548px 1769px #fff, 1814px 878px #fff, 1282px 1137px #fff,
    1833px 1487px #fff, 1670px 875px #fff, 1140px 242px #fff, 2302px 55px #fff,
    2551px 886px #fff, 1389px 760px #fff, 885px 831px #fff, 315px 302px #fff,
    5px 367px #fff, 1395px 2029px #fff, 1769px 1333px #fff, 736px 649px #fff,
    1663px 740px #fff, 969px 1755px #fff, 1391px 2349px #fff, 1908px 2555px #fff,
    148px 2135px #fff, 2163px 1363px #fff, 915px 2300px #fff, 944px 1620px #fff,
    88px 600px #fff, 1183px 82px #fff, 1410px 2522px #fff, 630px 681px #fff,
    449px 2321px #fff, 68px 844px #fff, 2270px 1553px #fff, 97px 1387px #fff,
    326px 2008px #fff, 238px 2354px #fff, 2419px 2149px #fff, 471px 606px #fff,
    960px 1170px #fff, 2114px 2323px #fff, 59px 818px #fff, 194px 425px #fff,
    1113px 1258px #fff, 1464px 211px #fff, 1548px 433px #fff, 1361px 892px #fff,
    1358px 1149px #fff, 1191px 923px #fff, 2348px 1627px #fff,
    1105px 1310px #fff, 780px 1467px #fff, 1809px 606px #fff, 521px 2409px #fff,
    1136px 2391px #fff, 213px 1185px #fff, 37px 1691px #fff, 773px 357px #fff,
    1063px 419px #fff, 473px 1614px #fff, 800px 178px #fff, 458px 732px #fff,
    989px 1658px #fff, 1824px 844px #fff, 2250px 512px #fff, 447px 1801px #fff,
    880px 2253px #fff, 535px 860px #fff, 1394px 2144px #fff, 121px 2372px #fff,
    2310px 46px #fff, 1084px 1544px #fff, 335px 2063px #fff, 266px 598px #fff,
    179px 1704px #fff, 64px 286px #fff, 2061px 2416px #fff, 971px 2075px #fff,
    960px 1437px #fff, 1494px 359px #fff, 903px 1628px #fff, 892px 2223px #fff,
    1535px 1515px #fff, 550px 2011px #fff, 2377px 1957px #fff,
    1873px 2037px #fff, 2325px 431px #fff, 193px 785px #fff, 342px 2305px #fff,
    1815px 2156px #fff, 629px 1876px #fff, 2325px 400px #fff, 1993px 97px #fff,
    1441px 517px #fff, 1687px 1689px #fff, 1px 905px #fff, 1193px 1125px #fff,
    199px 1364px #fff, 593px 2480px #fff, 1167px 1798px #fff, 709px 2290px #fff,
    2408px 1750px #fff, 2401px 1515px #fff, 159px 2029px #fff,
    2267px 1380px #fff, 2238px 2089px #fff, 1152px 737px #fff, 2344px 818px #fff,
    1568px 1389px #fff, 816px 1759px #fff, 704px 1221px #fff, 1883px 976px #fff,
    444px 680px #fff, 767px 1164px #fff, 389px 2014px #fff, 2330px 2280px #fff,
    722px 1084px #fff, 2554px 1792px #fff, 1155px 1994px #fff, 2086px 289px #fff,
    1421px 632px #fff, 1712px 924px #fff, 1919px 1446px #fff, 2296px 985px #fff,
    1199px 1067px #fff, 594px 2399px #fff, 589px 59px #fff, 2110px 1029px #fff,
    1948px 791px #fff, 498px 1691px #fff, 1098px 1832px #fff, 1370px 1143px #fff,
    1682px 2028px #fff, 1163px 449px #fff, 1258px 1173px #fff, 865px 1971px #fff,
    2276px 1714px #fff, 2049px 1520px #fff, 1836px 668px #fff, 292px 1183px #fff,
    74px 2242px #fff, 1548px 573px #fff, 1694px 1674px #fff, 2362px 2104px #fff,
    1029px 199px #fff, 1126px 2215px #fff, 2183px 603px #fff, 1117px 1160px #fff,
    2448px 2385px #fff, 1164px 392px #fff, 1898px 1506px #fff, 775px 310px #fff,
    1458px 316px #fff, 1972px 1660px #fff, 210px 919px #fff, 1699px 2290px #fff,
    1695px 1018px #fff, 2386px 737px #fff, 761px 667px #fff, 1400px 1695px #fff,
    2308px 505px #fff, 1266px 368px #fff, 895px 777px #fff, 40px 2253px #fff,
    1442px 2111px #fff, 1427px 1001px #fff, 540px 1691px #fff,
    1925px 1952px #fff, 2371px 2342px #fff, 1288px 1278px #fff,
    1817px 2349px #fff, 1505px 380px #fff, 1051px 798px #fff, 42px 2037px #fff,
    2334px 912px #fff, 725px 1281px #fff, 919px 905px #fff, 1858px 1859px #fff,
    1464px 2307px #fff, 548px 123px #fff, 377px 1551px #fff, 1606px 1740px #fff,
    1714px 149px #fff, 2484px 1115px #fff, 148px 959px #fff, 1453px 453px #fff,
    1052px 863px #fff, 1091px 2394px #fff, 1197px 2049px #fff, 57px 1603px #fff,
    1572px 715px #fff, 1201px 764px #fff, 1898px 557px #fff, 265px 868px #fff,
    2360px 111px #fff, 211px 301px #fff, 712px 1395px #fff, 1324px 1278px #fff,
    1669px 59px #fff, 1299px 1397px #fff, 455px 2174px #fff, 200px 553px #fff,
    2200px 1070px #fff, 2045px 726px #fff, 2075px 2119px #fff, 1764px 463px #fff,
    931px 260px #fff, 2181px 1408px #fff, 2249px 1090px #fff, 510px 414px #fff,
    2458px 1217px #fff, 255px 2132px #fff, 1282px 2460px #fff, 84px 2540px #fff,
    304px 1301px #fff, 1548px 1108px #fff, 1043px 2135px #fff, 1835px 238px #fff,
    1209px 213px #fff, 188px 1428px #fff, 66px 482px #fff, 1601px 654px #fff,
    2227px 2093px #fff, 1702px 643px #fff, 2218px 693px #fff, 1497px 2053px #fff,
    373px 472px #fff, 342px 608px #fff, 1533px 329px #fff, 2314px 2075px #fff,
    2555px 983px #fff, 1270px 1419px #fff, 141px 1786px #fff, 1771px 868px #fff,
    1048px 1592px #fff, 753px 1632px #fff, 1066px 581px #fff, 625px 2099px #fff,
    186px 869px #fff, 407px 1249px #fff, 1823px 620px #fff, 1375px 301px #fff,
    2477px 1334px #fff, 1784px 943px #fff, 180px 1704px #fff, 520px 906px #fff,
    650px 702px #fff, 193px 585px #fff, 583px 2357px #fff, 2429px 487px #fff,
    2527px 1357px #fff, 746px 1167px #fff, 873px 1184px #fff, 903px 2416px #fff,
    1482px 1424px #fff, 1928px 1625px #fff, 775px 1600px #fff, 2246px 743px #fff,
    2543px 8px #fff, 951px 2264px #fff, 2113px 1601px #fff, 956px 615px #fff,
    1270px 1876px #fff, 1809px 2106px #fff, 2532px 1233px #fff, 643px 722px #fff,
    1051px 1197px #fff, 1594px 2195px #fff, 861px 281px #fff, 482px 1278px #fff,
    135px 334px #fff, 2158px 972px #fff, 2217px 113px #fff, 446px 2544px #fff,
    2331px 1392px #fff, 1711px 2227px #fff, 843px 1863px #fff, 428px 2071px #fff,
    139px 733px #fff, 163px 2475px #fff, 1945px 1355px #fff, 1433px 51px #fff,
    2016px 643px #fff, 25px 1494px #fff, 1263px 2351px #fff, 174px 732px #fff,
    283px 1750px #fff, 1639px 2373px #fff, 1519px 1058px #fff, 945px 1006px #fff,
    1550px 2124px #fff, 256px 1131px #fff, 966px 2429px #fff, 1641px 2377px #fff,
    1432px 913px #fff, 1437px 2025px #fff, 1752px 1224px #fff, 804px 1499px #fff,
    738px 2405px #fff, 2292px 1378px #fff, 84px 2020px #fff, 160px 2006px #fff,
    2227px 2173px #fff, 62px 382px #fff, 110px 54px #fff, 999px 2470px #fff,
    950px 1993px #fff, 1901px 1362px #fff, 1957px 425px #fff, 850px 1801px #fff,
    122px 935px #fff, 1017px 2285px #fff, 935px 2060px #fff, 1817px 977px #fff,
    1725px 368px #fff, 544px 1764px #fff, 520px 1178px #fff, 2418px 56px #fff,
    718px 1445px #fff, 2236px 135px #fff, 2384px 2129px #fff, 636px 2411px #fff,
    1204px 1581px #fff, 1938px 2531px #fff, 1132px 826px #fff, 878px 843px #fff,
    259px 799px #fff, 1666px 2493px #fff, 340px 629px #fff;
}

.stars2 {
  z-index: 10;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 2308px 1785px #fff, 781px 2402px #fff, 958px 1925px #fff,
    985px 2287px #fff, 1810px 1125px #fff, 281px 171px #fff, 362px 228px #fff,
    2138px 227px #fff, 2408px 113px #fff, 2400px 1349px #fff, 2264px 2063px #fff,
    104px 1267px #fff, 435px 871px #fff, 1887px 2109px #fff, 973px 1108px #fff,
    606px 1576px #fff, 1265px 335px #fff, 2368px 68px #fff, 860px 2025px #fff,
    265px 2466px #fff, 2453px 620px #fff, 1929px 1665px #fff, 413px 2099px #fff,
    287px 1043px #fff, 2379px 1059px #fff, 152px 229px #fff, 476px 1511px #fff,
    985px 1196px #fff, 2514px 1946px #fff, 2060px 333px #fff, 1218px 1826px #fff,
    405px 452px #fff, 1970px 1947px #fff, 970px 37px #fff, 1643px 389px #fff,
    1798px 829px #fff, 110px 1272px #fff, 1798px 1226px #fff, 753px 1496px #fff,
    511px 1513px #fff, 68px 11px #fff, 2159px 77px #fff, 289px 1019px #fff,
    631px 2084px #fff, 346px 1290px #fff, 1719px 1674px #fff, 388px 2533px #fff,
    2056px 1102px #fff, 2136px 1932px #fff, 1525px 2529px #fff,
    1556px 2138px #fff, 1504px 92px #fff, 2240px 2052px #fff, 1050px 11px #fff,
    169px 1843px #fff, 1207px 642px #fff, 284px 2000px #fff, 1765px 1263px #fff,
    275px 976px #fff, 1634px 972px #fff, 1159px 698px #fff, 2502px 2195px #fff,
    1942px 2363px #fff, 466px 718px #fff, 1983px 14px #fff, 1811px 1239px #fff,
    379px 1172px #fff, 2138px 1597px #fff, 124px 1590px #fff, 2026px 2123px #fff,
    490px 1858px #fff, 1470px 316px #fff, 2451px 2379px #fff, 1715px 1338px #fff,
    2126px 206px #fff, 1610px 811px #fff, 180px 828px #fff, 2087px 412px #fff,
    2273px 776px #fff, 613px 657px #fff, 2491px 2060px #fff, 883px 84px #fff,
    145px 415px #fff, 2021px 1938px #fff, 1153px 19px #fff, 1581px 2457px #fff,
    1250px 2478px #fff, 2264px 57px #fff, 2082px 2228px #fff, 2186px 1424px #fff,
    1144px 1882px #fff, 1387px 2387px #fff, 2171px 453px #fff,
    2257px 2306px #fff, 1300px 1585px #fff, 1034px 1716px #fff, 52px 1595px #fff,
    2246px 91px #fff, 1765px 1351px #fff, 543px 194px #fff, 991px 469px #fff,
    1974px 974px #fff, 1567px 1973px #fff, 1014px 817px #fff, 1631px 1614px #fff,
    2041px 2410px #fff, 1342px 1219px #fff, 1988px 464px #fff, 402px 145px #fff,
    1847px 187px #fff, 684px 2452px #fff, 856px 1966px #fff, 526px 518px #fff,
    1779px 1211px #fff, 1396px 1621px #fff, 1767px 175px #fff,
    2532px 1579px #fff, 1473px 199px #fff, 685px 2221px #fff, 1172px 1672px #fff,
    1312px 25px #fff, 2309px 1800px #fff, 1916px 1693px #fff, 1812px 1231px #fff,
    2229px 2140px #fff, 1593px 425px #fff, 849px 1444px #fff, 465px 957px #fff,
    2530px 1319px #fff, 1183px 1480px #fff, 1039px 2238px #fff, 747px 205px #fff,
    460px 2519px #fff, 348px 2275px #fff, 912px 143px #fff, 2435px 915px #fff,
    1601px 595px #fff, 1443px 2035px #fff, 1177px 493px #fff, 791px 1679px #fff,
    925px 437px #fff, 139px 2375px #fff, 1797px 115px #fff, 1427px 2232px #fff,
    1039px 2532px #fff, 2198px 592px #fff, 1438px 1432px #fff, 880px 1787px #fff,
    2503px 1824px #fff, 206px 1072px #fff, 1843px 1908px #fff,
    1687px 2528px #fff, 1610px 2346px #fff, 2103px 635px #fff,
    2074px 2540px #fff, 265px 1341px #fff, 1417px 391px #fff, 1391px 1857px #fff,
    841px 212px #fff, 1813px 214px #fff, 1501px 371px #fff, 2247px 2303px #fff,
    125px 73px #fff, 669px 2037px #fff, 1690px 1712px #fff, 1782px 2201px #fff,
    2300px 1794px #fff, 1789px 1060px #fff, 752px 26px #fff, 2558px 1299px #fff,
    635px 1871px #fff, 2087px 903px #fff, 1892px 2174px #fff, 2138px 202px #fff,
    101px 420px #fff, 1612px 2407px #fff, 1667px 1283px #fff, 1379px 1520px #fff,
    962px 1442px #fff, 418px 288px #fff, 2399px 667px #fff, 1715px 2012px #fff,
    1239px 2203px #fff, 1358px 1115px #fff, 811px 2338px #fff, 1px 964px #fff,
    455px 217px #fff, 2454px 2193px #fff, 1122px 300px #fff, 1473px 1537px #fff,
    465px 1073px #fff, 724px 19px #fff, 1309px 1893px #fff, 1111px 2530px #fff,
    222px 1910px #fff, 845px 18px #fff, 486px 1208px #fff, 100px 1208px #fff,
    987px 130px #fff, 45px 1710px #fff;
  animation: animStar 175s linear infinite;
}

.stars2:after {
  content: " ";
  top: -600px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  position: absolute;
  background: transparent;
  box-shadow: 1173px 120px #fff, 1147px 1185px #fff, 177px 1755px #fff,
    312px 1001px #fff, 2520px 1861px #fff, 1703px 1838px #fff,
    2272px 1344px #fff, 1390px 1401px #fff, 353px 1984px #fff,
    1101px 2357px #fff, 2384px 1336px #fff, 586px 57px #fff, 758px 1277px #fff,
    1483px 653px #fff, 244px 2279px #fff, 1352px 135px #fff, 1153px 903px #fff,
    626px 937px #fff, 2298px 1600px #fff, 1552px 1713px #fff, 2060px 136px #fff,
    2338px 2115px #fff, 2499px 1195px #fff, 351px 2119px #fff, 1916px 673px #fff,
    1103px 439px #fff, 181px 1750px #fff, 186px 406px #fff, 1867px 523px #fff,
    421px 1322px #fff, 2473px 1589px #fff, 2243px 1549px #fff, 760px 590px #fff,
    2085px 131px #fff, 128px 1295px #fff, 2010px 258px #fff, 1456px 1620px #fff,
    542px 2499px #fff, 1770px 2069px #fff, 838px 2077px #fff, 2293px 1479px #fff,
    1882px 2081px #fff, 1724px 2432px #fff, 411px 2312px #fff, 1797px 60px #fff,
    758px 2419px #fff, 2091px 1070px #fff, 1792px 607px #fff, 1441px 2024px #fff,
    1240px 2074px #fff, 2534px 1006px #fff, 1581px 1564px #fff,
    2250px 659px #fff, 2556px 1794px #fff, 434px 549px #fff, 652px 2011px #fff,
    1982px 995px #fff, 2142px 2224px #fff, 962px 1868px #fff, 1261px 2141px #fff,
    2435px 563px #fff, 2418px 281px #fff, 1165px 2489px #fff, 937px 696px #fff,
    2403px 2164px #fff, 1676px 558px #fff, 288px 1247px #fff, 405px 28px #fff,
    77px 1119px #fff, 41px 2069px #fff, 1707px 2325px #fff, 2171px 484px #fff,
    2553px 1987px #fff, 2132px 2361px #fff, 847px 2141px #fff,
    2530px 1117px #fff, 385px 42px #fff, 2267px 1633px #fff, 826px 1594px #fff,
    339px 521px #fff, 361px 526px #fff, 880px 860px #fff, 927px 2284px #fff,
    1424px 289px #fff, 2087px 1349px #fff, 783px 142px #fff, 910px 1259px #fff,
    1771px 1149px #fff, 1230px 1752px #fff, 1370px 637px #fff, 2263px 180px #fff,
    749px 1635px #fff, 1313px 1299px #fff, 2262px 842px #fff, 871px 2260px #fff,
    732px 365px #fff, 193px 2365px #fff, 951px 1625px #fff, 213px 2039px #fff,
    28px 2554px #fff, 487px 2144px #fff, 1528px 1072px #fff, 2292px 1400px #fff,
    1510px 1715px #fff, 532px 991px #fff, 1356px 934px #fff, 1855px 202px #fff,
    1097px 1593px #fff, 2557px 2521px #fff, 742px 2069px #fff, 64px 441px #fff,
    71px 251px #fff, 755px 1866px #fff, 2152px 1391px #fff, 1586px 882px #fff,
    1794px 2529px #fff, 439px 2362px #fff, 1864px 1569px #fff, 1921px 904px #fff,
    416px 951px #fff, 742px 1626px #fff, 1179px 1955px #fff, 653px 900px #fff,
    233px 1356px #fff, 2499px 1716px #fff, 858px 1997px #fff, 1621px 1472px #fff,
    603px 2516px #fff, 2267px 2287px #fff, 1148px 1525px #fff,
    1273px 1229px #fff, 1310px 949px #fff, 2180px 459px #fff, 2419px 256px #fff,
    2034px 69px #fff, 1329px 749px #fff, 2031px 1676px #fff, 1370px 2519px #fff,
    359px 1603px #fff, 1896px 767px #fff, 763px 496px #fff, 1155px 217px #fff,
    1107px 2039px #fff, 1416px 1664px #fff, 2384px 1777px #fff,
    1787px 779px #fff, 2237px 1033px #fff, 685px 796px #fff, 1085px 1998px #fff,
    892px 663px #fff, 1292px 1007px #fff, 2076px 2047px #fff, 2255px 2071px #fff,
    2047px 1273px #fff, 1088px 513px #fff, 143px 769px #fff, 65px 182px #fff,
    1815px 1513px #fff, 794px 2434px #fff, 2280px 1534px #fff, 753px 1437px #fff,
    706px 703px #fff, 723px 1778px #fff, 381px 630px #fff, 2271px 1694px #fff,
    59px 2244px #fff, 1144px 2020px #fff, 60px 1909px #fff, 1683px 103px #fff,
    1916px 2489px #fff, 1293px 313px #fff, 117px 1283px #fff, 942px 585px #fff,
    311px 510px #fff, 2008px 1626px #fff, 1434px 518px #fff, 2197px 1386px #fff,
    2128px 978px #fff, 353px 367px #fff, 755px 1966px #fff, 2547px 913px #fff,
    897px 551px #fff, 796px 1148px #fff, 1302px 2532px #fff, 2328px 1061px #fff,
    1263px 271px #fff, 1837px 1727px #fff, 11px 582px #fff, 1590px 624px #fff,
    57px 898px #fff, 341px 2457px #fff, 2516px 1631px #fff, 2228px 1237px #fff,
    1692px 300px #fff, 2064px 1588px #fff, 934px 97px #fff, 1129px 423px #fff,
    66px 1507px #fff, 1186px 1412px #fff, 1905px 1944px #fff;
}

.shooting_stars {
  z-index: 10;
  width: 5px;
  height: 85px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(to top, rgba(255, 255, 255, 0), white);
  animation: animShootingStar 10s linear infinite;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-2560px) translateX(-2560px);
  }
}

@keyframes animShootingStar {
  from {
    transform: translateY(0px) translateX(0px) rotate(-45deg);
    opacity: 1;
    height: 5px;
  }

  to {
    transform: translateY(-2560px) translateX(-2560px) rotate(-45deg);
    opacity: 1;
    height: 800px;
  }
}

.runstrip {
  color: #000;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3e5c85;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0f0230;
}

.bgColor {
  background: #3d464e;
}

.boxClr {
  background: #110637;
}

.whiteHdr {
  color: #fff !important;
}

.txtCntr {
  text-align: center;
}

.iconImg {
  /* width:75px; */
  height: 100px;
  z-index: 2;
  position: relative;
}

.homesection2 {
  position: absolute;
  /* left:10%; */
  top: 65%;
}

.feature1:hover .feature2:before {
  height: 165px;
  opacity: 0.75;
}

.feature1:hover .feature2:after,
.feature1:hover .feature2:before {
  width: 190px;
}

.feature1 .feature2:after,
.feature1 .feature2:before {
  width: 0;
}

.feature1 .feature2:before {
  /* background: #35b0e6; */
  /* background: -webkit-gradient(left top,left bottom,color-stop(0,#35b0e6),color-stop(47%,#5d44a5),color-stop(100%,#da07a2)); */
  background: linear-gradient(180deg, #35b0e6 0, #5d44a5 47%, #0c0d38);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#35b0e6", endColorstr="#da07a2", GradientType=0);
  box-shadow: 0 0 35px 15px #d3f9ff;
}

.feature1 .feature2:after,
.feature1 .feature2:before {
  content: "";
  display: block;
  border-radius: 50%;
  width: 130px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 0.36s ease;
  position: absolute;
}

.feature1:hover .feature2:after {
  height: 190px;
  opacity: 0.75;
}

.feature1 .feature2:before {
  height: 0;
  opacity: 0;
}

.feature1 .feature2:after {
  z-index: 1;
  /* background: #4e287f; */
  /* background: -webkit-gradient(left top,right top,color-stop(0,#4e287f),color-stop(100%,#2d8bc9)); */
  background: linear-gradient(90deg, #4e287f 0, #2d8bc9);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$start", endColorstr="$end", GradientType=1);
  height: 110px;
  opacity: 0.75;
}

.feature1 .feature2:after {
  height: 0;
  opacity: 0;
}

.showMrBtn {
  background: #dc3545;
  height: 50px;
  border-radius: 50px;
  padding: 10px 10px;
  color: #fff !important;
  font-size: 13px;
  letter-spacing: 0.25px;
  min-width: 130px;
  font-weight: bold;
  border: none;
  text-align: center;
  margin-right: 20px;
}

.mobView {
  display: none;
}

.fullScr {
  display: block;
}

.pStyle {
  width: 257px;
}

.textStyle {
  z-index: 1;
  position: relative;
  color: #fff;
}

/* --Mobile Responssive----- */
@media (max-width: 736px) {
  .tagLine {
    font-size: 20px;
  }

  .homesection2 {
    top: 38%;
    height: 340px;
    display: flex;
    padding: 0;
    left: 0;
  }

  .homesection1 {
    left: 0;
    top: 28%;
  }

  /* .row{flex-wrap: initial;} */
  .feature1:hover .feature2:after,
  .feature1:hover .feature2:before {
    width: 165px;
  }

  .feature1:hover .feature2:before {
    height: 160px;
    opacity: 0.75;
  }

  .iconImg {
    height: 70px;
  }

  .feature1:hover .feature2:after {
    height: 135px;
    opacity: 0.75;
  }

  .feature1:hover .feature2:after,
  .feature1:hover .feature2:before {
    width: 165px;
  }

  .disBlck {
    display: block;
  }

  .sectionHead {
    margin-top: 30px;
  }

  .colTopSpcae + .colTopSpcae,
  .newsTopSpcae + .newsTopSpcae {
    margin-top: 0px !important;
  }

  .commonSectionStyle {
    padding: 4em 0 20px;
    overflow-x: auto;
  }

  .newsCardBox {
    height: 370px;
  }

  ::-webkit-scrollbar {
    height: 8px;
  }

  .featuredLogo {
    width: 100px;
  }

  .awssld__content {
    background: transparent !important;
  }

  .awssld__container figure,
  .awssld__content,
  .awssld__box {
    top: 6px !important;
  }

  .awssld__bullets {
    bottom: 0px !important;
  }

  .awssld__bullets button {
    width: 8px !important;
    height: 8px !important;
  }

  .mobView {
    display: block;
  }

  .fullScr {
    display: none;
  }

  .pStyle {
    width: 190px;
  }

  .menu ul li {
    padding: 0px 20px !important;
    margin: 24px 0px;
  }

  .loggin-box {
    margin-top: 0px !important;
    width: 195px !important;
    padding: 0 !important;
  }

  .login-box .btn.btn-secondary {
    width: 50px !important;
  }

  .proimg {
    min-width: 56px;
    min-height: 58px;
    object-fit: "cover";
    margin-top: 10px;
  }
}

.youtub {
  padding: 22px 100px;
}

.shwPas {
  width: 15px;
  height: 15px;
}

.shwLabel {
  padding-bottom: 5px;
  padding-left: 5px;
  color: #000;
  font-family: "Quicksand-Regular" !important;
}

.youBg {
  background: #3d464e;
  padding: 22px 10px;
}

.mor {
  width: 100%;
  padding: 27px 20px;
}

.algn {
  float: right;
  background: #dc3545 !important;
}

.al {
  color: #fff !important;
}

.swd {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  padding: 5px;
  height: 580px;
  border: 5px double gray;
  border-radius: 20px;
}

.swd_main {
  height: 100%;
  image-resolution: center;
}

.swd_img {
  text-align: center;
}

.swd_qrImg {
  padding-top: 15px;
  text-align: center;
}

.swd_logo {
  text-align: center;
  height: 120px;
}

.swd_qr {
  border: 2px solid #000;
}

.swd_lb {
  text-align: center;
  margin-top: 12px;
}

.qrTitle {
  font-weight: bold;
  font-size: 17px;
}

.appIcon {
  display: inline-flex;
  text-align: center;
}

.smallAddressBox {
  height: 40px;
  width: 40px;
  margin-left: 50px;
}

.text {
  text-align: center;
  margin-top: 5px;
}

.or {
  text-align: center;
}

.signOtp {
  text-align: center;
  margin-top: 15px;
  text-decoration: underline;
}

.selectTab {
  width: 250px;
  margin-bottom: 15px;
  float: right;
}

.backBtn {
  border: 0px;
  color: #999999;
  background: #fff;
  margin: 6px;
  font-size: 20px;
}

.mod_tit {
  width: 250px;
}

/* ***********new Css********* */

.bgColour {
  background: var(--bg-color);
}

.bgBox {
  background-color: #3e5c85;
}

.loginbox {
  display: flex;
  max-width: 900px;
  height: 550px;
  /* height: auto; */
  margin: 0 10%;
}

.loginbox2 {
  display: flex;
  /* max-width: 900px; */
  height: 550px;
  /* height: auto; margin: 0 10%; */
}

.hytAuto {
  height: auto !important;
}

.boxHyt {
  height: 700px;
}

.box_1 {
  width: 45%;
  background: url(../newImages/singup1.png), url(../newImages/signup2.png);
  background-position: 0px 20%, 15% 120%;
  background-size: 100%, 195% 75%;
  background-repeat: no-repeat;
  background-color: #3e5c85;
  position: relative;
  border-radius: 8px 0px 0px 8px;
}

.box_4 {
  width: 45%;
  background-color: #3e5c85;
  position: relative;
  border-radius: 8px 0px 0px 8px;
}

.box_lft {
  width: 50%;
  background-color: var(--primary-color);
  position: relative;
  /* border-radius: 8px 0px 0px 8px; */
}

.box_3 {
  width: 45%;
  background: url(../newImages/resetpswrd1.png);
  background-position: 55%;
  background-size: 68%;
  background-repeat: no-repeat;
  background-color: #3e5c85;
  position: relative;
  border-radius: 8px 0px 0px 8px;
}

.logoBox {
  width: 26%;
  height: 20%;
  background: #fff;
  border-radius: 10px 63px 60px 55px;
}

.logoBox img {
  width: 100%;
  height: 100%;
}

.pdBox {
  padding: 0 20px;
  position: absolute;
  text-align: center;
  margin-top: -50px;
}

.pdBox img {
  width: 85%;
  height: 85%;
}

.pdBox3 {
  padding: 0 20px;
  position: absolute;
  text-align: center;
  margin-top: -50px;
}

.pdBox3 img {
  width: 75%;
}

.rf_img {
  border-radius: 50%;
}

.box_2 {
  width: 55%;
  padding: 40px 35px;
  background: var(--white-color);
  border-radius: 0px 8px 8px 0px;
}

.box_ryt {
  width: 50%;
  padding: 40px 35px;
  background: var(--white-color);
  /* border-radius: 0px 8px 8px 0px; */
}

.btnGrey {
  background: #5c6c83;
  font-size: 20px;
  color: #fff;
}

.header2 {
  color: #3e5c85;
}

.fa-eye-slash,
.fa-eye {
  position: absolute;
  top: 35px;
  right: 12px;
}

.setPos {
  position: absolute;
  top: 12px !important;
  right: 12px;
}

.posRel {
  position: relative;
}

.formBox label {
  color: var(--black-color);
  font-weight: 600;
}

.canvas {
  width: 300px;
  height: 150px;
  border-radius: 8px;
}

/* --------------dashboard------ */
.banner1 {
  background: url(../newImages/home1.png), url(../newImages/bg1.png);
  background-position: 90% 45%, top left;
  background-repeat: no-repeat;
  height: 680px;
  width: 100%;
  background-size: 40% 55%, 65% 96%;
}

.bnrMar {
  position: absolute;
  top: 425px;
}

.home1-img {
  width: 400px;
  float: right;
  height: 400px;
}

.home1-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.frstBuyr {
  width: 80%;
}

/* .bnr2{background:url(../newImages/homeImg1.png);background-position:right;background-repeat:no-repeat;} */
.homesection11 {
  position: absolute;
  /* left: 3%; */
  top: 30%;
}

.homecards {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.paddingBox {
  padding: 0 6em;
}

.tagLine1 {
  text-align: left;
  margin-top: 0px;
  font-size: 32px;
  font-weight: 600;
  color: #3e5c85;
  font-family: "Tommysoft-Bold";
  /* width: 75%; */
  /* margin-left: 35px; */
}

.runstrip1 {
  color: #3a3a3a;
  /* margin-left: 35px; */
  font-size: 18pt;
}

.runstrip2 {
  margin: 120px auto 0px;
  background: #3e5c85;
  text-align: center;
  padding: 12px;
}

.runstrip2 p {
  margin-bottom: 0;
  font-size: 20px;
  font-family: "Quicksand-Medium";
  color: #fff;
}

.tophdr h2 {
  color: #3e5c85;
  font-size: 22px;
  /* margin: 0 35px; */
}

.tophdr p {
  color: #3a3a3a;
  /* margin: 0 35px 15px; */
  font-size: 15px;
}

.equalto {
  margin-top: 30px;
  font-size: 30px;
  font-weight: 600;
  color: #3e5c85;
}

.tableFilter11 {
  /* background: #fff; */
  /* margin-left: 18px; */
  /* padding: 10px 12px; */
  border-radius: 8px;
  margin-top: 50px;
}

.tablefilterInpRad {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.par {
  width: 100%;
  padding: 2em 8em 0;
  display: flex;
  justify-content: space-between;
}

.card_11,
.card_22 {
  width: 16%;
  background-color: var(--white-color);
  text-align: center;
  align-items: center;
  box-shadow: 3px 3px 17px -5px var(--box-shadow);
  border-radius: 8px;
  height: 125px;
  margin: auto;
}

.card_22:hover {
  background-color: #3e5c85;
}

.w15 {
  width: 15% !important;
}

.boxMar {
  margin: 30px auto 15px;
  width: 45px;
  height: 40px;
}

.card_11 p,
.card_22 p {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-grey);
}

.img1,
.img3,
.img5,
.img7,
.img9 {
  display: block;
}

.img2,
.img4,
.img6,
.img8,
.img10 {
  display: none;
}

.gift:hover,
.gift:focus,
.bnkTrnsfr:hover,
.bnkTrnsfr:focus,
.coins:hover,
.coins:focus,
.deposits:hover,
.deposits:focus,
.services:hover,
.services:focus {
  background-color: #3e5c85;
  color: #fff;
  cursor: pointer;
}

.gift:hover .img1,
.gift:focus .img1,
.bnkTrnsfr:hover .img3,
.bnkTrnsfr:focus .img3,
.coins:hover .img5,
.coins:focus .img5,
.deposits:hover .img7,
.deposits:focus .img7,
.services:hover .img9,
.services:focus .img9 {
  display: none;
}

.gift:hover .img2,
.gift:focus .img2,
.bnkTrnsfr:hover .img4,
.bnkTrnsfr:focus .img4,
.coins:hover .img6,
.coins:focus .img6,
.deposits:hover .img8,
.deposits:focus .img8,
.services:hover .img10,
.services:focus .img10 {
  display: block;
}

.footerHead {
  background: #3e5c85;
  bottom: 0;
  height: auto;
  padding: 45px 100px;
  color: #fff;
  font-size: 15px;
  line-height: 25px;
}

.rowPd {
  padding: 45px 100px;
}

.socialLinks {
  margin-top: 20px;
  /* margin-left: 20px; */
  height: 35%;
  width: 70%;
}

.footerApp {
  width: 100%;
  display: flex;
}

.footerApp .googleApp img {
  margin-right: 10px;
}

.cloudfare img {
  margin-top: 8px;
  margin-left: 6px;
}

.bitcoin img {
  /* margin-top: 8px; */
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.dun img {
  margin-left: 35px;
  margin-top: 10px;
}

.talkIcon img {
  margin-left: 10px;
}

.signupbox {
  display: flex;
  max-width: 900px;
  /* height: 725px; */
  height: auto;
  margin: 0 10%;
}

.form-group1 {
  margin-bottom: 5px;
}

.form-group1 label {
  margin-bottom: 0;
}

.pdbox2 {
  padding: 0 20px;
  position: absolute;
  text-align: center;
  top: 215px;
}

.pdbox2 img {
  width: 100%;
  height: 100%;
}

.fontFam {
  font-family: "Quicksand-Regular" !important;
}

.fontFam a {
  text-decoration: none !important;
  color: #535f66 !important;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  /* box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%); */
}

.tabClr {
  background-color: #3e5c85 !important;
  color: #fff !important;
}

/* -----------maindashboard------- */
.txtBox {
  border-radius: 0px 0px 8px 8px;
  width: 100%;
  padding: 22px 4px 80px 5px;
  margin-left: 0px !important;
  box-shadow: 0px 1px 8px 1px #cbc9d1;
  background-color: var(--white-color);
}

.txtBox p {
  font-family: "Quicksand-Regular";
  color: #121212;
  font-size: 16px;
  margin: 0 !important;
}

.tabList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.tabox {
  border: 1px solid var(--text-grey) !important;
  padding: 10px !important;
  cursor: pointer;
  font-family: "Quicksand-Regular";
  border-radius: 8px !important;
  width: fit-content;
  color: var(--text-grey);
  text-align: center;
  font-size: 14px;
  height: 50px;
  display: grid;
  place-items: center;
}

.taboxActive {
  background: #3e5c85;
  border-radius: 8px !important;
  width: 15%;
  font-size: 14px;
  height: 50px;
  cursor: pointer;
  color: #fff;
  padding: 10px !important;
  text-align: center;
  font-family: "Quicksand-Regular";
  border: none !important;
}

.tabox a {
  color: #3e5c85;
  text-decoration: none;
}

.taboxActive a {
  color: #fff;
  text-decoration: none;
}

.pdImp {
  padding: 4px !important;
}

/* ------trade----- */
.cardHead {
  width: 100%;
  height: 65px;
  background: #3e5c85;
  border-radius: 12px 12px 0px 0px;
  color: #fff;
}

.cardHead h4 {
  padding: 10px;
  font-size: 18px;
}

.colCenter {
  justify-content: center;
}

.iconMar {
  margin: -5px 5px 0px 0px;
}

.buySell_bg {
  background: url(../newImages/buySell_bg.png);
  background-position: 90% -25%;
  background-repeat: no-repeat;
  background-size: 100% 90%;
  background-color: var(--white-color);
}

/* ----------buy/sell Bitcoin------- */
.buySellBox {
  padding: 10em 6em;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.tabGrp {
  justify-content: center;
}

.tabBtns {
  border: 1px solid #30b5d1;
  border-radius: 8px;
  color: #30b5d1;
}

.tabBtnsAct {
  border-radius: 8px;
  background-color: #30b5d1;
  color: #fff;
}

.boxBg {
  /* background: var(--white-color); */
  padding: 10px 12px;
  border-radius: 8px;
}

.buySellBox2 {
  padding: 5em 8em 1em;
}

.w80 {
  width: 80% !important;
}

.banner2 {
  background: url(../newImages/bg2.png);
  background-position: 50% 90%;
  background-repeat: no-repeat;
  background-color: var(--white-color);
}

.banner3 {
  background: url(../newImages/bg3.png);
  background-position: 50% 70%;
  background-repeat: no-repeat;
  padding: 5em 8em 0;
  background-size: 100% 70%;
  background-color: var(--white-color);
}

.mar_auto {
  margin: auto;
}

/* ---------------create trade ad ------------- */
.tradeAdBg {
  background: url(../newImages/adBg1.png);
  background-size: 100% 95%;
  background-position: 100% -80%;
  background-repeat: no-repeat;
  margin-bottom: 60px;
}

.labelStyle {
  font-size: 14px;
  color: var(--text-grey);
  padding-left: 0;
  font-weight: 100 !important;
}

/* ---------edit profile------- */
.editProTabs {
  border: 1px solid var(--dark-btn) !important;
  padding: 10px 4px !important;
  font-family: "Quicksand-Regular";
  border-radius: 8px !important;
  width: auto;
  color: var(--dark-btn);
  text-align: center;
  font-size: 14px;
  height: 45px;
  margin: 0 10px;
  background: var(--white-color);
}

.editProTabs:hover {
  border: 1px solid #3e5c85 !important;
  color: white;
  background: #3e5c85;
}

.editProTabs a {
  color: #3e5c85;
  text-decoration: none;
}

.editTabsActive {
  background: #3e5c85;
  border: 1px solid #3e5c85 !important;
  padding: 10px 4px !important;
  font-family: "Quicksand-Regular";
  border-radius: 8px !important;
  width: auto;
  text-align: center;
  font-size: 14px;
  height: 45px;
  margin: 0 10px;
}

.editTabsActive a {
  color: #fff;
  text-decoration: none;
}

.detailBox {
  margin: 0 auto;
  border-radius: 12px;
  box-shadow: 0 4px 20px #cac7c7;
  padding: 0 !important;
}

.selectInput {
  border: 1px solid #3e5c85;
  color: #3e5c85;
}

.colPad {
  padding: 20px 30px 0;
}

.setPosCalendr {
  position: absolute;
  top: 46px !important;
  right: 40px;
}

/* -------------tradeId----------- */
.tradeStrip {
  margin: 25px auto 40px;
  background: #3e5c85;
  text-align: left;
  padding: 36px 20px 0px;
  display: flex;
  justify-content: space-between;
  width: 83%;
  height: fit-content;
  border-radius: 12px;
}

.cnfrmtext {
  text-align: center;
  padding: 20px 0px 0;
  width: 75%;
  margin: 0 auto 20px;
}

.uploadBtn {
  position: absolute;
  width: 40px;
  right: 30px;
  opacity: 0;
}

/* --------wallet--------- */
.tabList2 {
  display: flex;
  flex-wrap: wrap;
}

.tabox2 {
  border: 1px solid var(--text-grey) !important;
  padding: 10px !important;
  font-family: "Quicksand-Regular";
  cursor: pointer;
  border-radius: 8px !important;
  width: fit-content;
  color: var(--text-grey);
  text-align: center;
  font-size: 14px;
  height: 45px;
  margin-right: 20px;
}

.taboxActive2 {
  background: #3e5c85;
  border-radius: 8px !important;
  width: fit-content;
  font-size: 14px;
  height: 45px;
  cursor: pointer;
  color: #fff;
  padding: 10px !important;
  text-align: center;
  font-family: "Quicksand-Regular";
  border: none !important;
  margin-right: 20px;
}

.tabox2 a {
  color: #3e5c85;
}

.rcvCoin {
  color: var(--text-grey);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}

.formSpace {
  margin: 60px auto;
}

/* ----twofactor--------- */
.tabList3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.taboxActive3 {
  background: #3e5c85;
  border-radius: 8px !important;
  width: 20%;
  font-size: 14px;
  height: 45px;
  padding: 10px !important;
  text-align: center;
  font-family: "Quicksand-Regular";
  border: none !important;
  margin-left: 10px;
  white-space: nowrap;
}

.tabox3 {
  border: 1px solid #3e5c85 !important;
  padding: 10px !important;
  font-family: "Quicksand-Regular";
  border-radius: 8px !important;
  width: 20%;
  text-align: center;
  font-size: 14px;
  height: 45px;
  margin-left: 10px;
  white-space: nowrap;
}

.tabox3 a {
  color: #3e5c85 !important;
  text-decoration: none !important;
}

.taboxActive3 a {
  color: #fff !important;
  text-decoration: none !important;
}

.mar_auto2 {
  margin: auto 10px !important;
}

.colSpace {
  justify-content: space-evenly;
}

.rowMar {
  margin: 0 auto 35px;
}

/* ----------merchant------- */
.mechantBnr {
  background: url(../newImages/mechntbg.png);
  background-position: 0% -65%;
  background-repeat: no-repeat;
  background-size: 60% 96%;
  /* margin-bottom: 60px; */
  /* height: 680px; */
  height: 650px;
  width: 100%;
  background-color: var(--white-color);
}

.rowSpace4 {
  padding: 0em 8em 0;
}

.merImgLft {
  position: relative;
  float: left;
  width: 55%;
}

.merImgRyt {
  position: relative;
  float: right;
  width: 55%;
}

.width85 {
  width: 85%;
}

.width80 {
  width: 80%;
}

.width55 {
  width: 55%;
}

.width75 {
  width: 75%;
}

/* .merIm1{width:75%;} */
.mer_logo {
  position: absolute;
  bottom: 22%;
  right: 15%;
  width: 25%;
}

.rowSpace3 {
  padding: 12em 8em 0;
}

.rowSpace4 {
  padding: 6em 4em 0;
}

.rowSpace,
.rowSpace2 {
  padding: 2em 8em 0;
}

.text1 {
  padding: 80px 30px 30px 0px;
  width: 80%;
}

.text2 {
  padding: 80px 0px 30px 0px;
  width: 80%;
  color: var(--text-grey);
}

.text3 {
  padding: 125px 0px 30px 0px;
  width: 80%;
  text-align: justify;
  color: var(--text-grey);
}

.text4 {
  padding: 50px 0px 30px 0px;
  width: 90%;
}

.img_1 {
  width: 80%;
  margin: 0 auto 25px;
  float: right;
}

/* ----------refer a friend------ */
.profImg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 20px auto;
}

.crossBtn {
  position: absolute;
  z-index: 1;
  right: 3px;
  background: #3e5c85;
  top: 2px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.modal button.close,
.btn.closeChatPanel {
  background: transparent !important;
}

.btcrefAmnt {
  float: right;
}

/* .crossBtn:hover, .crossBtn:focus{background: red;} */
/* ----------referral----------- */
.refHeader {
  text-align: center;
  /* margin-top: 25px;padding: 25px; */
}

.refHeader .txt1 {
  font-size: 26px;
  color: #3e5c85;
  font-family: "Tommysoft-Regular";
}

.refHeader .txt2 {
  color: #3e5c85;
  font-family: "Tommysoft-Medium";
  margin: 0 12px;
  font-size: 36px;
}

.refTxt {
  text-align: center;
  width: 40%;
  margin: 0 auto;
  font-family: "Quicksand-Regular";
  color: var(--text-grey);
}

.refImgLft {
  position: relative;
  float: left;
  width: 65%;
}

.refImg {
  position: relative;
  float: right;
  width: 65%;
}

.refImg img,
.refImgLft img {
  width: 100%;
  height: 100%;
}

.refTxt3 {
  /* text-align: right; */
  text-align: justify;
  position: relative;
  float: right;
  padding: 60px 30px 0;
  width: 85%;
}

.refBnr {
  background: url(../newImages/bg3.png);
  background-position: 0% 30%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* height: 700px; */
  height: 575px;
  width: 100%;
  padding: 5em 0;
}

.cnterImg {
  width: 25%;
  margin: 0 auto;
}

/* ----------security image----- */
.mobImg {
  position: relative;
  float: right;
  margin-right: 30px;
  width: 25%;
}

/* ----------social media----- */
.socialPadd {
  padding: 5em 8em;
}

.socailTxt {
  font-size: 30px;
}

.socialImg {
  width: 55%;
  margin: 0 auto;
}

.imgGrp0 {
  width: 70%;
  /* height: 315px; */
  margin: 0 auto;
}

.imgGrp0 img {
  width: 100%;
  height: 100%;
}

.imgGrp1 {
  width: 40%;
  height: 150px;
}

.imgGrp1 img {
  width: 100%;
  height: 100%;
}

.imgGrp2 {
  width: 80%;
  height: 250px;
}

.imgGrp2 img {
  width: 100%;
  height: 100%;
}

.imgGrpTxt {
  margin: auto 0;
  padding: 0 0 0 50px;
}

.imgGrpTxt h2 {
  margin-bottom: 20px;
}

.imgGrp3 {
  width: 70%;
  /* height: 125px; */
  margin: 0 auto;
}

.imgGrp3 img {
  width: 100%;
  height: 100%;
}

.imgGrp4 {
  width: 70%;
  /* height: 300px; */
  margin: 0 auto;
}

.imgGrp4 img {
  width: 100%;
  height: 100%;
}

.appBtn {
  width: 25%;
  height: 45px;
}

.appBtn img {
  width: 100%;
  height: 100%;
}

.w18 {
  width: 18% !important;
}

.socMedGrp {
  width: 100%;
  padding: 35px 10em;
  display: flex;
  justify-content: space-between;
}

.socialIcon {
  width: 60px;
  height: 55px;
  margin: 35px auto;
}

.socialIcon img {
  width: 100%;
  height: 100%;
}

.socialTweet {
  width: 50px;
  height: 40px;
  margin: 42px auto;
}

.socialTweet img {
  width: 100%;
  height: 100%;
}

/* ------------about us------ */
.aboutusTxt {
  padding: 0 20px 30px;
  font-family: "Quicksand-Regular";
  color: var(--text-grey);
}

/* -------generate invoice------- */
.btcTab {
  width: 100px;
  margin: 0 auto;
  padding: 5px;
  border-radius: 6px;
  box-shadow: 0 1px 4px 1px #ddd;
}

.invoiceTxt {
  background: #30b5d1;
  padding: 10px;
  text-align: center;
  margin-top: 10px;
  font-family: "Quicksand-Medium";
}

.howPay {
  color: #fff !important;
}

.howPay:hover,
.howPay:focus {
  color: #30b5d1 !important;
}

.qrCode a {
  align-self: center;
  margin-left: 32%;
  margin-top: 10%;
}

.bcal {
  border-radius: 8px 8px 0px 0px;
  background-color: #3e5c85;
  padding: 25px 2px 15px 2px;
  color: #fff;
}

.invoiceTab {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px 30px;
}

.invoiceSearch {
  width: 250px;
}

.invoiceSearch span {
  padding: 1px;
}

.termpad {
  padding: 25px 40px;
}

.pdTop {
  padding: 7em 35px 5em;
}

.invoicePad {
  padding: 0px 45px;
  color: var(--text-grey);
}

.cardHeadModal {
  width: 100%;
  border-radius: 8px 8px 0 0;
  height: 65px;
  background: #3e5c85;
  color: #fff;
  padding-top: 18px;
}

.pad10 {
  padding: 10px;
}

/* <<<<<<< HEAD */
.feedback {
  text-align: center;
  padding: 50px;
  color: var(--text-grey);
}

.sectionHead ul ul li {
  color: white;
}

.sun-editor {
  border-radius: 7px;
  overflow: hidden;
}

.sun-editor .se-toolbar {
  outline: none;
}

/* ======= */
.editLink {
  color: #fff !important;
  text-decoration: none !important;
}

/* >>>>>>> eff42476e65b5d0821c652cff6b9976a51238991 */
.marTp {
  margin-top: 30px;
}

.marTp1 {
  margin-top: 80px;
}

.modal-content {
  border-radius: 15px !important;
}

.txtJus {
  text-align: justify;
  color: var(--text-grey);
}

.emailVer {
  padding: 3em 2em;
  color: #3e5c85;
}

.onCoinbaazar i {
  font-size: 24px;
  color: #3e5c85;
  margin-right: 10px;
}

.onCoinbaazar span {
  font-size: 16px;
  font-family: "Quicksand-Bold";
  color: #3e5c85;
}

.onCoinbaazar p {
  font-size: 14px;
  margin-left: 38px;
  margin-bottom: 0;
}

.onCoinbaazar label {
  margin-bottom: 0 !important;
}

.commonPad {
  background-color: var(--white-color);
  padding: 6em 0 0 !important;
}

.seeFeed {
  color: #30b5d1;
  cursor: pointer;
}

.forgotbox {
  height: 450px;
}

.captaMar {
  margin: 0 50px;
}

.selCurr {
  font-size: 20px !important;
  font-weight: bold;
  height: auto !important;
}

.calCurr {
  font-weight: bold;
  font-size: 30px !important;
}

.graphBox {
  width: 100%;
  overflow: hidden;
  height: 350px;
  margin: 40px auto 0px;
  padding: 0 100px;
}

.graphBox .chartContainer {
  height: 100% !important;
  width: 100% !important;
  /* width: 100px;
    height: 100px;
    max-width: 120px; */
}

.paymethod {
  width: 100%;
  border: 1px solid #ddd;
  background: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-family: "Quicksand-Bold";
  color: var(--popUpBW-color);
  margin-bottom: 10px;
  font-size: 16px;
  text-align: left;
}

.paymethod img {
  width: 40px;
  height: 30px;
}

.currBtn {
  width: 100%;
  border: 1px solid #ddd;
  background: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-family: "Quicksand-Bold";
  color: var(--popUpBW-color);
  margin-bottom: 10px;
  font-size: 16px;
  text-align: left;
}

.currBtn img {
  width: 30px;
  margin-right: 10px;
  height: 30px;
}

.modalSearch {
  border-color: #ddd !important;
  font-size: 18px !important;
  min-height: 50px !important;
}

.referlist {
  width: 100%;
  background: #fff;
  padding: 50px 40px 25px;
}

.listItem {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #ddd;
}

.itemImg {
  width: 10%;
  padding: 10px;
  height: 100px;
  border-radius: 50%;
}

.itemImg img {
  width: 100%;
  height: 100%;
}

.itemInfo {
  width: 90%;
  padding: 25px;
}

.itemInfo p {
  font-size: 16px;
  font-family: "Quicksand-Bold";
  color: #3e5c85;
  margin-bottom: 5px;
}

.itemInfo .id {
  font-size: 16px;
  font-family: "Quicksand-Medium";
  color: #3e5c85;
}

.itemInfo .icon {
  float: right;
  color: #3e5c85;
  font-size: 18px;
}

/* input[type="date"]
  
    {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        background-image: url("../newImages/caledarIcon.png") !important;
        background-repeat: no-repeat;
        background-position: 99% center;
        background-size: 25px;
        cursor: pointer;
    } */
/* -------------gift-card---------- */
.giftImg {
  width: 65%;
  float: right;
}

.giftImg img {
  border-radius: 50%;
}

.giftPad {
  padding: 20px 10em;
  color: #3e5c85;
  font-family: "Tommysoft-Medium";
  margin: 0 12px;
  font-size: 36px;
}

.stepList li {
  font-size: 18px;
  font-family: "Tommysoft-Medium";
  color: #3e5c85;
  list-style: circle;
}

.stepList li::marker {
  font-size: 25px;
  font-weight: 600;
}

.gftTxt {
  text-align: center;
  width: 40%;
  margin: 30px auto 0;
  font-family: "Quicksand-Medium";
  font-size: 18px;
  color: var(--text-grey);
}

.gftTxt2 {
  text-align: center;
  width: 50%;
  margin: auto;
  padding: 30px;
  font-family: "Quicksand-Medium";
  font-size: 18px;
  color: var(--text-grey);
}

.giftPad2 {
  padding: 20px 15em;
  color: #3e5c85;
  font-family: "Tommysoft-Medium";
  margin: 0 12px;
  font-size: 24px;
}

/* ------bank-transfer----- */
.bankLayer {
  background: url(../newImages/banklayer1.png), url(../newImages/banklayer2.png);
  background-repeat: no-repeat;
  height: 700px;
  width: 100%;
  background-position: -370% 52%, 455% 48%;
  padding: 40px 0;
}

.centerSection {
  width: 30%;
  margin: 0 auto;
}

.centerSection .bankImg {
  width: 100%;
  height: 380px;
}

.centerSection .bankImg img {
  width: 100%;
  height: 100%;
}

.bnkImgTitle {
  font-size: 24px;
  margin-bottom: 25px;
  font-family: "Tommysoft-Medium";
  text-align: center;
  color: #3e5c85;
}

.bnkTxt {
  text-align: center;
  font-family: "Quicksand-Medium";
  font-size: 16px;
  color: var(--text-grey);
}

/* ---------altcoin---------- */
.altLayer {
  background: url(../newImages/banklayer1.png), url(../newImages/banklayer2.png);
  background-repeat: no-repeat;
  height: auto;
  width: 100%;
  background-position: -360% 135%, 440% 85%;
  padding: 100px 0;
  background-color: var(--white-color);
}

.bullet {
  font-size: 10px;
  margin-right: 5px;
}

.altSection {
  width: 40%;
  margin: 0 auto;
}

.altSection .bankImg {
  width: 100%;
  height: 400px;
}

.altSection .bankImg img {
  width: 100%;
  height: 100%;
}

/* ------cash-deposit--------- */
.cashLayer {
  background: url(../newImages/banklayer1.png), url(../newImages/banklayer2.png);
  background-repeat: no-repeat;
  height: auto;
  width: 100%;
  background-position: -360% 135%, 440% 85%;
  padding: 40px 0;
  background-color: var(--white-color);
}

.cashSection {
  width: 50%;
  margin: 0 auto;
}

.cashSection .bankImg {
  width: 75%;
  margin: 0 auto;
}

.cashSection .bankImg img {
  width: 100%;
  height: 100%;
}

.width65,
.cashImg {
  width: 65%;
}

.fontsize {
  font-size: 30px;
}

/* --------goods & services-------- */
.goodsLayer {
  background: url(../newImages/banklayer1.png), url(../newImages/banklayer2.png);
  background-repeat: no-repeat;
  height: auto;
  width: 100%;
  background-position: -360% 135%, 440% 85%;
  padding: 40px 0;
  background-color: var(--white-color);
}

.goodsTitle {
  padding: 20px 15em;
  color: #fb072f;
  font-family: "Tommysoft-Medium";
  margin: 0 12px;
  font-size: 24px;
}

.goodsImg {
  width: 60%;
  margin: 80px auto 0;
}

.colcentr {
  padding: 20px 20px 0;
}

.spanpad {
  padding: 0 25px;
}

.commonSectionStyle2 {
  padding: 6em 0;
}

.commonSectionStyle3 {
  padding: 10em 0;
}

.salryInp {
  width: 17%;
}

.salrytab {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* -------createAdd add image---------- */
.addmoreImage {
  display: block;
  width: 65px;
  border: 1px solid #ced4da;
  max-width: 100%;
  height: 45px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}

.addmoreImage input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  opacity: 0;
  z-index: 99;
}

.addmoreImage i {
  position: absolute;
  right: 25px;
  top: 12px;
  color: #495057;
  font-size: 18px;
  opacity: 0.8;
}

.addImg_l {
  width: 75px;
  height: 60px;
  margin-right: 5px;
  position: relative;
}

.addImg_l img {
  width: 100%;
  height: 100%;
  border: solid 2px #f39c12;
  border-radius: 10px;
}
.add_tag {
  font-size: 12px;
  color: "var(--black-color)";
  margin-left: 2px;
  border: solid 1px #f39c12;
  border-radius: 5px;
  background-color: #f39c1240;
  padding: 2px;
}
.addImg_l span {
  position: absolute;
  right: -3px;
  bottom: 35px;
  color: red;
}

.imagecount {
  position: absolute;
  background: #305d85;
  padding: 0px 5px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  right: 5px;
  top: 12px;
}

.awssld__controls__arrow-left:before,
.awssld__controls__arrow-right:before {
  top: -2% !important;
}

.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after,
.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right:after {
  height: 50% !important;
}

.awssld__bullets button {
  width: 10px !important;
  height: 10px !important;
}

.awssld__content {
  background-color: #fff !important;
  align-items: flex-start !important;
}

.pictureframe {
  width: 100%;
  height: 90%;
  padding-bottom: 10px;
  padding-top: 10px;
}

.pictureframe img {
  width: 100%;
  height: 100%;
}

.myCard {
  margin: 0 70px;
  max-width: 500px !important;
}

.amntCard {
  display: flex;
  justify-content: space-around;
}

.amntCard div {
  width: 23%;
  text-align: center;
  border-radius: 10px;
  padding: 0.2rem 0.75rem;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ced4da;
  margin-top: 8px;
}

.amntCard div:hover {
  background: #3e5c85;
  color: #fff;
}

.cardBal {
  font-family: "Quicksand-Regular";
  color: #3e5c85;
  font-weight: 600;
  font-size: 15px;
  text-align: left;
  margin: 0;
}

.alrtMsg {
  margin: 40px 0 30px;
  background: #f5f5f5;
  padding: 4px 8px;
  font-size: 15px;
}

.totAmntBox {
  display: flex;
  justify-content: space-between;
}

.tot_amnt {
  font-size: 20px;
  border: 2px solid #3e5c85;
  padding: 10px;
  border-radius: 4px;
  font-family: "Quicksand-Bold";
  color: #3e5c85;
}

.balnce {
  display: flex;
  justify-content: space-between;
}

.cardtext {
  text-align: left;
  margin-top: 15px;
}

.cardtext h2 {
  margin: 50px 0;
  color: #3e5c85;
  font-size: 26px;
  font-weight: 500;
  font-family: "Tommysoft-Bold";
}

.cardtext span {
  font-size: 16px;
  font-family: "Quicksand-Bold";
}

.cardBucket {
  padding-left: 55px;
  padding-top: 5px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  width: 100%;
  height: 236px;
  overflow-y: auto;
}

.bucket {
  width: 20%;
  border-radius: 4px;
  height: 100px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.bucket_img {
  width: 100%;
  height: 100%;
  border: 1px solid #3e5c85;
}

.bucket_img:hover {
  /* width: 100%;
  height: 100%;
  transform: scale(1.1); */
}

.prodImg .boxsize2 {
  width: 90% !important;
  height: 100%;
  padding: 0px 20px;
  margin-left: 35px;
}

.cardStr {
  text-align: right;
  font-size: 20px;
  font-family: "Quicksand-Medium";
  padding-top: 15px;
  color: #3e5c85;
}

.cardStr img {
  width: 35px;
  height: 30px;
  margin-right: 5px;
  margin-top: -15px;
}

.cardHeader {
  text-align: center;
  color: #3e5c85;
}

.cardHeader p {
  font-size: 20px;
}

.redeemImg {
  width: 70%;
  height: 300px;
  margin: 15px auto 0px;
}

.giftIns {
  margin-bottom: 4em;
}

.giftIns li {
  font-size: 16px;
  font-family: "Quicksand-Medium";
  color: #4b4b4b;
  list-style: circle;
  color: var(--text-grey);
}

.balanceImg {
  /* width: 60%;
  margin: 30px auto 55px;
  height: 300px */
  width: 100%;
  margin: 0px auto 55px;
  height: 375px;
}

.giftTabs {
  padding-left: 55px;
  padding-top: 5px;
  margin-top: 15px;
  width: 100%;
}

.giftTabBox {
  margin-bottom: 10px;
  margin-right: 12px;
  width: auto;
}

.giftT li {
  list-style-type: disc;
  color: var(--text-grey);
}

.giftCd {
  font-weight: bold;
  font-size: 20px;
}
.dropbtn {
  background-color: var(--white-color);
  border: 1px solid #30b5d1;
  border-radius: 8px;
  font-size: 14px;
  height: 45px;
  cursor: pointer;
  padding: 10px;
  font-family: "Quicksand-Regular";
  width: 200px;
  /* margin-right: 20px; */
  text-align: center;
  color: #3e5c85 !important;
}
/* .dropbtn span{
  color: #3e5c85;
} */
.newbtn1 span {
  color: #3e5c85 !important;
}
.newbtn1:hover span {
  color: white !important;
}
.dropbtn:hover {
  background-color: #3e5c85;
  /* background-color: white; */
  border: 1px solid #30b5d1;
  border-radius: 8px;
  font-size: 14px;
  height: 45px;
  cursor: pointer;
  padding: 10px;
  font-family: "Quicksand-Regular";
  width: 200px;
  /* margin-right: 20px; */
  text-align: center;
  color: white !important;
}
.underl {
  cursor: pointer;
  font-size: 18px;
  color: var(--black-color) !important;
  font-weight: 600 !important;
  text-decoration: underline !important;
}
.underl:hover {
  cursor: pointer;
}
.proimg {
  width: 56px;
  height: 58px;
  object-fit: "cover";
  margin-top: 10px;
}
.imgcount {
  background-color: "#3e5c85";
  border-radius: 10px;
  padding: 5px;
  height: 20px;
  width: 20px;
  margin-top: 5px;
  /* margin-top: -10px; */
}
/* .dropbtn span:hover{
  color:white
} */
/* .dropbtnclr{
  color:white;

}
.dropbtnclr:hover{
  color:#3e5c85
} */
.__react_component_tooltip {
  width: 500px;
}

/* theme changer */

.checkbox-label {
  background-color: #111;
  width: 50px;
  height: 26px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--black-color);
  margin: 4px auto;
}

.fa-moon {
  color: #f1c40f;
}

.fa-sun {
  color: #f39c12;
}

.checkbox-label .ball {
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

/* .checkbox:checked + .checkbox-label .ball {
  transform: translateX(24px);
} */

/*  Support me if you like it */
.support {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.support a {
  color: #292c35;
  font-size: 32px;
  backface-visibility: hidden;
  display: inline-block;
  transition: transform 0.2s ease;
}

.support a:hover {
  transform: scale(1.1);
}
.profile-name .fa{
  position: unset;
}
.profile-name b{
  vertical-align: text-top;
  font-size: 20px;
}
.profile-name .btc_i{
  color: orange;
  font-size: 19px;
}